import { useAppContext } from "AppContext";
import { AnimationComponent, Box, Grid2, Show, Tab, Tabs } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import {
  AvailableCash,
  BankLedger,
  CashManagementMainPage,
  IncomeExpense,
  MutualFunds,
  Transaction,
} from "Pages";
import ReportTab from "Pages/ReportManager/ReportTab";
import { GET_ALL_EQUITY_ETF_TRANSACTION } from "utils/apiRoutes";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

const ReportsMenu = () => {
  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const tabs = [
    { label: "Report Manager", component: <ReportTab /> },
    { label: "Ledger", component: <BankLedger /> },
    { label: "Ledger Balance", component: <AvailableCash /> },
    {
      label: "Equity/ETF",
      component: (
        <Transaction
          title={"Equity/ETF"}
          name={"tradeBook"}
          apiRoute={GET_ALL_EQUITY_ETF_TRANSACTION}
          apiRouteKey={QueryKeys.TRANSACTIONS_LIST}
          tableName="trade_book_list"
          bulkDelete={"/transaction/bulk_delete/"}
          routeNavigate={{
            navigate: "/app/create-transaction",
            editNavigate: "/app/edit-transaction",
          }}
        />
      ),
    },
    { label: "Mutual Funds", component: <MutualFunds /> },
    { label: "Cash Entry", component: <CashManagementMainPage /> },
    { label: "Income/Expense", component: <IncomeExpense /> },
    {
      label: "Private Equity",
      component: (
        <Transaction
          title={"Private Equity"}
          name={"privateEquityList"}
          apiRoute={"/other_assets/"}
          requestParams={{
            key: "asset_type",
            value: "PEQ",
          }}
          apiRouteKey={QueryKeys.PRIVATE_EQUITY_LIST}
          tableName="private_equity_list"
          routeNavigate={{
            navigate: "/app/create-transaction",
            transactionType: "OTHER-ASSETS",
            assetType: "PEQ",
            editNavigate: "/app/edit-transaction",
          }}
          bulkDelete={"/other_assets/bulk_delete/?asset_type=PEQ"}
        />
      ),
    },
    {
      label: "Fixed Deposit",
      component: (
        <Transaction
          title={"Fixed Deposit"}
          name={"FDList"}
          apiRoute={"/other_assets/"}
          requestParams={{
            key: "asset_type",
            value: "FD",
          }}
          apiRouteKey={QueryKeys.FD_LIST}
          tableName="FD_list"
          routeNavigate={{
            navigate: "/app/create-fixed-deposit-transaction",
            transactionType: "OTHER-ASSETS",
            assetType: "FD",
            editNavigate: "/app/edit-fixed-deposit-transaction",
          }}
          bulkDelete={"/other_assets/bulk_delete/?asset_type=FD"}
        />
      ),
    },
  ];

  return (
    <DashboardLayout title="Dashboard">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, tabValueOfReports: val },
                });
              }}
              value={tabValue?.tabValueOfReports}
              variant="scrollable"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} disableTouchRipple />
              ))}
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When
              key={index}
              isTrue={tabValue?.tabValueOfReports === index}
            >
              <AnimationComponent>{tab.component}</AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default ReportsMenu;
