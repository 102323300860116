import {
  FormControl,
  Grid2,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import GKDatePicker from "components/GKDatePicker";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { MdOutlineInfo } from "react-icons/md";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import {
  COMPANY_CAP,
  COMPANY_STATUS,
  CompanyGetByIdInterface,
  FREQUENCY_DROPDOWN,
  INSTRUMENT_SUB_TYPE,
} from "./helper";

interface FormValues {
  name: string;
  shortName: string;
  instType: string;
  cap: string;
  sector: string;
  industry: string;
  house: string;
  status: string;
  isin: string;
  instSubType: string;
  startingDate: string;
  cmp: string;
  otherAssetMeta: {
    tenure: string;
    frequency: string;
    interestRate: string;
  };
}

interface Props {
  type: string;
  onSave?: (values: FormValues) => void;
  onUpdate?: (values: any) => void;
  companyData?: CompanyGetByIdInterface;
  isEdit: boolean;
}

const CompanyDetailsForm = (props: Props) => {
  const { type, onSave, companyData, isEdit, onUpdate } = props;

  const form = useForm<FormValues>({
    defaultValues: {
      name: isEdit ? companyData?.data?.name : "",
      shortName: isEdit ? companyData?.data?.shortName : "",
      instType: type || "",
      cap: isEdit ? companyData?.data?.cap : "",
      sector: isEdit ? companyData?.data?.sector : "",
      industry: isEdit ? companyData?.data?.industry : "",
      house: isEdit ? companyData?.data?.house : "",
      status: isEdit ? companyData?.data?.status : "",
      isin: "",
      instSubType: isEdit ? companyData?.data?.instSubType : "NCE",
      startingDate: isEdit
        ? companyData?.data?.startingDate
        : dayjs("01-01-2001").format("YYYY-MM-DD"),
      otherAssetMeta: {
        tenure: isEdit ? companyData?.data?.otherAssetMeta?.tenure : "",
        frequency: isEdit ? companyData?.data?.otherAssetMeta?.frequency : "",
        interestRate: isEdit
          ? companyData?.data?.otherAssetMeta?.interestRate
          : "",
      },
    },
  });

  return (
    <form
      id={isEdit ? "update-company-form" : "company-form"}
      onSubmit={form.handleSubmit((values) => {
        isEdit ? onUpdate(values) : onSave(values);
      })}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={6}>
          <Controller
            name="name"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter company name",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    inputLabel={"Company Name"}
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid2>

        {type === "PEQ" && (
          <Grid2 size={6}>
            <Controller
              name="sector"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField {...field} inputLabel={"Sector"} />
                  </FormControl>
                );
              }}
            />
          </Grid2>
        )}

        {type === "FD" && (
          <Grid2 size={6}>
            <Controller
              name="otherAssetMeta.tenure"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter tenure",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel={"Tenure"}
                      type="number"
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                );
              }}
            />
          </Grid2>
        )}

        <Grid2 size={6}>
          <Controller
            name="shortName"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter short name",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel={
                      <Typography
                        fontSize={18}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        Short Name
                        <span
                          style={{
                            color: paletteLightTheme.palette.error.main,
                          }}
                        >
                          *
                        </span>
                        <Tooltip
                          title="Short Name is the equity company's name and will appear in the dropdown while adding trades to holdings."
                          placement="right"
                          arrow
                        >
                          <Typography>
                            <IconButton>
                              <MdOutlineInfo size={18} />
                            </IconButton>
                          </Typography>
                        </Tooltip>
                      </Typography>
                    }
                    placeholder="Short Name"
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid2>

        {type === "FD" && (
          <Grid2 size={6}>
            <Controller
              name="otherAssetMeta.frequency"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select frequency",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disableClearable
                    inputLabel="Frequency"
                    options={FREQUENCY_DROPDOWN || []}
                    onChange={(event, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      FREQUENCY_DROPDOWN?.find(
                        (option) => option.value === field.value,
                      )?.name || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select frequency",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
        )}

        {type === "PEQ" && (
          <Grid2 size={6}>
            <Controller
              name="industry"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField {...field} inputLabel={"Industry"} />
                  </FormControl>
                );
              }}
            />
          </Grid2>
        )}

        {type === "FD" && (
          <Grid2 size={6}>
            <Controller
              name="otherAssetMeta.interestRate"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter interest rate",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel={"Interest Rate"}
                      type="number"
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                );
              }}
            />
          </Grid2>
        )}

        {type === "PEQ" && (
          <Grid2 size={6}>
            <Controller
              name="house"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField {...field} inputLabel={"House"} />
                  </FormControl>
                );
              }}
            />
          </Grid2>
        )}

        {type === "PEQ" && (
          <Grid2 size={6}>
            <Controller
              name="cap"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select cap",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disableClearable
                    inputLabel="Cap"
                    options={COMPANY_CAP}
                    onChange={(event, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      COMPANY_CAP?.find(
                        (option) => option.value === field.value,
                      )?.name || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Cap",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
        )}
        <Grid2 size={6}>
          <Controller
            name="status"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please select status",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <GKSearchSelect
                  {...field}
                  requiredField
                  disableClearable
                  inputLabel="Status"
                  options={COMPANY_STATUS || []}
                  onChange={(event, val) => {
                    field.onChange(val?.value);
                  }}
                  value={
                    COMPANY_STATUS?.find(
                      (option) => option.value === field.value,
                    )?.name || ""
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Status",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              );
            }}
          />
        </Grid2>

        {!isEdit && type === "PEQ" && (
          <Grid2 size={6}>
            <Controller
              name="isin"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      inputLabel={
                        <Typography
                          fontSize={18}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          ISIN
                          <Tooltip
                            title="Enter the 12-character unique ID for the security."
                            placement="right"
                            arrow
                          >
                            <Typography>
                              <IconButton>
                                <MdOutlineInfo size={18} />
                              </IconButton>
                            </Typography>
                          </Tooltip>
                        </Typography>
                      }
                      placeholder="ISIN"
                    />
                  </FormControl>
                );
              }}
            />
          </Grid2>
        )}

        {type === "PEQ" && (
          <Grid2 size={6}>
            <Controller
              name="instSubType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select instrument sub type",
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disableClearable
                    inputLabel="Instrument Sub Type"
                    options={INSTRUMENT_SUB_TYPE || []}
                    onChange={(event, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      INSTRUMENT_SUB_TYPE?.find(
                        (option) => option.value === field.value,
                      )?.name || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Instrument Sub Type",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
        )}
        {type === "PEQ" && (
          <Grid2 size={6}>
            <FormControl fullWidth variant="standard">
              <Controller
                name="startingDate"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select date",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel={isEdit ? "Start Date" : "Date"}
                    value={dayjs(field.value)}
                    onChange={(value) =>
                      field.onChange(dayjs(value).format("YYYY-MM-DD"))
                    }
                    disableFuture
                    maxDate={dayjs()}
                    requiredField
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid2>
        )}
      </Grid2>
    </form>
  );
};

export default CompanyDetailsForm;
