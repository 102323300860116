import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  Box,
  FormControl,
  GKOtpInput,
  GKTextField,
  Grid2,
  PhoneInput,
  Show,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { ThemeContainer } from "utils/Theme";
import { emailRegex } from "utils/validationSchema";

interface Props {
  token: string;
  logo: string;
}

const Authentication = (props: Props) => {
  const { theme } = ThemeContainer();
  const { token, logo } = props;

  const [hasError, setHasError] = useState(false);

  const form = useFormContext();

  const [verifyResponse, setVerifyResponse] = useState<any>(false);
  const [otpCode, setOtpCode] = useState("");
  const [isDoneTyping, setIsDoneTyping] = useState<boolean>(false);

  const { mutate: verifyOTP } = useMutation({
    mutationKey: ["VERIFY_OTP"],
    mutationFn: (data: number | string) =>
      axiosInstance.post(
        "/lm-order/verify-otp/",
        {
          phoneNumber: form.getValues("auth.phoneNumber"),
          email: form.getValues("auth.email"),
          countryCode: form.getValues("auth.countryCode"),
          emailOtp: data,
          phoneOtp: data,
        },
        { headers: { Authorization: `Token ${token}` } },
      ),
    onSuccess: (response) => {
      if (
        response?.data?.data?.isEmailVerified &&
        response?.data?.data?.isPhoneVerified
      ) {
        form.setValue("emailOtp", otpCode);
        form.setValue("phoneOtp", otpCode);
        form.setValue("isVerified", true);
      } else {
        toast.error("The OTP you entered is incorrect. Please try again.");
      }
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  useEffect(() => {
    if (isDoneTyping) verifyOTP(otpCode);
  }, [isDoneTyping]);

  const { mutate: verifyDetails, isLoading: detailsLoading } = useMutation({
    mutationKey: ["VERIFY_DETAILS"],
    mutationFn: (data) =>
      axiosInstance.post("/lm-order/send-otp/", data, {
        headers: { Authorization: `Token ${token}` },
      }),
    onSuccess: (response) => {
      if (response?.data?.status === 200) {
        setVerifyResponse(true);
      }
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <Grid2 container bgcolor="#F8FAFC" height="100vh" direction="column">
      <Grid2
        size="auto"
        py={2}
        px={4}
        display="flex"
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <img
          src={logo || "/Logos/iconFullLogo.png"}
          alt=""
          height={`-webkit-fill-available`}
          width={"auto"}
          style={{ maxHeight: "32px" }}
          onError={({ currentTarget }) => {
            currentTarget.src = "https://gridkey.in/Logos/iconFullLogo.png";
          }}
        />
      </Grid2>
      <Grid2
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
      >
        <Box
          width="420px"
          bgcolor={theme.palette.light.main}
          p={"32px 40px"}
          borderRadius="16px"
          border={"1px solid #E3E8EF"}
          textAlign="center"
          component="form"
          onSubmit={form.handleSubmit((values) => verifyDetails(values?.auth))}
        >
          <Typography
            fontSize={28}
            fontWeight={600}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {verifyResponse
              ? " Verify your account"
              : " Secure your access today"}
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={500}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            color={theme.palette.secondaryText.main}
          >
            {verifyResponse
              ? "Please enter the OTP sent to your registered email and phone."
              : "Submit your details to explore the trading opportunities waiting for you"}
          </Typography>
          <Show.When isTrue={!verifyResponse}>
            <Grid2 size={12} mt={3}>
              <Controller
                name="auth.portfolioCode"
                control={form.control}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      inputLabel="Trading ID"
                      slotProps={{ htmlInput: { readOnly: true } }}
                    />
                  </FormControl>
                )}
              />
            </Grid2>
            <Grid2 size={12} mt={2}>
              <Controller
                name="auth.email"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter email",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      inputLabel="Email"
                      requiredField
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                )}
              />
            </Grid2>
            <Grid2 size={12} mt={2}>
              <Controller
                name="auth.phoneNumber"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter phone number",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl variant="standard" fullWidth>
                    <PhoneInput
                      {...field}
                      onSelectChange={(event: any, val: any) => {
                        form.setValue("auth.countryCode", val?.callingCodes);
                      }}
                      onTextChange={(event: any) => {
                        field.onChange(event.target.value);
                      }}
                      onTextValue={field.value}
                      requiredField
                      onSelectValue={form.getValues("auth.countryCode")}
                      error={!!error || hasError}
                      helperText={error?.message}
                      hasError={hasError}
                      setHasError={setHasError}
                    />
                  </FormControl>
                )}
              />
            </Grid2>
            <Grid2 size={12} mt={3}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={detailsLoading}
                fullWidth
              >
                Submit
              </LoadingButton>
            </Grid2>
          </Show.When>
          <Show.When isTrue={verifyResponse}>
            <Grid2 size={12} mt={3}>
              <GKOtpInput
                value={otpCode}
                onChange={setOtpCode}
                setIsTyping={setIsDoneTyping}
              />
            </Grid2>
          </Show.When>
        </Box>
      </Grid2>
      <Grid2
        size="auto"
        p={2}
        display="flex"
        justifyContent="center"
        borderTop="1px solid #E3E8EF"
      >
        <Typography variant="caption" color="text.secondary">
          © {dayjs().get("year")} gridkey. All rights reserved.
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default Authentication;
