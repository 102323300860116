import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import { FormControlLabel, GKDataGrid, Switch, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { FAMILY_ACCESS_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

const FamilyAdminAccess = () => {
  const { id } = useParams();

  const [employeeList, setEmployeeList]: any = useState([]);

  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);

  const requestBody = useGenerateQueryParams("familyAdminAccess");

  const { data, isLoading, refetch } = useFetchQuery({
    key: ["FAMILY_ADMIN_ACCESS", requestBody],
    route: FAMILY_ACCESS_BY_ID(id),
    requestBody,
    success: (response: any) => {
      setEmployeeList(response?.data);
      setSelectedEmployeeList(response?.data?.employees);
    },
  });

  const { mutate: handleAccess } = useMutation({
    mutationKey: ["FAMILY_ACCESS"],
    mutationFn: () =>
      axiosInstance.patch(`/family/access/${id}/all_employees/`),
    onSuccess: () => refetch(),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Name", "name", 150, 1),
      renderCell: (param) => {
        return <Typography>{param?.row?.personalDetails?.name}</Typography>;
      },
    },
    {
      ...extraColumnsField("Email", "email", 150, 1),
      renderCell: (param) => {
        return (
          <Typography textTransform={"lowercase"}>
            {param?.row?.personalDetails?.email}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Phone", "phone", 150, 1),
    },
    {
      ...extraColumnsField("Role", "role", 150, 1),
    },
    {
      ...extraColumnsField("Salary", "salary", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      headerAlign: "right",
      editable: false,
      align: "right",
      renderCell: (params) => {
        return (
          <Switch
            checked={selectedEmployeeList?.includes(params?.row?.id)}
            onChange={(event) => {
              let arr = [];
              if (event.target.checked) {
                arr = [...selectedEmployeeList, params?.row?.id];
              } else {
                arr = selectedEmployeeList?.filter(
                  (id: any) => id !== params?.row?.id,
                );
              }
              axiosInstance
                .patch(`/family/access/${id}/`, { employees: arr })
                .then(() => {
                  refetch();
                })
                .catch((error) => {
                  toast.error(
                    error?.response?.data?.message ||
                      "Something went wrong, please try again.",
                  );
                });
            }}
          />
        );
      },
    },
  ];

  return (
    <GKDataGrid
      columns={columns}
      rows={data?.data?.data || []}
      loading={isLoading}
      totalCount={data?.data?.totalCount || 0}
      name="familyAdminAccess"
      exportFile={{
        path: FAMILY_ACCESS_BY_ID(id),
        exportName: "FamilyAccessList",
      }}
      headerComponent={
        <FormControlLabel
          control={
            <Switch
              checked={employeeList?.enableAll}
              onChange={() => handleAccess()}
            />
          }
          label={employeeList?.enableAll ? "Disable All" : "Enable All"}
          labelPlacement="end"
        />
      }
    />
  );
};

export default FamilyAdminAccess;
