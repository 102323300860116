import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  FormControlLabel,
  GKDataGrid,
  GKModal,
  Grid2,
  Switch,
  Tooltip,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { FAMILY_ID_BY_PORTFOLIO_ACCESS } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

interface Props {
  familyAccessData: FamilyAccessDataInterface;
}

export interface FamilyAccessDataInterface {
  id: number;
  clientCode: string;
  name: string;
  familyName: string;
  phone: string;
  countryCode: string;
  email: string;
  pan: string;
  branch: string;
  portfolioCount: number;
  familyId?: string;
}

export interface AccessList {
  totalCount: number;
  data: Daum[];
  portfoliosAccess: number[];
  checkAll?: boolean;
}

export interface Daum {
  portfolioId: string;
  name: string;
  id: number;
  phone: string;
  clientName: string;
  clientCode: string;
}

const FamilyAccessModal = ({
  open,
  setOpen,
  familyAccessData,
}: Props & GKModalProps) => {
  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const [portfolioAccess, setPortfolioAccess] = useState<AccessList>();

  const requestBody = useGenerateQueryParams("familyPortfolioAccess");

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["FAMILY_PORTFOLIO_ACCESS", requestBody],
    route: FAMILY_ID_BY_PORTFOLIO_ACCESS(
      familyAccessData?.familyId,
      familyAccessData?.id,
    ),
    requestBody,
    success: (response: { data: AccessList }) => {
      setPortfolioAccess(response?.data);
    },
  });

  const { mutate: handleToggleAccess, isLoading: toggleLoading } = useMutation({
    mutationKey: ["FAMILY_PORTFOLIO_TOGGLE_ACCESS"],
    mutationFn: (obj: { portfoliosAccess?: number[]; checkAll?: boolean }) =>
      axiosInstance.patch(
        FAMILY_ID_BY_PORTFOLIO_ACCESS(
          familyAccessData?.familyId,
          familyAccessData?.id,
        ),
        obj,
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
      refetchQuery(QueryKeys.FAMILY_ACCESS);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const toggleSingleAccess = (id: number) => {
    const currentAccessIds = portfolioAccess?.portfoliosAccess || [];
    const updatedAccessIds = currentAccessIds.includes(id)
      ? currentAccessIds.filter((accessId) => accessId !== id)
      : [...currentAccessIds, id];

    handleToggleAccess({ portfoliosAccess: updatedAccessIds });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "name", 240, 1),
      renderCell: (params) => {
        return (
          <Grid2 container>
            <Tooltip title={params?.row?.name}>
              <Grid2 size={11}>
                <Typography
                  fontWeight={700}
                  maxWidth={"100%"}
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                  display={"flex"}
                  gap={1}
                  alignItems={"center"}
                >
                  {params?.row?.name}
                  {params?.row?.isClientsPortfolio && (
                    <Typography
                      bgcolor={"rgb(0 101 128 / 11%)"}
                      fontSize={10}
                      fontWeight={500}
                      color={theme.palette.primary.main}
                      p={"2px 4px"}
                      borderRadius={"4px"}
                      lineHeight={"14px"}
                    >
                      Self
                    </Typography>
                  )}
                </Typography>
                <Typography fontSize={12}>
                  {params?.row?.portfolioId}
                </Typography>
              </Grid2>
            </Tooltip>{" "}
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Client Name", "clientName", 150, 1),
    },
    // {
    //   ...extraColumnsField("Phone", "phone", 130, 1),
    //   valueGetter: (params) => params?.row?.phone || "-",
    // },
    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      editable: false,
      align: "right",
      renderCell: (params) => {
        return (
          <Switch
            checked={portfolioAccess?.portfoliosAccess?.includes(params.row.id)}
            onChange={() => toggleSingleAccess(params.row.id)}
          />
        );
      },
    },
  ];

  function clearPagination() {
    dispatch({
      type: "FILTER",
      data: {
        ...commonFilter,
        familyPortfolioAccess: {
          skip: 0,
          page: 0,
          take: 25,
          sortModel: [],
          search: "",
          showNonZero: false,
          customFilter: {},
        },
      },
    });
    setOpen(false);
  }

  return (
    <GKModal
      modalTitle={`${familyAccessData?.name} - Portfolio Access`}
      open={open}
      setOpen={() => clearPagination()}
      footer={
        <Grid2 size={12} textAlign={"right"}>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={() => clearPagination()}
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <GKDataGrid
        columns={columns}
        rows={data?.data?.data || []}
        loading={isFetching}
        columnsButton={false}
        name="familyPortfolioAccess"
        totalCount={portfolioAccess?.totalCount || 0}
        headerComponent={
          <Grid2>
            <FormControlLabel
              control={
                <Switch
                  disabled={isFetching || toggleLoading}
                  checked={portfolioAccess?.checkAll}
                  onChange={() =>
                    handleToggleAccess(
                      portfolioAccess?.checkAll
                        ? { checkAll: false }
                        : { checkAll: true },
                    )
                  }
                />
              }
              label={portfolioAccess?.checkAll ? "Disable All" : "Enable All"}
              labelPlacement="end"
            />
          </Grid2>
        }
        maxHeight={"calc(100vh - 398px)"}
      />
    </GKModal>
  );
};

export default FamilyAccessModal;
