import { useAppContext } from "AppContext";
import { CommonFilterState } from "interfaces/CommonFilter";

const includeZeroHoldingKeys = [
  "portfolio",
  "client",
  "clientFamily",
  "family",
  "portfolioHoldingsTable",
  "holdingSummary",
  "clientHolding",
  "equityBasketOrderManagerPortfolio",
  "familyClient",
  "familyHolding",
  "holdingDetails",
  "insights",
  "anchorTransaction",
  "portfolioGroupHolding",
  "portfolioGroup",
  "avlCashBalance",
  "subscribedPortfolio",
];

interface ExtraKeyInterface {
  [key: string]: string | boolean | string[] | number;
}

interface RequestBody {
  skip?: number;
  take?: number;
  sort?: string;
  search?: string;
  filter?: string;
  show_zero_holding?: boolean;
  extraKey?: ExtraKeyInterface;
}

const useGenerateQueryParams = (
  filterKey: string,
  extraKey?: ExtraKeyInterface,
): RequestBody => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const filter = commonFilter[filterKey as keyof CommonFilterState];

  const requestBody: RequestBody = {
    skip: filter?.skip,
    take: filter?.take,
    sort: JSON.stringify(
      filter?.sortModel?.map((data: { field: string; sort: string }) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      }),
    ),
    search: filter?.search || "",
    filter: JSON.stringify(filter?.customFilter),
  };

  if (includeZeroHoldingKeys.includes(filterKey)) {
    requestBody.show_zero_holding =
      !!extraKey?.defaultShowZeroHolding || filter?.showNonZero;
  }

  if (extraKey) {
    const { defaultShowZeroHolding, ...restExtraKeys } = extraKey;
    Object.assign(requestBody, restExtraKeys);
  }

  return requestBody;
};

export default useGenerateQueryParams;
