import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { FormControl, GKModal, GKTextField } from "components";
import { Controller, useForm, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

const TemplateModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const form = useFormContext();

  const templateForm = useForm({
    defaultValues: {
      name: "",
      reportName: form.getValues("selectedReported.reportName") || "",
      customData: {
        optionsToInclude: form.getValues("optionsToInclude") || "",
        disclaimer: form.getValues("disclaimer") || "",
        quote: form.getValues("quote") || "",
        holdingsStatement: form.getValues("holdingsStatement") || [],
        bankStatement: form.getValues("bankStatement") || [],
        availableTimePeriods: form.getValues("availableTimePeriods") || [],
        benchmark: form.getValues("benchmark") || "",
        end_date: form.getValues("end_date") || "",
        custom_date: form.getValues("custom_date") || {},
        optional_field: form.getValues("optionalField") || [],
        tradesPlusCash: form.getValues("tradesPlusCash") || false,
        returnMetrics: form.getValues("returnMetrics") || "xirr",
        benchmarkComparisionTimePeriods:
          form.getValues("benchmarkComparisionTimePeriods") || [],
        advisorCategory: form.getValues("advisorCategory") || "",
      },
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["CREATE_TEMPLATE"],
    mutationFn: (data) => axiosInstance.post("/report/", data),
    onSuccess: (response) => {
      refetchQuery("GET_ALL_GENERATE_REPORT_LIST");
      setOpen(false);
      form.reset();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <GKModal
      modalTitle="Create Template"
      open={open}
      setOpen={setOpen}
      footer={
        <LoadingButton
          variant="contained"
          size="medium"
          type="submit"
          form="template-form"
          loading={isLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form
        id="template-form"
        onSubmit={templateForm.handleSubmit((values: any) => mutate(values))}
      >
        <Controller
          name="name"
          control={templateForm.control}
          rules={{
            required: {
              value: true,
              message: "Please enter template name",
            },
            validate: validateNotWhitespaceOnly,
          }}
          render={({ field, formState: { errors } }) => (
            <FormControl variant="standard" fullWidth>
              <GKTextField
                {...field}
                requiredField
                inputLabel="Template Name"
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />
            </FormControl>
          )}
        />
      </form>
    </GKModal>
  );
};

export default TemplateModal;
