import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  GKEditor,
  GKRadio,
  GKSearchSelect,
  GKTextArea,
  GKTextField,
  GoUnverified,
  Grid2,
  ImageUpload,
  InputAdornment,
  MdVerified,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { AGREEMENT_SIGNATURE_SOURCE, KYC_OPTIONS } from "utils/constants";
import { handleFreshReturnFormData } from "utils/formHelpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import { emailRegex, validateNotWhitespaceOnly } from "utils/validationSchema";
import InvestorSettingsConfirmation from "./InvestorSettingsConfirmation";
import { CHECK_FILE_SIZE_FOR_LOGO, CHECK_FILE_TYPE_FOR_LOGO } from "./utils";

interface FormValues {
  id?: number;
  domainName?: string;
  paymentMethods?: string;
  paymentStep?: true;
  kycType?: string;
  agreement?: string;
  codePrefix?: string;
  revenueWithGst?: string;
  codeLength?: number;
  companyLogo?: any;
  qrCode?: any;
  companyMetaName?: string;
  tagLine?: string;
  tagLineBody?: string;
  colorPalette?: {
    primary: string;
    secondary: string;
  };
  advisorPhoto?: any;
  advisorName?: string;
  invoiceSeries?: string;
  sebiRegistered?: string;
  yearsOfExperience?: number;
  advisorInfo?: string;
  advisorPhone?: string;
  advisorEmail?: string;
  advisorAddress?: string;
  company?: number;
  isVerified?: boolean;
}

const InvestorSettings = () => {
  useDocumentTitle("Investor Settings");

  const { theme } = ThemeContainer();

  const {
    state: { userData },
  } = useAppContext();

  const [investorSettingsConfirmation, setInvestorSettingsConfirmation] =
    useState(false);

  const { data, refetch, isFetching } = useFetchQuery({
    key: ["GET_INVESTOR_PROFILE"],
    route: `/company/${userData?.companyId}/publish_settings/`,
  });

  const { data: formData } = useFetchQuery({
    key: ["GET_CHECK_INVESTOR_DATA"],
    route: `/company/${userData?.companyId}/publish_settings/verify/`,
  });

  const { mutate: updateInvestorProfile, isLoading: updateInvestorLoading } =
    useMutation({
      mutationKey: ["GET_INVESTOR_PROFILE_DOMAIN"],
      mutationFn: (data: any) =>
        axiosInstance.patch(
          `/company/${userData?.companyId}/publish_settings/`,
          data,
        ),
      onSuccess: (response) => {
        refetch();
        setInvestorSettingsConfirmation(false);
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.data?.message || "Something went wrong, please try again.",
        );
      },
    });

  const form = useForm<FormValues>({
    defaultValues: {
      domainName: "",
      revenueWithGst: "false",
      paymentMethods: "",
      invoiceSeries: "",
      paymentStep: null,
      kycType: "",
      agreement: "",
      codePrefix: "",
      codeLength: 3,
      companyLogo: null,
      qrCode: null,
      companyMetaName: "",
      tagLine: "",
      tagLineBody: "",
      advisorPhoto: null,
      advisorName: "",
      sebiRegistered: "",
      yearsOfExperience: 0,
      advisorInfo: "",
      advisorPhone: "",
      advisorEmail: "",
      advisorAddress: "",
      colorPalette: {
        primary: "#006580",
        secondary: "#63A387",
      },
      isVerified: false,
    },
    values: {
      ...data?.data,
      agreementTemplate: "",
      colorPalette: data?.data?.colorPalette
        ? JSON.parse(data?.data?.colorPalette)
        : {
            primary: "#006580",
            secondary: "#63A387",
          },
    },
  });

  const prefixWatch = form.watch(["codePrefix", "codeLength"]);

  const { mutate: confirmDomainName, isLoading } = useMutation({
    mutationKey: ["GET_INVESTOR_PROFILE_DOMAIN"],
    mutationFn: (data: string) =>
      axiosInstance.get(`/company/check_domain_name/?domain_name=${data}`),
    onSuccess: (response) => {
      const { available } = response.data;
      if (!available) {
        form.setError("domainName", {
          type: "manual",
          message: "Domain is not available. Please choose another one.",
        });
      } else {
        form.clearErrors("domainName");
        form.setValue("isVerified", true);
      }
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const handlePhotoDelete = (field: string) => {
    const formData: any = new FormData();
    formData.append(field, "");
    updateInvestorProfile(formData);
  };

  return (
    <DashboardLayout title="Investor Settings" loading={isFetching}>
      <form
        onSubmit={form.handleSubmit((values: any) => {
          if (!values.companyLogo?.name) {
            delete values.companyLogo;
          }
          if (!values.qrCode?.name) {
            delete values.qrCode;
          }
          if (!values.advisorPhoto?.name) {
            delete values.advisorPhoto;
          }
          updateInvestorProfile(
            handleFreshReturnFormData({
              isEditForm: false,
              obj: values,
            }),
          );
        })}
        id="investor-setting-form"
      >
        <Grid2 container spacing={2}>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Micro-website Domain
            </Typography>
          </Grid2>

          <Grid2
            container
            size={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid2 size={6}>
              <Controller
                name="domainName"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter a valid domain name",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        inputLabel="Domain Name"
                        requiredField
                        sx={{ borderColor: "green" }}
                        onChange={(event) => {
                          field.onChange(
                            event.target.value.toLowerCase().replace(/\s/g, ""),
                          );
                        }}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{ fontSize: 14 }}
                              >
                                .gridkey.in
                              </InputAdornment>
                            ),
                          },
                        }}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid2>
            <Grid2
              size={6}
              mt={"18px"}
              display={"flex"}
              alignItems={"center"}
              gap={2}
            >
              <LoadingButton
                variant="contained"
                loading={isLoading}
                onClick={() => confirmDomainName(form.getValues("domainName"))}
              >
                Confirm domain
              </LoadingButton>
              {form.getValues("isVerified") ? (
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  color={theme.palette.success.main}
                  border={`1px solid ${theme.palette.success.main}`}
                  p={1}
                  borderRadius={2}
                >
                  <MdVerified size={20} /> Verified
                </Typography>
              ) : (
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  color={theme.palette.error.main}
                  border={`1px solid ${theme.palette.error.main}`}
                  p={1}
                  borderRadius={2}
                >
                  <GoUnverified /> Not Verified
                </Typography>
              )}
            </Grid2>
            <Grid2 size={12} container gap={2}>
              <Grid2 size={2}>
                <Controller
                  control={form.control}
                  name="colorPalette.primary"
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      {" "}
                      <GKTextField
                        {...field}
                        inputLabel={"Primary Color"}
                        type="color"
                        style={{ borderRadius: "100%" }}
                        sx={{
                          width: 50,
                          mr: 1,
                          "& .MuiInputBase-input": {
                            p: 0,
                            height: 50,
                            width: 50,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={2}>
                <Controller
                  control={form.control}
                  name="colorPalette.secondary"
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      {" "}
                      <GKTextField
                        inputLabel={"secondary Color"}
                        type="color"
                        style={{ borderRadius: "100%" }}
                        {...field}
                        sx={{
                          width: 50,
                          mr: 1,
                          "& .MuiInputBase-input": {
                            p: 0,
                            height: 50,
                            width: 50,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2 size={12} my={1}>
            <Divider />
          </Grid2>

          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Onboarding
            </Typography>
          </Grid2>

          <Grid2
            container
            size={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid2 size={6}>
              <Controller
                name="kycType"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select KYC type",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    requiredField
                    inputLabel="KYC Type"
                    options={KYC_OPTIONS}
                    onChange={(event, val) => field.onChange(val?.value)}
                    value={
                      KYC_OPTIONS.find((type) => type.value === field.value)
                        ?.name || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="kycType"
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select KYC",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="agreement"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select agreement",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    requiredField
                    inputLabel="Agreement"
                    options={AGREEMENT_SIGNATURE_SOURCE}
                    onChange={(event, val) => field.onChange(val?.value)}
                    value={
                      AGREEMENT_SIGNATURE_SOURCE.find(
                        (type) => type.value === field.value,
                      )?.name || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="agreement"
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Agreement",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Grid2>

          <Grid2 size={12} my={1}>
            <Divider />
          </Grid2>

          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Default Portfolio code
            </Typography>
          </Grid2>

          <Grid2
            container
            size={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid2 size={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="codePrefix"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter prefix",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel="Prefix"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2
              size={8}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              mt={"20px"}
            >
              <Typography>Code Length</Typography>
              <Controller
                name={"codeLength"}
                control={form.control}
                render={({ field }) => (
                  <FormControl component="fieldset">
                    <RadioGroup
                      {...field}
                      onChange={(e) => field.onChange(+e.target.value)}
                      value={field.value}
                    >
                      <Grid2
                        container
                        size={12}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Grid2>
                          <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label={<Typography>3-digits</Typography>}
                            sx={{ mr: "8px" }}
                          />
                        </Grid2>
                        <Grid2>
                          <FormControlLabel
                            value={4}
                            control={<Radio />}
                            label={<Typography>4-digits</Typography>}
                            sx={{ mr: "8px" }}
                          />
                        </Grid2>
                        <Grid2>
                          <FormControlLabel
                            value={5}
                            control={<Radio />}
                            label={<Typography>5-digits</Typography>}
                          />
                        </Grid2>
                      </Grid2>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid2>

            {prefixWatch && (
              <Grid2
                size={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                gap={1}
              >
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color={theme.palette.secondaryText.main}
                >
                  Portfolio code will be
                </Typography>
                <Typography fontWeight={500}>{`${form.getValues(
                  "codePrefix",
                )}${"0".repeat(form.getValues("codeLength"))}`}</Typography>
              </Grid2>
            )}
            <Grid2 size={4}>
              <Controller
                name="invoiceSeries"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField {...field} inputLabel={"Invoice Series"} />
                    </FormControl>
                  );
                }}
              />{" "}
            </Grid2>
            <Grid2 size={8}>
              <Controller
                name="revenueWithGst"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormControl>
                      <FormLabel>Revenue with GST</FormLabel>
                      <RadioGroup
                        {...field}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<GKRadio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<GKRadio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  );
                }}
              />{" "}
            </Grid2>
          </Grid2>
          <Grid2 size={12} my={1}>
            <Divider />
          </Grid2>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Micro-website Profile
            </Typography>
          </Grid2>
          <Grid2
            container
            size={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid2 size={12}>
              <Controller
                name="companyLogo"
                control={form.control}
                render={({ field }) => (
                  <ImageUpload
                    buttonLabel="Upload Company Logo"
                    onDelete={() => handlePhotoDelete("companyLogo")}
                    onUpdate={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const file = e.target.files[0];
                      if (
                        file &&
                        CHECK_FILE_SIZE_FOR_LOGO(file?.size) &&
                        CHECK_FILE_TYPE_FOR_LOGO(file?.type)
                      ) {
                        field.onChange(file, "companyLogo");
                      } else {
                        toast.error(
                          "We don't seem to support the `files` property of file inputs.",
                        );
                      }
                    }}
                    src={field.value}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="companyMetaName"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter company name",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl variant="standard" fullWidth>
                        <GKEditor
                          {...field}
                          inputLabel="Company Meta Name"
                          requiredField
                          error={!!error}
                          helperText={error?.message}
                          toolbar={[
                            "undo",
                            "redo",
                            "|",
                            "fontColor",
                            "fontBackgroundColor",
                          ]}
                        />
                      </FormControl>
                    );
                  }}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="tagLine"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter tag line",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <GKEditor
                        {...field}
                        inputLabel="Tagline"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                        toolbar={[
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "|",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "alignment",
                        ]}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="tagLineBody"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter tag line description",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl variant="standard" fullWidth>
                      <GKEditor
                        {...field}
                        inputLabel="Tagline Body"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                        toolbar={[
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "|",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "alignment",
                          "link",
                          "|",
                          "bulletedList",
                          "numberedList",
                        ]}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                fontSize={16}
                fontWeight={500}
                color={theme.palette.secondaryText.main}
              >
                Advisor Details
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="advisorPhoto"
                control={form.control}
                render={({ field }) => (
                  <ImageUpload
                    buttonLabel="Upload Advisor Photo"
                    onDelete={() => handlePhotoDelete("advisorPhoto")}
                    onUpdate={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const file = e.target.files[0];
                      if (
                        file &&
                        CHECK_FILE_SIZE_FOR_LOGO(file?.size) &&
                        CHECK_FILE_TYPE_FOR_LOGO(file?.type)
                      ) {
                        field.onChange(file, "advisorPhoto");
                      } else {
                        toast.error(
                          "We don't seem to support the `files` property of file inputs.",
                        );
                      }
                    }}
                    src={field.value}
                    description="Format should be JPG or PNG, 3:4 aspect ratio, up to 10MB"
                  />
                )}
              />
            </Grid2>
            <Grid2 size={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="advisorName"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter advisor name",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Advisor name"
                      requiredField
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="sebiRegistered"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter SEBI Registered number",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel="SEBI Registered No."
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="yearsOfExperience"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter years of experience",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel="Years of Experience"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="advisorInfo"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter description",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKEditor
                      {...field}
                      requiredField
                      inputLabel="Advisor Introduction"
                      error={!!error}
                      helperText={error?.message}
                      toolbar={[
                        "undo",
                        "redo",
                        "|",
                        "heading",
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "|",
                        "fontColor",
                        "fontBackgroundColor",
                        "|",
                        "alignment",
                        "link",
                        "|",
                        "bulletedList",
                        "numberedList",
                      ]}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="advisorEmail"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter email",
                    },
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Email"
                      requiredField
                      error={!!error}
                      helperText={!!error && error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="advisorPhone"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      type="number"
                      inputLabel="Phone Number"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="advisorAddress"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter address",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextArea
                      {...field}
                      requiredField
                      inputLabel="Address"
                      rows={4}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
          </Grid2>

          <Grid2 size={12} my={1}>
            <Divider />
          </Grid2>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Payment Details
            </Typography>
          </Grid2>
          <Grid2 container size={9}>
            <Grid2 size={12}>
              <Controller
                name="qrCode"
                control={form.control}
                render={({ field }) => (
                  <ImageUpload
                    buttonLabel="Upload Payment Barcode"
                    onDelete={() => handlePhotoDelete("qrCode")}
                    onUpdate={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const file = e.target.files[0];
                      if (
                        file &&
                        CHECK_FILE_SIZE_FOR_LOGO(file?.size) &&
                        CHECK_FILE_TYPE_FOR_LOGO(file?.type)
                      ) {
                        field.onChange(file, "qrCode");
                      } else {
                        toast.error(
                          "We don't seem to support the `files` property of file inputs.",
                        );
                      }
                    }}
                    src={field.value}
                  />
                )}
              />
            </Grid2>
          </Grid2>
          <Grid2
            size={12}
            mt={2}
            p={1}
            gap={1}
            display={"flex"}
            justifyContent={"flex-end"}
            position={"sticky"}
            bgcolor={theme.palette.light.main}
            bottom={-7}
          >
            {formData?.data?.isEquityBasket && (
              <LoadingButton
                variant="lightBg"
                color="dark"
                loading={updateInvestorLoading}
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = `https://${form.getValues(
                    "domainName",
                  )}.gridkey.in/preview`;
                  document.body.appendChild(link);
                  link.target = "_blank";
                  link.click();
                }}
              >
                Preview
              </LoadingButton>
            )}
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={updateInvestorLoading}
            >
              Save
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={() => {
                setInvestorSettingsConfirmation(true);
              }}
              loading={updateInvestorLoading}
            >
              Publish
            </LoadingButton>
          </Grid2>
        </Grid2>
        {investorSettingsConfirmation && (
          <InvestorSettingsConfirmation
            open={investorSettingsConfirmation}
            setOpen={setInvestorSettingsConfirmation}
            investorData={data?.data}
          />
        )}
      </form>
    </DashboardLayout>
  );
};

export default InvestorSettings;
