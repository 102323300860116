/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import {
  Alert,
  CircularProgress,
  Divider,
  GKButton,
  GKOtpInput,
  Grid2,
  Typography,
} from "components";
import useOTPRegistration from "hooks/useOTPRegistration";

const OTPRegistration = () => {
  const {
    theme,
    phone,
    countryCode,
    setIsDoneTyping,
    isLoading,
    resendOTP,
    seconds,
    minutes,
    otpCode,
    setOtpCode,
    resendCount,
    setResendCount,
    navigate,
    setCount,
  } = useOTPRegistration();

  return (
    <Grid2 container gap={3} justifyContent={"center"}>
      <Grid2
        size={12}
        position={"relative"}
        textAlign={"center"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"10px"}
        flexDirection={"column"}
      >
        <Typography
          fontWeight={700}
          fontSize={24}
          color={theme.palette.dark.main}
          className="textUnderlineLogin"
          sx={{
            "&.textUnderlineLogin::before": {
              width: "130px !important",
            },
          }}
        >
          Verify OTP !
        </Typography>
      </Grid2>

      <Grid2 container size={{ xs: 12, md: 7, sm: 8 }} spacing={1}>
        <Grid2 size={12}>
          <Alert
            variant="outlined"
            severity="warning"
            style={{ margin: "10px 0" }}
          >
            <Typography fontWeight={500}>
              {" "}
              We have sent you an SMS with a 4-digit verification code on{" "}
              <span style={{ fontWeight: 600 }}>
                {countryCode}-{phone}
              </span>{" "}
              & will expire in{" "}
              <span style={{ fontWeight: 600 }}>
                {minutes}:{seconds < 10 ? "0" : ""}
                {seconds}
              </span>
            </Typography>
          </Alert>
        </Grid2>
        <Grid2 size={12}>
          <GKOtpInput
            value={otpCode}
            onChange={setOtpCode}
            setIsTyping={setIsDoneTyping}
          />
        </Grid2>
      </Grid2>
      <Grid2
        size={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"10px"}
      >
        <GKButton
          disabled={resendCount >= 3}
          onClick={() => {
            resendOTP();
            setResendCount(resendCount + 1);
            setCount(900);
          }}
        >
          Resend OTP
        </GKButton>
        <Divider orientation="vertical" />
        <GKButton
          onClick={() => {
            navigate(-1);
          }}
        >
          Change Number
        </GKButton>
      </Grid2>
      <Grid2 size={12} textAlign={"center"}>
        {isLoading && <CircularProgress size={50} />}{" "}
      </Grid2>
    </Grid2>
  );
};

export default OTPRegistration;
