import DashboardLayout from "layouts/DashboardLayout";
import { Transaction } from "Pages";
import PortfolioSummaryTradeBookTab from "Pages/Portfolio/ReportsPages/PortfolioSummaryTradeBookTab";
import { useParams } from "react-router-dom";
import { QueryKeys } from "utils/queryKey";

const FixedDepositInterestPayout = () => {
  const { id } = useParams();

  return (
    <DashboardLayout title="Fixed Deposit Interest">
      {id ? (
        <PortfolioSummaryTradeBookTab
          name={"portfolioFDPayoutList"}
          apiRoute={`/portfolios/${id}/other_assets/transactions/`}
          requestParams={{
            key: "asset_type",
            value: "INTEREST_PAYOUT",
          }}
          apiRouteKey={QueryKeys.PORTFOLIO_FD_PAYOUT}
          tableName="portfolio_FD_Payout_list"
          bulkDelete={"/other_assets/bulk_delete/?asset_type=INTEREST_PAYOUT"}
          exportRoute={`/portfolios/${id}/other_assets/transactions/`}
          routeNavigate={{
            navigate: "/app/create-fixed-deposit-transaction",
            transactionType: "OTHER-ASSETS",
            assetType: "INTEREST_PAYOUT",
            editNavigate: "/app/edit-fixed-deposit-transaction",
          }}
          portfolioId={id}
        />
      ) : (
        <Transaction
          title={"Fixed Deposit Interest"}
          name={"FDPayoutList"}
          apiRoute={"/other_assets/"}
          requestParams={{
            key: "asset_type",
            value: "INTEREST_PAYOUT",
          }}
          apiRouteKey={QueryKeys.FDPayoutList}
          tableName="FD_payout_list"
          routeNavigate={{
            navigate: "/app/create-fixed-deposit-transaction",
            transactionType: "OTHER-ASSETS",
            assetType: "INTEREST_PAYOUT",
            editNavigate: "/app/edit-fixed-deposit-transaction",
          }}
          bulkDelete={"/other_assets/bulk_delete/?asset_type=INTEREST_PAYOUT"}
        />
      )}
    </DashboardLayout>
  );
};

export default FixedDepositInterestPayout;
