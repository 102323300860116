import { GridColDef } from "@mui/x-data-grid-pro";
import FamilyCreateModal from "Pages/Client/FamilyCreateModal";
import { CgMathPlus, GKButton, GKDataGrid, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import { GET_FAMILY } from "utils/apiRoutes";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const Family = () => {
  useDocumentTitle("Family");

  const navigate = useNavigate();

  const [isFamilyCreateModalOpen, setIsFamilyCreateModalOpen] = useState(false);

  const requestBody = useGenerateQueryParams("family");

  const { data, isFetching } = useFetchQuery({
    key: [QueryKeys.FAMILY_LIST, requestBody],
    route: GET_FAMILY,
    requestBody,
  });

  const familyScreenColumns: GridColDef[] = [
    {
      ...extraColumnsField("Family Name", "name", 150, 1),
    },
    {
      ...extraColumnsField("Net Contribution", "netContribution", 150, 1),
      type: "number",
      valueGetter: (params) => params?.row?.netContribution || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Invested Value", "investedValue", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage,
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
    },
  ];

  return (
    <>
      <GKDataGrid
        rows={data?.data?.data || []}
        columns={familyScreenColumns}
        loading={isFetching}
        onRowClick={(value) => navigate(`/app/family/${value.id}`)}
        maxHeight={"calc(100vh - 312px)"}
        totalCount={data?.data?.totalCount || 0}
        zeroHolding
        name="family"
        headerComponent={
          <GKButton
            className="add-new-family"
            variant="contained"
            size="medium"
            onClick={() => setIsFamilyCreateModalOpen(true)}
            startIcon={<CgMathPlus size={14} />}
          >
            Add Family
          </GKButton>
        }
        exportFile={{
          path: "/family/",
          exportName: "FamilyList",
        }}
      />
      {isFamilyCreateModalOpen && (
        <FamilyCreateModal
          open={isFamilyCreateModalOpen}
          setOpen={setIsFamilyCreateModalOpen}
        />
      )}
    </>
  );
};

export default Family;
