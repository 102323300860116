import { CgMathPlus, GKButton, GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { GET_ALL_EMPLOYEE_LIST } from "utils/apiRoutes";
import { employeeColumn } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import AddEmployee from "./AddBranchAdmins";

const BranchAdmins = ({ path }: { path?: any }) => {
  useDocumentTitle("Admins");

  const navigate = useNavigate();

  const [addEmployeeModal, setAddEmployeeModal] = useState(false);

  const requestBody = useGenerateQueryParams("employee");

  const { data: employeeData, isLoading } = useFetchQuery({
    key: [QueryKeys.EMPLOYEE_LIST, requestBody, path],
    route: path || GET_ALL_EMPLOYEE_LIST,
    requestBody,
  });

  return (
    <>
      <GKDataGrid
        rows={employeeData?.data?.data || []}
        columns={employeeColumn}
        loading={isLoading}
        maxHeight={"calc(100vh - 312px)"}
        onRowClick={(row) => {
          navigate(`/app/admin/${row.id}`);
        }}
        totalCount={employeeData?.data?.totalCount || 0}
        name="employee"
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => setAddEmployeeModal(true)}
            startIcon={<CgMathPlus size={14} />}
          >
            Add Admin
          </GKButton>
        }
        tableName="employee_list"
        headerFilter={<TableFiltering name="employee" />}
        exportFile={{
          path: GET_ALL_EMPLOYEE_LIST,
          exportName: "EmployeeList",
        }}
      />

      {addEmployeeModal && (
        <AddEmployee open={addEmployeeModal} setOpen={setAddEmployeeModal} />
      )}
    </>
  );
};

export default BranchAdmins;
