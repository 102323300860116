import IIFLBrokerConfigure from "./IIFLBrokerConfigure";

interface Props {
  broker: string;
}

const BrokerConfiguration = (props: Props) => {
  const { broker } = props;

  return broker === "IIFL" && <IIFLBrokerConfigure />;
};

export default BrokerConfiguration;
