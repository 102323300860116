import { GridColDef } from "@mui/x-data-grid-pro";
import { GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useLocation, useParams } from "react-router-dom";
import { MANDATE_PRESENTATION_TRANSACTION_LIST } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { removeUnderscoreAndFirstLetterCapital } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import MandateDetailsPageCard from "./MandateDetailsPageCard";

const MandatePresentationTransaction = () => {
  useDocumentTitle("Mandate Presentation Transactions");

  const location = useLocation();
  const { clientName, portfolioId, portfolioName } = location.state || {};

  const { presentationId } = useParams();

  const requestBody = useGenerateQueryParams("mandatePresentationTransaction");

  const { data, isFetching } = useFetchQuery({
    key: ["MANDATE_PRESENTATION_TRANSACTION_LIST", requestBody],
    route: MANDATE_PRESENTATION_TRANSACTION_LIST(presentationId),
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "settlementDate", 100, 1),
      type: "date",
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Amount", "amount", 150, 1),
      valueGetter: (row) => row?.row?.amount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Invoice Number", "invoice", 150, 1),
      valueGetter: (row) => row?.row?.invoice || "-",
    },
    {
      ...extraColumnsField("Status", "status", 150, 1),
      valueGetter: (row) =>
        removeUnderscoreAndFirstLetterCapital(row?.row?.status),
    },
  ];
  return (
    <DashboardLayout title="Mandate Presentation Transactions">
      <MandateDetailsPageCard
        clientName={clientName}
        portfolioId={portfolioId}
        portfolioName={portfolioName}
      />
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        totalCount={data?.data?.totalCount || 0}
        name="mandatePresentationTransaction"
        maxHeight={"calc(100vh - 321px)"}
      />
    </DashboardLayout>
  );
};

export default MandatePresentationTransaction;
