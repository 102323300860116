import { Box, Grid2, Tab, Tabs } from "@mui/material";
import { Show } from "components/Show";
import AdvisorCompanyMasterLayout from "layouts/AdvisorCompanyMasterLayout";
import { useState } from "react";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import PrivateEquity from "./PrivateEquity";

const CompanyMasterDashboard = () => {
  useDocumentTitle("Company Master Dashboard");

  const [tabValue, setTabValue] = useState(0);

  const { theme } = ThemeContainer();

  const tabs = [
    {
      label: "Private Equity",
      component: (
        <PrivateEquity
          name={"privateEquity"}
          tableName={"private_equity"}
          type={"PEQ"}
          routeKey={"FETCH_ALL_PRIVATE_EQUITY"}
        />
      ),
    },
    {
      label: "Fixed Deposit",
      component: (
        <PrivateEquity
          name={"privateEquity"}
          tableName={"private_equity"}
          type={"FD"}
          routeKey={"FETCH_ALL_PRIVATE_EQUITY"}
        />
      ),
    },
  ];

  return (
    <AdvisorCompanyMasterLayout title="Other Assets">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => setTabValue(val)}
              value={tabValue}
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} disableRipple />
              ))}
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When key={index} isTrue={tabValue === index}>
              {tab.component}
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </AdvisorCompanyMasterLayout>
  );
};

export default CompanyMasterDashboard;
