import { Grid2, TableContainer } from "@mui/material";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import { CommonFilterState } from "interfaces/CommonFilter";
import { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { LuSearch, LuTable2 } from "react-icons/lu";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdExpandLess,
  MdExpandMore,
} from "react-icons/md";
import { ThemeContainer } from "utils/Theme";
import CommonPagination from "./CommanPagination";
import { CustomLoader, EmptyData, Pagination } from "./DatagridUtils";
import ExportCsv from "./ExportCsv";
import GKButton from "./GKButton";
import GKCheckbox from "./GKCheckbox";
import GKCommonTableFilterModal from "./GKCommonTableFilterModal";
import GKTextField from "./GKTextField";
import { Show } from "./Show";
import TableColumnModal from "./TableColumnModal";

interface Props {
  headerComponent?: any;
  showSearch?: boolean;
  columnsButton?: boolean;
  ref?: any;
  maxHeight?: any;
  showHeaderGrid?: any;
  zeroHolding?: boolean;
  minHeight?: any;
  height?: string;
  tableName?: string;
  name: string;
  headerFilter?: any;
  exportFile?: {
    path?: string;
    exportName?: string;
    paramsValue?: {
      key?: string;
      value?: string;
    };
  };
  totalCount?: number;
}

const GKDataGrid = (props: DataGridProProps & Props) => {
  const { theme } = ThemeContainer();

  const {
    state: { columnsPersist, commonFilter },
    dispatch,
    apiRef,
  } = useAppContext();

  const [tableColumnModalOpen, setTableColumnModalOpen] = useState(false);

  const {
    rows,
    headerComponent,
    showSearch = true,
    loading,
    ref,
    columnsButton = true,
    maxHeight,
    showHeaderGrid = true,
    pinnedColumns,
    zeroHolding,
    onRowClick,
    height,
    minHeight,
    tableName,
    name,
    headerFilter,
    columns,
    exportFile,
    totalCount,
  } = props;

  const [filterModal, setFilterModal] = useState(false);

  const [searchText, setSearchText] = useState(
    commonFilter[name as keyof CommonFilterState]?.search,
  );

  useEffect(() => {
    const timerId = setTimeout(() => {
      dispatch({
        type: "FILTER",
        data: {
          ...commonFilter,
          [name]: {
            ...commonFilter[name as keyof CommonFilterState],
            search: searchText?.trim(),
          },
        },
      });
    }, 400);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  function CheckboxWrapper(props: any) {
    return (
      <GKCheckbox
        icon={<MdCheckBoxOutlineBlank />}
        checkedIcon={<MdCheckBox />}
        {...props}
      />
    );
  }

  const exportCsv = () => {
    return (
      <ExportCsv
        path={exportFile?.path}
        name={exportFile?.exportName}
        paramsValue={exportFile?.paramsValue}
        filter={JSON.stringify(
          commonFilter[name as keyof CommonFilterState]?.customFilter,
        )}
      />
    );
  };

  const CustomPagination = () => {
    return <CommonPagination name={name} totalCount={totalCount} />;
  };

  return (
    <Grid2
      container
      sx={{
        "& .MuiTableContainer-root": {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        },
      }}
    >
      <Grid2 size={12}>
        <Show.When isTrue={showHeaderGrid}>
          <Grid2
            container
            justifyContent={"space-between"}
            p={1}
            alignItems={"center"}
            ref={ref}
            border={`solid 1px ${theme.palette.grey[300]}`}
            borderBottom={`solid 0px ${theme.palette.grey[300]}`}
            sx={{
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",

                gap: 10,
                width: "100%",
              }}
            >
              <Grid2 size={6}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <Show.When isTrue={showSearch}>
                    <GKTextField
                      style={{ width: 180 }}
                      placeholder="Search"
                      sx={{
                        "& .MuiInputBase-root ": {
                          height: "35px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #E3E8EF",
                        },
                      }}
                      value={searchText}
                      onChange={(event: any) => {
                        setSearchText(event.target.value);
                      }}
                      type="search"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <LuSearch
                              size={22}
                              color={theme.palette.grey[500]}
                            />
                          ),
                        },
                      }}
                    />
                  </Show.When>
                  <Show.When isTrue={Boolean(tableName)}>
                    <GKButton
                      style={{
                        padding: "6px 10px",
                        color: theme.palette.secondaryText.main,
                        border: "1px solid #E3E8EF",
                      }}
                      onClick={() => setFilterModal(true)}
                      startIcon={<IoFilter size={16} />}
                    >
                      Filter
                    </GKButton>
                  </Show.When>
                  <Show.When isTrue={!!exportFile?.path}>
                    {exportCsv()}
                  </Show.When>
                  <Show.When isTrue={columnsButton}>
                    <GKButton
                      onClick={() => {
                        setTableColumnModalOpen(true);
                      }}
                      style={{
                        padding: "6px 10px",
                        color: theme.palette.secondaryText.main,
                        border: "1px solid #E3E8EF",
                      }}
                      startIcon={<LuTable2 size={16} />}
                    >
                      Columns
                    </GKButton>
                  </Show.When>
                </span>
              </Grid2>
              <Grid2 size={6} display={"flex"} justifyContent={"flex-end"}>
                <Show.When isTrue={headerComponent}>
                  {headerComponent}
                </Show.When>{" "}
              </Grid2>
            </div>
            {headerFilter && (
              <Grid2 container display={"flex"} alignItems={"center"} mt={1}>
                {headerFilter}
              </Grid2>
            )}
          </Grid2>
        </Show.When>
      </Grid2>
      <Grid2 size={12}>
        <TableContainer>
          <DataGridPro
            {...props}
            columnVisibilityModel={
              columnsPersist && name && (columnsPersist[name] as any)
            }
            apiRef={apiRef}
            getDetailPanelHeight={() => "auto"}
            sortingMode="server"
            filterMode="server"
            keepNonExistentRowsSelected
            disableRowSelectionOnClick
            sortModel={commonFilter[name as keyof CommonFilterState]?.sortModel}
            loading={loading}
            rows={loading ? [] : rows || []}
            columns={columns}
            onSortModelChange={(model) => {
              dispatch({
                type: "FILTER",
                data: {
                  ...commonFilter,
                  [name]: {
                    ...commonFilter[name as keyof CommonFilterState],
                    sortModel: model,
                  },
                },
              });
            }}
            slots={{
              noRowsOverlay: EmptyData,
              loadingOverlay: CustomLoader,
              detailPanelExpandIcon: () => <MdExpandMore size={20} />,
              detailPanelCollapseIcon: () => <MdExpandLess size={20} />,
              footer: totalCount ? CustomPagination : Pagination,
              baseCheckbox: CheckboxWrapper,
            }}
            pinnedColumns={{
              ...pinnedColumns,
              right: ["action"],
            }}
            sx={{
              "& .MuiDataGrid-virtualScroller": {
                maxHeight: maxHeight || "calc(100vh - 256px)",
                minHeight: minHeight || "10px",
              },
              "&.MuiDataGrid-root .MuiTablePagination-root": {
                padding: "0 8px",
              },
              "& .MuiDataGrid-row": {
                "&:hover": {
                  cursor: onRowClick ? "pointer" : "",
                },
              },
              minHeight: height,
              maxHeight: height,
            }}
          />

          {filterModal && tableName && (
            <GKCommonTableFilterModal
              open={filterModal}
              setOpen={setFilterModal}
              tableName={tableName}
              name={name}
              zeroHolding={zeroHolding}
            />
          )}

          <Show.When isTrue={tableColumnModalOpen}>
            <TableColumnModal
              open={tableColumnModalOpen}
              setOpen={setTableColumnModalOpen}
              columns={columns}
              columnVisibilityModel={columnsPersist}
              dispatch={dispatch}
              name={name}
            />
          </Show.When>
        </TableContainer>
      </Grid2>
    </Grid2>
  );
};

export default GKDataGrid;
