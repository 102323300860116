/* eslint-disable react/no-this-in-sfc */
import { Card } from "components";
import GKCharts from "components/GKCharts";
import Highcharts from "highcharts/highstock";
import { formatYAxisLabel } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  data: [number, number][];
  loading: boolean;
}

const HighChartLine = ({ data, loading }: Props) => {
  const { theme } = ThemeContainer();
  const options = {
    chart: {
      backgroundColor: theme.palette.light.main,
      borderRadius: 5,
      type: "area",
      height: 500,
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: `<h1 id="chart-title">Total Assets Under Management</h1>`,
      align: "left",
      style: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: 18,
      },
    },
    yAxis: {
      opposite: false,
      gridLineColor: `${theme.palette.border.main}44`,
      color: theme.palette.error.main,
      labels: {
        formatter(this: any) {
          return formatYAxisLabel(this?.value);
        },
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
    },
    tooltip: {
      formatter(this: any) {
        const formattedDate = Highcharts.dateFormat("%b %e, %Y", this.x);

        let content = `<b>${formattedDate}</b><br/>`;

        this.points.forEach((point: any) => {
          content += `<span style="color:${point.color}">\u25CF</span> ${
            point.series.name
          }: <b>${moneyCommaSeparator(point.y)}</b><br/>`;
        });

        return content;
      },
    },
    series: [
      {
        name: "Total Assets Under Management",
        data: data?.sort((a, b) => a[0] - b[0]) || [],
        tooltip: {
          valueDecimals: 2,
          pointFormatter(this: any) {
            return `<span style="color:${this.color}">\u25CF</span> ${
              this.series.name
            }: <b>${moneyCommaSeparator(this.y)}</b><br/>`;
          },
          formatter(this: any) {
            return `<b></b>`;
          },
        },
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        fillColor: {
          linearGradient: [0, 0, 0, 400],
          stops: [
            [0, theme.palette.primary.main],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
    ],
  };

  return (
    <Card>
      <GKCharts
        constructorType="stockChart"
        chartOptions={options}
        rangeSelector
        loading={loading}
        navigator
      />
    </Card>
  );
};

export default HighChartLine;
