/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import { GridColDef } from "@mui/x-data-grid-pro";
import ClientModal from "Pages/Portfolio/ClientModal";
import {
  CgMathPlus,
  GKButton,
  GKDataGrid,
  Grid2,
  Typography,
} from "components";
import { useClient } from "hooks/useClient";
import { useNavigate } from "react-router-dom";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  path?: string;
}

const ClientList = ({ path }: Props) => {
  useDocumentTitle("Client");

  const navigate = useNavigate();

  const {
    getClientList,
    isClientLoading,
    isClientCreateModalOpen,
    setIsClientCreateModalOpen,
  } = useClient({ path });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name / Code", "name", 180, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.name || "-"}</Typography>
            <Typography>{params?.row?.clientCode || "-"}</Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Net Contribution", "netContribution", 150, 1),
      type: "number",
      valueGetter: (params) => params?.row?.netContribution || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value) || "0",
    },
    {
      ...extraColumnsField("Invested Value", "investedValue", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value) || "0",
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value) || "0",
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage,
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value) || "0",
    },
  ];

  return (
    <>
      <GKDataGrid
        rows={getClientList?.data || []}
        columns={columns}
        loading={isClientLoading}
        onRowClick={(value) => {
          navigate(`/app/clients/${value.id}`);
        }}
        totalCount={getClientList?.totalCount || 0}
        maxHeight={"calc(100vh - 321px)"}
        zeroHolding
        headerComponent={
          <GKButton
            className="add-new-client"
            variant="contained"
            size="medium"
            onClick={() => setIsClientCreateModalOpen(true)}
            startIcon={<CgMathPlus size={18} />}
          >
            Add Client
          </GKButton>
        }
        name="client"
        tableName="client_list"
        headerFilter={<TableFiltering name="client" />}
        exportFile={{
          path: "/client/",
          exportName: "ClientList",
        }}
      />

      {isClientCreateModalOpen && (
        <ClientModal
          open={isClientCreateModalOpen}
          setOpen={setIsClientCreateModalOpen}
        />
      )}
    </>
  );
};

export default ClientList;
