import { GridColDef } from "@mui/x-data-grid-pro";
import ImportBrokerModal from "Pages/CustomBrokerImport/ImportBrokerModal";
import {
  GKButton,
  GKDataGrid,
  Grid2,
  PiArrowSquareDownRightDuotone,
  Show,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { LEDGER_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import { returnParticularColor } from "utils/constants";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 150, 1),
    valueFormatter: (value) => matchDate(value.value),
  },
  {
    ...extraColumnsField("Portfolio", "clientName", 245, 1),
    renderCell: (params) => {
      return (
        <Grid2
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography
            fontWeight={700}
            maxWidth={"100%"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {params?.row?.clientName}
          </Typography>
          <Typography fontSize={12}>
            {params?.row?.portfolioName} - {params?.row?.portfolioCode}
          </Typography>
        </Grid2>
      );
    },
  },
  {
    ...extraColumnsField("Particulars", "transactionType", 180, 1),
    renderCell: (params) => {
      return (
        <Typography
          fontWeight={600}
          style={{
            width: "fit-content",
            background: `${returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType,
            )}33`,
            color: returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType,
            ),
            padding: "4px 8px",
            borderRadius: 6,
          }}
          textTransform={"capitalize"}
        >
          {params?.row?.transactionType?.toLowerCase() === "sell" ||
          params?.row?.transactionType?.toLowerCase() === "buy"
            ? `BILLAMOUNT - ${params?.row?.transactionType}`
            : params?.row?.transactionType}
          &nbsp;
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
              ? `/ ${handleRemoveUnderscore(
                  params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                    ?.addedField,
                )}`
              : ""}
          </span>
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Debit", "debit", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.debit || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Credit", "credit", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.credit || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Ledger Balance", "ledgerBalance", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.ledgerBalance || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Remarks", "remarks", 150, 1),
    valueGetter: (params) => params.row.remarks || "-",
  },
];

interface Props {
  portfolioDetailData: PortfolioDetailsInterface;
}

const PortfolioLedger = (props: Props) => {
  const { id } = useParams();

  const [openImportBrokerModal, setOpenImportBrokerModal] = useState(false);

  const requestBody = useGenerateQueryParams("portfolioBankLedger");

  const { data, isFetching, refetch } = useFetchQuery({
    route: LEDGER_BY_PORTFOLIO_ID(id),
    key: [QueryKeys.PORTFOLIO_BANK_LEDGER_LIST, requestBody],
    requestBody,
  });

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={columns || []}
        rows={data?.data?.data || []}
        totalCount={data?.data?.totalCount || 0}
        name="portfolioBankLedger"
        maxHeight={"calc(100vh - 309px)"}
        tableName="portfolio_bank_ledger_list"
        headerFilter={<TableFiltering name="portfolioBankLedger" />}
        headerComponent={
          <Grid2 container>
            <Grid2
              size={12}
              display={"flex"}
              alignItems={"center"}
              gap={1}
              justifyContent={"flex-end"}
            >
              {props?.portfolioDetailData?.broker !== "OTHER" && (
                <GKButton
                  variant="contained"
                  size="medium"
                  onClick={() => setOpenImportBrokerModal(true)}
                  startIcon={<PiArrowSquareDownRightDuotone size={16} />}
                >
                  Import from broker
                </GKButton>
              )}
            </Grid2>
          </Grid2>
        }
        exportFile={{
          path: LEDGER_BY_PORTFOLIO_ID(id),
          exportName: "bankLedgerList",
        }}
      />

      <Show.When isTrue={openImportBrokerModal}>
        <ImportBrokerModal
          open={openImportBrokerModal}
          setOpen={setOpenImportBrokerModal}
          refetch={refetch}
          type={props?.portfolioDetailData?.broker}
          subType={"LEDGER"}
        />
      </Show.When>
    </>
  );
};

export default PortfolioLedger;
