import { GridColDef } from "@mui/x-data-grid-pro";
import {
  GKDataGrid,
  GoPlus,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { moneyCommaSeparator, numberToCurrency } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { ThemeContainer } from "utils/Theme";
import { GET_HOLDING_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const HoldingsUserDetails = () => {
  useDocumentTitle("Holding Details");

  const location = useLocation();

  const navigate = useNavigate();

  const { theme } = ThemeContainer();

  const { id } = useParams<{ id: string }>();

  const { data: holdingDetails, isFetching } = useFetchQuery({
    key: [
      id,
      location?.state?.portfoliosIds,
      QueryKeys.INSIGHTS_HOLDINGS_DETAILS,
    ],
    route: `/holdings/${id}/portfolios/`,
    requestBody: {
      portfolios: JSON.stringify(location?.state?.portfoliosIds),
    },
  });

  const requestBody = useGenerateQueryParams("holdingDetails", {
    portfolios: JSON.stringify(location?.state?.portfoliosIds),
  });

  const { data, isLoading } = useFetchQuery({
    key: [QueryKeys.HOLDING_DETAILS, requestBody],
    route: GET_HOLDING_BY_PORTFOLIO_ID(id),
    requestBody,
  });

  const detail = holdingDetails?.data;

  const holdingsUserDetailsColumns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.name} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Quantity", "rawQuantity", 150, 1),
      valueGetter: (params) => params.row.rawQuantity || "0",
      valueFormatter: (params) => params.value,
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Avg. Buy Price", "avgBuyPrice", 150, 1),
      valueGetter: (params) => params.row.avgBuyPrice || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Invested Value", "investedAmount", 150, 1),
      valueGetter: (params) => params.row.investedAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Current Value", "currentAmount", 150, 1),
      valueGetter: (params) => params.row.currentAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Weightage (%)", "weightage", 150, 1),
      type: "number",
    },
  ];

  return (
    <DashboardLayout title="Holding Details" loading={isFetching}>
      <Grid2 container spacing={2}>
        <Grid2 size={6}>
          <Typography fontWeight={700} fontSize={20}>
            {detail?.fullName}
          </Typography>
          <Typography fontWeight={600} fontSize={16}>
            <span
              style={{
                fontWeight: 400,
                fontSize: 12,
                color: theme.palette.secondaryText.main,
                marginRight: 4,
              }}
            >
              CMP
            </span>
            {detail?.cmp}{" "}
            <span
              style={{
                color: handleReturnPositiveNegative(
                  detail?.todaysGainPercentage,
                ),
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              ( {detail?.todaysGainPercentage}% )
            </span>
          </Typography>
        </Grid2>
        <Grid2 size={6} textAlign={"right"}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.border.main}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 8,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        NSE
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {detail?.nse || "-"}{" "}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.border.main}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 8,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        BSE
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {detail?.bse || "-"}
                      </Typography>
                    </div>
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.border.main}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 8,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        ISIN
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {detail?.isin || "-"}{" "}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2 size={6}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.border.main}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography
                      fontSize={12}
                      color={theme.palette.secondaryText.main}
                    >
                      Holding Qty.
                    </Typography>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {detail?.rawQuantity}
                      <span
                        style={{
                          display: "flex",
                          fontSize: 12,
                          color: theme.palette.primary.main,
                          fontWeight: 400,
                        }}
                      >
                        &nbsp;
                        <img loading="lazy" src="/Logos/longTerm.svg" alt="" />
                        &nbsp;
                        {detail?.ltHoldingsCount}
                      </span>
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.border.main}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography
                      fontSize={12}
                      color={theme.palette.secondaryText.main}
                    >
                      Avg. Buy Price
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(detail?.avgBuyPrice || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography
                      fontSize={12}
                      color={theme.palette.secondaryText.main}
                    >
                      Invested Value
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(detail?.investedAmount || 0)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={detail?.instSubType === "MF" ? 3 : 1}
                    style={{
                      borderRight: `solid 1px ${theme.palette.border.main}`,
                      borderTop: `solid 1px ${theme.palette.border.main}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography
                      fontSize={12}
                      color={theme.palette.secondaryText.main}
                    >
                      Total Holding Value{" "}
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(detail?.totalHoldingValue || 0)}
                    </Typography>
                  </TableCell>
                  {detail?.instSubType !== "MF" && (
                    <TableCell
                      style={{
                        borderRight: `solid 1px ${theme.palette.border.main}`,
                        borderTop: `solid 1px ${theme.palette.border.main}`,
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Buy Amount
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(detail?.totalBuyAmount || 0)}
                      </Typography>
                    </TableCell>
                  )}
                  {detail?.instSubType !== "MF" && (
                    <TableCell
                      style={{
                        borderTop: `solid 1px ${theme.palette.border.main}`,
                        borderBottom: "none",
                        padding: 16,
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        Total Sell Amount
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(detail?.totalSellAmount || 0)}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2 size={6}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    style={{
                      borderRight: `solid 1px ${theme.palette.border.main}`,
                      borderBottom: "none",
                      padding: 16,
                      width: "50%",
                    }}
                  >
                    <Typography
                      fontSize={12}
                      color={theme.palette.secondaryText.main}
                    >
                      Total Gain{" "}
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(detail?.totalGain || 0)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{
                      borderRight: `solid 1px ${theme.palette.border.main}`,
                      borderTop: `solid 1px ${theme.palette.border.main}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Grid2 container spacing={1}>
                      <Grid2 size={2.4}>
                        <Typography
                          fontSize={12}
                          color={theme.palette.secondaryText.main}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          Unrealised Gain
                        </Typography>
                        <Typography fontWeight={600} fontSize={14}>
                          {numberToCurrency(detail?.unrealisedGain || 0)}
                        </Typography>
                      </Grid2>
                      <Grid2
                        size={2.4}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <span
                          style={{
                            background: "#F8FAFC",
                            borderRadius: 4,
                            height: 24,
                            width: 24,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <GoPlus />
                        </span>
                      </Grid2>
                      <Grid2 size={2.4}>
                        <Typography
                          fontSize={12}
                          color={theme.palette.secondaryText.main}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          Realised Gain{" "}
                        </Typography>
                        <Typography fontWeight={600} fontSize={14}>
                          {numberToCurrency(detail?.realisedGain || 0)}
                        </Typography>
                      </Grid2>
                      <Grid2
                        size={2.4}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <span
                          style={{
                            background: "#F8FAFC",
                            borderRadius: 4,
                            height: 24,
                            width: 24,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <GoPlus />
                        </span>
                      </Grid2>
                      <Grid2 size={2.4}>
                        <Typography
                          fontSize={12}
                          color={theme.palette.secondaryText.main}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          Dividend{" "}
                        </Typography>
                        <Typography fontWeight={600} fontSize={14}>
                          {numberToCurrency(detail?.dividend || 0)}
                        </Typography>
                      </Grid2>
                    </Grid2>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2 size={12}>
          <GKDataGrid
            columns={holdingsUserDetailsColumns}
            rows={data?.data?.data || []}
            loading={isLoading}
            zeroHolding
            totalCount={data?.data?.totalCount || 0}
            name="holdingDetails"
            tableName="holding_details"
            headerFilter={<TableFiltering name="holdingDetails" />}
            pinnedColumns={{
              left: ["clientName", "name"],
            }}
            onRowClick={(params) => {
              navigate(
                `/app/holdings/${id}/transactions?type=${params?.row?.instType}`,
                {
                  state: {
                    holdingId: params?.row?.id,
                    gcode: id,
                    name: params?.row?.name,
                    folioNo: params?.row?.folioNo,
                    quantity: params?.row?.rawQuantity,
                    investedAmount: params?.row?.investedAmount,
                    currentAmount: params?.row?.currentAmount,
                    type: "portfolios",
                  },
                },
              );
            }}
            exportFile={{
              path: GET_HOLDING_BY_PORTFOLIO_ID(id),
              exportName: "PortfolioHoldingsList",
            }}
          />
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default HoldingsUserDetails;
