import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  FiTrash2,
  GKButton,
  GKDataGrid,
  Grid2,
  IconButton,
  Show,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FEE_PLAN_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";

const FeePlans = () => {
  useDocumentTitle("Fee Plan Setup");

  const navigate = useNavigate();

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectFeePlanId, setSelectFeePlanId] = useState<any>(null);

  const requestBody = useGenerateQueryParams("feePlans");
  const {
    data: getFeePlans,
    isFetching: feePlansFetching,
    refetch,
  } = useFetchQuery({
    key: ["GET_FEE_PLAN_LIST", requestBody],
    route: FEE_PLAN_LIST,
    requestBody,
  });

  const { mutate: deleteFeePlan, isLoading } = useMutation({
    mutationKey: ["DELETE_FEE_PLAN"],
    mutationFn: (id) => axiosInstance.delete(`/fee-plans/${id}/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
      setSelectFeePlanId(null);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Fee Plan Name", "name", 150, 1),
      valueGetter: (params) => params.row.name || "-",
    },
    {
      ...extraColumnsField("Revenue Products", "revenueProducts", 150, 1),
      valueGetter: (params) =>
        params.row.revenueProducts
          ?.map((dataItem: any) => dataItem.replace("_", " "))
          .join(", ") || "-",
    },
    {
      ...extraColumnsField("AUM Mapped", "totalAumMapped", 150, 1),
      valueGetter: (param) => param?.row?.totalAumMapped,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Portfolio Mapped", "totalPortfolio", 150, 1),
      valueGetter: (param) => param?.row?.totalPortfolio,
    },
    {
      ...extraColumnsField("Fee Billed", "totalFeeGenerated", 150, 1),
      valueGetter: (param) => param?.row?.totalFeeGenerated,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Fee Collected", "totalFeeCollected", 150, 1),
      valueGetter: (param) => param?.row?.totalFeeCollected,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Grid2 container gap={1} display={"flex"} justifyContent={"flex-end"}>
          <IconButton
            className="outline"
            color="error"
            disabled={params.row.id === selectFeePlanId && isLoading}
            onClick={(e) => {
              e.stopPropagation();
              setSelectFeePlanId(params.id);
              setConfirmationModal(true);
            }}
          >
            <FiTrash2 size={20} />
          </IconButton>
        </Grid2>
      ),
    },
  ];

  return (
    <>
      <GKDataGrid
        loading={feePlansFetching}
        columns={columns}
        rows={getFeePlans?.data?.data || []}
        maxHeight={"calc(100vh - 312px)"}
        name="feePlans"
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => {
              navigate("/app/fee-plans/create-fee-plan");
            }}
            startIcon={<CgMathPlus size={16} />}
          >
            Create Fee Plan
          </GKButton>
        }
        onRowClick={(row) => {
          navigate(`/app/fee-plans/${row.id}/edit-fee-plan`);
        }}
        totalCount={getFeePlans?.data?.totalCount || 0}
      />

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => deleteFeePlan(selectFeePlanId)}
          title={"Fee Plan"}
          isDelete
        />
      </Show.When>
    </>
  );
};

export default FeePlans;
