import {
  Avatar,
  Button,
  Divider,
  Grid2,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { ProfileAPI } from "hooks/ProfileAPI";
import { useState } from "react";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { FiArrowLeft, FiArrowRight, FiEdit, FiLogOut } from "react-icons/fi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { LuCircleDollarSign, LuUser, LuWallet } from "react-icons/lu";
import { MdOutlineCandlestickChart, MdSupervisorAccount } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { VscSettings } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";
import { UserRoleTypes } from "utils/common";
import AvatarImg from "../assets/avatar.png";

const CustomButton = styled(Button)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 8,
}));

// const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     right: 20,
//     top: 50,
//     border: `3px solid ${theme.palette.background.paper}`,
//     padding: "0 4px",
//     textTransform: "uppercase",
//     fontWeight: 600,
//   },
// }));

const ProfileMenu = ({ handleGetOPT }: any) => {
  const { theme } = ThemeContainer();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const {
    state: { userData: userInfo, settingTab },
    dispatch,
  } = useAppContext();

  const { handleUpdateProfileRole } = ProfileAPI();

  const queryClient = useQueryClient();

  const [showAccountButtons, setShowAccountButtons] = useState(false);

  // const { mutate: handleLogout } = useMutation({
  //   mutationFn: () => axiosInstance?.post(`/user-logout/`),
  //   onSuccess: () => {
  //     window.location.reload();
  //     localStorage.removeItem(`role`);
  //     localStorage.removeItem(`token`);
  //     localStorage.removeItem(`isInvestor`);
  //     localStorage.removeItem(`isAdvisor`);
  //     localStorage.removeItem(`userInfo`);
  //     localStorage.removeItem(`version`);
  //     localStorage.removeItem("breadcumbs");
  //     queryClient.clear();
  //   },
  //   onError: () => {
  //     toast.error(`Something went wrong while logging you out`);
  //   },
  // });

  const items = [
    {
      name: "Advisor",
      isActive: UserRoleTypes.advisor === userInfo?.userRole,
      value: UserRoleTypes.advisor,
      show: userInfo?.isEmployee,
      click: () => {
        localStorage?.setItem("role", UserRoleTypes.advisor);
        handleUpdateProfileRole(UserRoleTypes.advisor);
      },
      icon: LuCircleDollarSign,
      stroke: "1.5",
    },
    {
      name: "Investor",
      isActive: UserRoleTypes.client === userInfo?.userRole,
      value: UserRoleTypes.client,
      show: userInfo?.isClient,
      click: () => {
        localStorage?.setItem("role", UserRoleTypes.client);
        handleUpdateProfileRole(UserRoleTypes.client);
      },
      icon: MdOutlineCandlestickChart,
      stroke: "0.2",
    },
    {
      name: "Partner",
      isActive: UserRoleTypes.partner === userInfo?.userRole,
      show: userInfo?.isPartner,
      value: UserRoleTypes.partner,
      click: () => {
        localStorage?.setItem("role", UserRoleTypes.partner);
        handleUpdateProfileRole(UserRoleTypes.partner);
        window.location.reload();
      },
      icon: MdSupervisorAccount,
    },
  ];

  const buttonData = [
    {
      name: "User Profile",
      click: () => {
        dispatch({
          type: "SETTING_TAB",
          data: {
            type: "USER_PROFILE",
            name: "User Profile",
          },
        });
        navigate("/app/user-profile/settings");
      },
      icon: LuUser,
      isActive:
        settingTab.type === "USER_PROFILE" &&
        pathname === "/app/user-profile/settings",
      show: true,
    },
    {
      name: "Company Profile",
      click: () => {
        dispatch({
          type: "SETTING_TAB",
          data: {
            name: "Company Profile",
            type: "COMPANY_PROFILE",
          },
        });
        navigate("/app/user-profile/settings");
      },
      icon: HiOutlineOfficeBuilding,
      stroke: "1.5",
      isActive:
        settingTab.type === "COMPANY_PROFILE" &&
        pathname === "/app/user-profile/settings",
      show: userInfo?.userRole !== UserRoleTypes.client,
    },
    {
      name: "Change Password",
      click: () => {
        handleGetOPT();
      },
      icon: FiEdit,
      show: true,
    },
    {
      name: "Settings",
      click: () => {
        dispatch({
          type: "SETTING_TAB",
          data: {
            name: "Portfolio Metrics",
            type: "PORTFOLIO_METRICS",
          },
        });
        navigate("/app/user-profile/settings");
      },
      icon: IoSettingsOutline,
      stroke: "1.5",
      show: userInfo?.userRole !== UserRoleTypes.client,
      isActive:
        settingTab.type === "PORTFOLIO_METRICS" &&
        pathname === "/app/user-profile/settings",
    },
    {
      name: "Investor Settings",
      click: () => {
        navigate("/app/investor-settings");
      },
      icon: VscSettings,
      stroke: "0.1",
      show: userInfo?.broker?.isRaAccess,
      isActive: pathname === "/app/investor-settings",
    },
    {
      name: "Company Master",
      click: () => {
        window.location.href = "/company-master/dashboard";
      },
      icon: RiUserSettingsLine,
      stroke: "0.1",
      show: userInfo?.userRole !== UserRoleTypes.client,
      isActive:
        pathname === "/company-master/dashboard" ||
        pathname === "/company-master/setting",
    },
    {
      name: "Wallet",
      click: () => {
        navigate("/app/wallet");
      },
      icon: LuWallet,
      stroke: "1.5",
      show: userInfo?.userRole !== UserRoleTypes.client,
      isActive: pathname?.includes(`/app/wallet/`),
    },
  ];

  return (
    <Grid2 container>
      <Grid2 size={12} display={"flex"} alignItems={"center"}>
        {/* <StyledBadge
          badgeContent={userInfo?.userRole?.charAt(0)}
          color="primary"
          style={{ textTransform: "uppercase", padding: "16px" }}
        > */}
        <div style={{ padding: "16px" }}>
          <Avatar
            alt=""
            src={
              userInfo?.profilePic?.includes("s3")
                ? userInfo?.profilePic
                : AvatarImg
            }
            sx={{
              width: 40,
              height: 40,
              ".MuiAvatar-img": { borderRadius: "50%" },
              p: "2px",
              border: `2px solid ${userInfo?.userRole === UserRoleTypes.advisor ? paletteLightTheme.palette.info.main : paletteLightTheme.palette.success.main}`,
            }}
          />
        </div>
        {/* </StyledBadge> */}
        <div>
          <Typography fontWeight={600} fontSize={14}>
            {userInfo?.name}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={12}
            color={theme.palette.secondaryText.main}
            display={"flex"}
          >
            {userInfo?.phone}
          </Typography>
        </div>
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
      <Grid2
        size={12}
        display={"flex"}
        onClick={() => {
          setShowAccountButtons(!showAccountButtons);
        }}
      >
        <CustomButton
          fullWidth
          sx={{ padding: "16px", color: theme.palette.secondaryText.main }}
        >
          {showAccountButtons ? (
            <FiArrowLeft size={18} />
          ) : (
            <AiOutlineUserSwitch size={18} />
          )}
          Switch Account
        </CustomButton>

        {!showAccountButtons && (
          <IconButton sx={{ paddingRight: "16px" }}>
            <FiArrowRight size={18} />
          </IconButton>
        )}
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
      <Grid2 size={12}>
        {(showAccountButtons ? items : buttonData)?.map((dataItem: any) => (
          <CustomButton
            fullWidth
            key={dataItem.name}
            onClick={dataItem?.click}
            sx={{
              display: dataItem?.show ? "flex" : "none",
              color: dataItem?.isActive
                ? `${theme.palette.primary.main}`
                : theme.palette.grey[700],
              px: "16px",
            }}
          >
            <dataItem.icon size={16} strokeWidth={dataItem?.stroke} />
            {dataItem.name}
          </CustomButton>
        ))}
      </Grid2>
      <Grid2 size={12}>
        <Divider />
        {!showAccountButtons && (
          <CustomButton
            fullWidth
            onClick={() => {
              window.location.reload();
              localStorage.removeItem(`role`);
              localStorage.removeItem(`token`);
              localStorage.removeItem(`isInvestor`);
              localStorage.removeItem(`isAdvisor`);
              localStorage.removeItem(`userInfo`);
              localStorage.removeItem(`version`);
              localStorage.removeItem("breadcumbs");
              localStorage.removeItem("columns");
              queryClient.clear();
            }}
            sx={{
              color: `${theme.palette.error.main}`,
              padding: "16px",
            }}
          >
            <FiLogOut size={16} />
            Logout
          </CustomButton>
        )}
      </Grid2>
    </Grid2>
  );
};

export default ProfileMenu;
