import GKCharts from "components/GKCharts";
import Highcharts from "highcharts";
import { useMemo, useState } from "react";
import { moneyCommaSeparator } from "utils";
import { formatYAxisLabel } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

interface Props {
  multiLineData: any;
  isLoading: boolean;
}

const MultiLineComponent = (props: Props) => {
  const { multiLineData, isLoading: isNavLoading } = props;

  const { theme } = ThemeContainer();

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      borderRadius: 5,
      backgroundColor: theme.palette.light.main,
      height: 660,
      spacing: [10, 10, 10, 10],
      marginRight: 50,
      style: {
        fontFamily: "Inter",
      },
    },
    tooltip: {
      formatter(this: any) {
        const formattedDate = Highcharts.dateFormat("%b %e, %Y", this.x);

        let content = `<b>${formattedDate}</b><br/>`;

        this.points.forEach((point: any) => {
          content += `<span style="color:${point.color}">\u25CF</span> ${
            point.series.name
          }: <b>${moneyCommaSeparator(point.y)}</b><br/>`;
        });

        return content;
      },
      pointFormatter(this: any) {
        return `<span style="color:${this?.series?.color}">${
          this?.series?.name
        }</span>: ${moneyCommaSeparator(this?.y)}<br/>`;
      },
      valueDecimals: 2,
      split: true,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
    },
    title: {
      text: "",
      enabled: false,
      align: "left",
      style: {
        color: theme.palette.light.main,
        fontWeight: "bold",
        fontSize: 18,
      },
    },
    yAxis: {
      opposite: false,
      gridLineColor: `${theme.palette.border.main}44`,
      color: theme.palette.error.main,
      labels: {
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
        formatter(this: any) {
          return formatYAxisLabel(this?.value);
        },
      },
    },
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      itemStyle: {
        color: theme.palette.dark.main,
        fontWeight: "bold",
      },
      symbolWidth: 20,
      symbolHeight: 10,
    },
    xAxis: {
      type: "date",
      dateTimeLabelFormats: {
        day: "%b %e, %Y",
        week: "%b %e, %Y",
        month: "%b %Y",
        year: "%Y",
      },
      labels: {
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
        formatter(this: any) {
          return Highcharts.dateFormat("%b %e, %Y", this.value);
        },
      },
    },
    series: [],
  });

  useMemo(() => {
    setChartOptions(() => ({
      ...chartOptions,
      series: multiLineData?.map((data: any) => {
        return {
          ...data,
          color: theme.palette.primary.main,
        };
      }),
    }));
  }, [multiLineData]);

  return (
    <GKCharts
      chartOptions={{
        ...chartOptions,
      }}
      loading={isNavLoading}
      rangeSelector
      navigator
    />
  );
};

export default MultiLineComponent;
