import { blueGrey } from "@mui/material/colors";
import { useAppContext } from "AppContext";
import {
  Box,
  FaFileSignature,
  FiShoppingBag,
  Grid2,
  HiOutlineOfficeBuilding,
  List,
  ListItem,
  ListItemText,
  LuDownload,
  LuFileCheck2,
  LuFileSpreadsheet,
  LuMail,
  LuMegaphone,
  LuSettings,
  LuSettings2,
  LuUsers,
  PiIdentificationCard,
  PiUserCircleLight,
  RiListRadio,
  TbInvoice,
  TbSitemap,
  TbTransactionRupee,
  Typography,
  styled,
} from "components";
import { UserInfoInterface } from "interfaces/User";
import DashboardLayout from "layouts/DashboardLayout";
import CompanyInformationPage from "Pages/CompanyInformation";
import Mandate from "Pages/Mandate";
import MarketingMaterial from "Pages/MarketingMaterial";
import MutualFundAutoImportSettings from "Pages/MutualFundAutoImport/MutualFundAutoImportSettings";
import PaymentGateway from "Pages/PaymentGateway";
import RightIssue from "Pages/RightIssue";
import CompanyMappingPreferences from "Pages/Settings/CompanyMappingPreferences";
import CustomAgreement from "Pages/Settings/CustomAgreement";
import EquityBasketSetting from "Pages/Settings/EquityBasketSetting";
import ExecutionSheet from "Pages/Settings/ExecutionSheet";
import GeneralPreferences from "Pages/Settings/GeneralPreferences";
import KYCRegistrationAgency from "Pages/Settings/KYCRegistrationAgency";
import RevenueManagerSettings from "Pages/Settings/RevenueManagerSettings";
import RiskProfiling from "Pages/Settings/RiskProfiling";
import SmtpSetup from "Pages/Settings/SmtpSetup";
import UserProfile from "Pages/Settings/UserProfile";
import { paletteLightTheme } from "utils/ColorTheme";
import { UserRoleTypes } from "utils/common";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import BrokerConfiguration from "./BrokerConfiguration";
import LedgerSettings from "./LedgerSettings";
import PortfolioMetrics from "./PortfolioMetrics";
import RoleWisePermission from "./RoleWisePermission";

const Sidebar = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const returnSettingTab = (userInfo: UserInfoInterface) => {
  const menuItems = [
    {
      text: "Account",
      children: [
        {
          text: "User Profile",
          component: "USER_PROFILE",
          icon: PiUserCircleLight,
        },
        {
          text: "Company Profile",
          component: "COMPANY_PROFILE",
          icon: HiOutlineOfficeBuilding,
        },
      ],
    },
    {
      text: "Onboarding",
      children: [
        {
          text: "Risk Profiling",
          component: "RISK_PROFILING",
          icon: RiListRadio,
        },
        {
          text: "Custom Agreement",
          component: "CUSTOM_AGREEMENT",
          icon: FaFileSignature,
        },
      ],
    },
    {
      text: "Preferences",
      children: [
        {
          text: "Portfolio Metrics",
          component: "PORTFOLIO_METRICS",
          icon: LuSettings2,
        },
        {
          text: "Invoice Preferences",
          component: "ANNEXURE_FOR_INVOICE",
          icon: TbInvoice,
        },
        {
          text: "Company Mapping",
          component: "COMPANY_MAPPING",
          icon: TbSitemap,
        },
        {
          text: "Right Issue",
          component: "RIGHT_ISSUE",
          icon: LuFileCheck2,
        },
        {
          text: "Basket",
          component: "BASKET",
          icon: FiShoppingBag,
        },
        {
          text: "Ledger",
          component: "LEDGER",
          icon: TbTransactionRupee,
        },
        {
          text: "General Preferences",
          component: "GENERAL_PREFERENCES",
          icon: LuSettings,
        },
      ],
    },
    {
      text: "Roles & Permissions",
      children: [
        {
          text: "Employee",
          component: "EMPLOYEE",
          icon: LuUsers,
        },
        {
          text: "Client",
          component: "CLIENT",
          icon: LuFileSpreadsheet,
        },
        {
          text: "Partner",
          component: "PARTNER",
          icon: LuFileSpreadsheet,
        },
      ],
    },
    {
      text: "Integration & Setup",
      children: [
        {
          text: "Execution Sheet",
          component: "EXECUTION_SHEET",
          icon: LuFileSpreadsheet,
        },
        {
          text: "Email Configuration",
          component: "EMAIL_CONFIGURATION",
          icon: LuMail,
        },
        {
          text: "Mutual Fund Auto Import",
          component: "MUTUAL_FUND_AUTOIMPORT",
          icon: LuDownload,
        },
        {
          text: "Marketing Material",
          component: "MARKETING_MATERIAL",
          icon: LuMegaphone,
        },
        // {
        //   text: "Payment Gateway",
        //   component: "PAYMENT_GATEWAY",
        //   icon: LuCreditCard,
        // },
        // {
        //   text: "Mandate",
        //   component: "MANDATE",
        //   icon: LuFileCog,
        // },
        {
          text: "KRA",
          component: "KYC_REGISTRATION_AGENCY",
          icon: PiIdentificationCard,
        },
      ],
    },
    userInfo?.brokerSupport?.iiflSupport && {
      text: "Broker Configuration",
      children: [
        {
          text: "IIFL",
          component: "BROKER_CONFIGURATION",
          icon: LuFileSpreadsheet,
        },
      ],
    },
  ];

  return menuItems;
};

interface SidebarPropsInterface {
  dispatch: any;
  settingTab: {
    type: string;
    name: string;
  };
  userInfo: UserInfoInterface;
}

const SidebarMenu = (props: SidebarPropsInterface) => {
  const { dispatch, settingTab, userInfo } = props;

  const { theme } = ThemeContainer();

  return (
    <Sidebar>
      <List sx={{ pr: 2 }}>
        {returnSettingTab(userInfo).map((item, index) => {
          return (
            <div key={index}>
              <Typography
                mt={1}
                fontSize={12}
                fontWeight={500}
                color={theme.palette.secondaryText.main}
                px={2}
                py={1}
              >
                {item?.text}
              </Typography>
              {item?.children &&
                item?.children?.map((item) => {
                  return (
                    <ListItem
                      key={item.text}
                      onClick={() =>
                        dispatch({
                          type: "SETTING_TAB",
                          data: { name: item.text, type: item.component },
                        })
                      }
                      sx={{
                        backgroundColor:
                          settingTab.type === item.component
                            ? `${paletteLightTheme.palette.primary.main}11`
                            : "",
                        color:
                          settingTab.type === item.component
                            ? `${paletteLightTheme.palette.primary.main}`
                            : "",
                        borderRadius: "8px",
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <item.icon
                        size={16}
                        color={
                          settingTab.type === item.component
                            ? `${paletteLightTheme.palette.primary.main}`
                            : blueGrey[500]
                        }
                      />
                      &nbsp;&nbsp;
                      <ListItemText
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color:
                            settingTab.type === item.component
                              ? `${paletteLightTheme.palette.primary.main}`
                              : theme.palette.secondaryText.main,
                          "& .MuiTypography-root": {
                            lineHeight: "16px !important",
                          },
                        }}
                        primary={item.text}
                      />
                    </ListItem>
                  );
                })}
            </div>
          );
        })}
      </List>
    </Sidebar>
  );
};

const AccountSetting = () => {
  useDocumentTitle("Settings");

  const {
    state: { sidebarOpen, settingTab, userData },
    dispatch,
  } = useAppContext();

  const renderComponent = () => {
    switch (settingTab.type) {
      case "USER_PROFILE":
        return <UserProfile />;
      case "COMPANY_PROFILE":
        return <CompanyInformationPage />;
      case "RISK_PROFILING":
        return <RiskProfiling />;
      case "CUSTOM_AGREEMENT":
        return <CustomAgreement />;
      case "PORTFOLIO_METRICS":
        return <PortfolioMetrics />;
      case "ANNEXURE_FOR_INVOICE":
        return <RevenueManagerSettings />;
      case "COMPANY_MAPPING":
        return <CompanyMappingPreferences />;
      case "RIGHT_ISSUE":
        return <RightIssue />;
      case "BASKET":
        return <EquityBasketSetting />;
      case "GENERAL_PREFERENCES":
        return <GeneralPreferences />;
      case "EXECUTION_SHEET":
        return <ExecutionSheet />;
      case "EMAIL_CONFIGURATION":
        return <SmtpSetup />;
      case "MARKETING_MATERIAL":
        return <MarketingMaterial />;
      case "EMPLOYEE":
        return <RoleWisePermission type="Employee" />;
      case "CLIENT":
        return <RoleWisePermission type="Client" />;
      case "PARTNER":
        return <RoleWisePermission type="Partner" />;
      case "PAYMENT_GATEWAY":
        return <PaymentGateway />;
      case "MUTUAL_FUND_AUTOIMPORT":
        return <MutualFundAutoImportSettings />;
      case "MANDATE":
        return <Mandate />;
      case "LEDGER":
        return <LedgerSettings />;
      case "BROKER_CONFIGURATION":
        return <BrokerConfiguration broker={"IIFL"} />;
      case "KYC_REGISTRATION_AGENCY":
        return <KYCRegistrationAgency />;
      default:
        return <Typography variant="h4">Page not found!</Typography>;
    }
  };

  return (
    <DashboardLayout title="Account Setting">
      <Grid2 container display={"flex"}>
        {userData?.userRole !== UserRoleTypes.client && (
          <Grid2
            width={sidebarOpen ? "21%" : "20%"}
            height={"calc(100vh - 100px)"}
            overflow={"auto"}
          >
            <SidebarMenu
              dispatch={dispatch}
              settingTab={settingTab}
              userInfo={userData}
            />
          </Grid2>
        )}
        <Grid2
          size={userData?.userRole === UserRoleTypes.client && 12}
          width={sidebarOpen ? "79%" : "80%"}
          p={"8px 16px"}
          height={"calc(100vh - 100px)"}
          overflow={"auto"}
        >
          <Typography fontSize={24} fontWeight={500} mb={3}>
            {settingTab.name}
          </Typography>
          {renderComponent()}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default AccountSetting;
