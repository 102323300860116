/* eslint-disable jsx-a11y/media-has-caption */
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import {
  Chip,
  CircularProgress,
  EditIcon,
  FiTrash2,
  GKButton,
  GKSelect,
  Grid2,
  IconButton,
  MdUnarchive,
  MenuItem,
  PiEyeDuotone,
  RiSendPlaneLine,
  StatusField,
  Switch,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import axiosInstance from "./axiosInstance";
import {
  handleReturnPositiveNegative,
  returnInstaTypeColor,
} from "./colorHelper";
import { handleCapitalize } from "./common";
import { MEETING_STATUS } from "./constants";
import { extraColumnsField, matchDate, refetchQuery } from "./helpers";
import { moneyCommaSeparator, moneyCommaSeparatorPoint } from "./MathFunction";
import { handleReturnTripleDots } from "./StringFunctions";

export const ClientAgreementColumns = () => {
  const { mutate: handleResendAgreement } = useMutation({
    mutationKey: ["RESEND_AGREEMENT"],
    mutationFn: (id: any) =>
      axiosInstance.post(
        `/resendAgreement/resendAgreement/?agreement_id=${id}`,
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Agreement Id", "documentId", 300, 0),
      valueGetter: (param) => {
        return param?.row?.agreementStatus?.documentId;
      },
    },
    {
      ...extraColumnsField("Signature Type", "signatureType", 150, 0),
      renderCell: (param) => {
        return (
          <Typography textTransform={"capitalize"}>
            {handleRemoveUnderscore(param?.row?.signatureType)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Sign Date", "signDate", 150, 0),
      renderCell: (param) => {
        return <Typography>{matchDate(param?.row?.createdAt)}</Typography>;
      },
    },
    {
      ...extraColumnsField("Status", "status", 150, 0),
      valueGetter: (param) => param?.row?.agreementStatus?.status,
      renderCell: (param) => {
        return (
          <StatusField statusString={param?.row?.agreementStatus?.status} />
        );
      },
    },
    {
      ...extraColumnsField("Action", "action", 250, 0),
      align: "right",
      headerAlign: "right",
      renderCell: (param) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <IconButton
              href={
                param?.row.agreementStatus?.status === "completed"
                  ? param?.row.agreementStatus?.signedDocument
                  : param.row?.document
              }
              target="_blank"
              download
              sx={{
                padding: 0,
                textDecoration: "underline",
                "&:hover": {
                  textDecoration: "underline",
                  backgroundColor: "transparent",
                },
              }}
            >
              <PiEyeDuotone />
            </IconButton>
            &nbsp;
            <IconButton
              sx={{
                padding: 0,
                textDecoration: "underline",
                "&:hover": {
                  textDecoration: "underline",
                  backgroundColor: "transparent",
                },
              }}
              disabled={
                param.row.agreementStatus.status === "approved" ||
                param?.row?.signatureType === "Manual"
              }
              onClick={() => handleResendAgreement(param.id)}
            >
              <RiSendPlaneLine />
            </IconButton>
          </Grid2>
        );
      },
    },
  ];

  return columns;
};

export const clientKYCColumns: GridColDef[] = [
  {
    flex: 1,
    minWidth: 150,
    field: "preview",
    ...extraColumnsField("Preview", "preview", 150, 1),
    disableColumnMenu: true,
    renderCell: (params) => {
      return params?.row?.type === "image" || params?.row?.type === "selfie" ? (
        <img
          onError={({ currentTarget }) => {
            currentTarget.src = "/Logos/errorImg.svg";
          }}
          src={params?.row?.document}
          alt="document"
          width={60}
          style={{
            height: "40px !important",
          }}
        />
      ) : (
        <video
          width={60}
          style={{
            height: "40px !important",
          }}
        >
          <source src={params?.row?.document} type="video/webm" />
        </video>
      );
    },
  },
  {
    ...extraColumnsField("Document Type", "type", 150, 1),
    renderCell: (props) => {
      return (
        <Typography textTransform={"capitalize"}>{props?.row?.type}</Typography>
      );
    },
  },
  {
    ...extraColumnsField("Status", "status", 150, 1),
    renderCell: (props) => {
      return <StatusField statusString={`${props?.row?.status}`} />;
    },
  },
];

export const ClientRiskProfilingColumns = (id: string) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Risk Profile", "riskStatus", 150, 1),
      valueGetter: (row) => row.row.riskStatus || "-",
    },
    {
      ...extraColumnsField("Points", "points", 150, 1),
      valueGetter: (row) => row.row.points || "-",
    },
    {
      ...extraColumnsField("Date", "createdAt", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
      valueGetter: (row) => row.row.createdAt || "-",
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (row) => {
        return (
          <GKButton
            variant="link"
            onClick={() => {
              navigate(`/app/risk-profile/${id}/questionnaire/${row.row.id}`);
            }}
          >
            View Questionnaire
          </GKButton>
        );
      },
    },
  ];

  return columns;
};

export const portfolioHoldingTransactionsColumns: (GridColDef & {
  visibleType?: string[];
})[] = [
  {
    ...extraColumnsField("Date", "date", 150, 1),
    valueGetter: (row) => row.row.date,
    valueFormatter: (value) => matchDate(value.value),
    visibleType: ["FD", "ETF", "EQ", "MF", "CE", "PEQ", "NCE"],
  },
  {
    ...extraColumnsField("Transaction Type", "type", 150, 1),
    valueFormatter: (params) => handleCapitalize(params?.value),
    visibleType: ["FD", "ETF", "EQ", "MF", "CE", "PEQ", "NCE"],
  },
  {
    ...extraColumnsField("Quantity", "quantity", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.quantity || "0",
    valueFormatter: (params) => params.value,
    visibleType: ["ETF", "EQ", "MF", "CE", "PEQ", "NCE"],
  },
  {
    ...extraColumnsField("Price", "price", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.price || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    visibleType: ["ETF", "EQ", "MF", "CE", "PEQ", "NCE"],
  },
  {
    ...extraColumnsField("Amount", "price", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.price || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    visibleType: ["FD"],
  },
  {
    ...extraColumnsField("TDS", "tds", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.tds || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    visibleType: ["FD"],
  },
  {
    ...extraColumnsField("Brokerage", "brokerage", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.brokerage,
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    visibleType: ["ETF", "EQ", "MF", "CE", "PEQ", "NCE"],
  },
  {
    ...extraColumnsField("Amount", "amount", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.amount,
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    visibleType: ["ETF", "EQ", "MF", "CE", "PEQ", "NCE"],
  },
  {
    ...extraColumnsField("FInal Amount", "amount", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.amount,
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    visibleType: ["FD"],
  },
  {
    ...extraColumnsField("Balance Quantity", "totalQuantity", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.totalQuantity,
    valueFormatter: (params) => params.value,
    visibleType: ["ETF", "EQ", "MF", "CE", "PEQ", "NCE"],
  },
];

export const assetsHoldingsColumns: GridColDef[] = [
  {
    ...extraColumnsField("Asset Name", "name", 150, 1),
    valueGetter: (params) => params?.row?.name || "-",
    renderCell: (params) => {
      const { name, instType } = params.row;
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Chip
            sx={{
              borderRadius: 1,
              p: "4px",
              background: `${returnInstaTypeColor(instType)}22`,
              height: "unset",
              color: returnInstaTypeColor(instType),
              "& .MuiChip-label": {
                pr: 0,
                pl: 0,
              },
            }}
            label={instType || "-"}
          />
          <Tooltip title={name || ""} arrow placement="right">
            <Typography>
              {handleReturnTripleDots({ str: name, length: 10 }) || "-"}
            </Typography>
          </Tooltip>
        </div>
      );
    },
  },
  {
    ...extraColumnsField("Quantity", "rawQuantity", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.rawQuantity || "0",
    valueFormatter: (params) => params.value,
  },
  {
    ...extraColumnsField("Avg. Buy Price", "price", 150, 1),
    type: "number",
    renderCell: (row) => {
      return (
        <Typography>
          {moneyCommaSeparator(
            row?.row?.investedAmount / row?.row?.quantity || 0,
          )}
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Invested Value", "investedAmount", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.investedAmount || "-",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("CMP", "cmp", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.cmp || "-",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Current Value", "currentAmount", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.currentAmount || "-",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.unrealisedGain,
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    renderCell: (value) => {
      const { unrealisedGain } = value?.row;
      return (
        <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
          {moneyCommaSeparator(unrealisedGain)}
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.absoluteReturn,
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    renderCell: (value) => {
      return (
        <Typography
          color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
        >
          {moneyCommaSeparator(value?.row?.absoluteReturn)}%
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.todaysGain,
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    renderCell: (value) => {
      const { todaysGain } = value?.row;
      return (
        <Typography color={handleReturnPositiveNegative(todaysGain)}>
          {moneyCommaSeparator(todaysGain)}
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.todaysGainPercentage,
    valueFormatter: (params) => moneyCommaSeparator(params.value),
    renderCell: (value) => {
      return (
        <Typography
          color={handleReturnPositiveNegative(value?.row?.todaysGainPercentage)}
        >
          {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Weightage(%)", "weightage", 150, 1),
    type: "number",
    valueGetter: (params) => params?.row?.weightage,
  },
  {
    ...extraColumnsField("Portfolios", "countPortfolios", 150, 1),
    editable: false,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.countPortfolios || "-",
    valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
  },
];

export const LeadMeetingColumns = (
  setIsDeleteModalOpen: any,
  setItemId: any,
) => {
  const handleChange = async (e: any, data: any) => {
    await axiosInstance
      .post("/lead-meeting-status/", {
        meet_id: data.id,
        status: e.target.value,
      })
      .then((response) => {
        refetchQuery("GET_ALL_LEAD_MEETINGS");
        toast.success(
          response?.data?.message || "Meeting status update successfully!",
        );
      })
      .catch((error) => {
        refetchQuery("GET_ALL_LEAD_MEETINGS");
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Agenda", "meetingTitle", 150, 1),
      valueGetter: (params) => params.row.meetingTitle || "-",
    },
    {
      ...extraColumnsField("Meeting Type", "meetingType", 150, 1),
    },
    {
      ...extraColumnsField("Meeting Person", "meetingPerson", 150, 1),
      renderCell: (value) => {
        return <Typography>{value?.row?.meetingPerson || "-"}</Typography>;
      },
    },
    {
      ...extraColumnsField("Meeting Date", "meetingDate", 150, 1),
      renderCell: (value) => {
        return (
          <Typography>{matchDate(value.row.meetingDate) || "N/A"}</Typography>
        );
      },
    },
    {
      ...extraColumnsField("Meeting Time", "startTime", 150, 1),
      valueFormatter: (value) => value.value || "N/A",
    },
    {
      ...extraColumnsField("Status", "status", 150, 1),
      align: "center",
      renderCell: (params) => {
        return (
          <GKSelect
            name="status"
            value={params.row.status}
            onChange={(e) => handleChange(e, params.row)}
          >
            {MEETING_STATUS.map((dataItem: any) => {
              return (
                <MenuItem value={dataItem?.value} key={dataItem?.value}>
                  {dataItem?.value}
                </MenuItem>
              );
            })}
          </GKSelect>
        );
      },
    },
    {
      ...extraColumnsField("Created Date", "createdAt", 150, 1),
      renderCell: (value) => {
        return (
          <Typography>{matchDate(value.row.createdAt) || "N/A"}</Typography>
        );
      },
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (props) => {
        return (
          <IconButton
            color="error"
            className="outline"
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteModalOpen(true);
              setItemId(props.row.id);
            }}
          >
            <FiTrash2 size={20} />
          </IconButton>
        );
      },
    },
  ];

  return columns;
};

export const modelPortfolioPerformanceColumns: GridColDef[] = [
  {
    ...extraColumnsField("Asset", "asset", 150, 1),
  },
  {
    ...extraColumnsField("Since Last Change", "sinceLastChange", 150, 1),
  },
  {
    ...extraColumnsField("1 M", "1Month", 150, 1),
  },
  {
    ...extraColumnsField("3 M", "3Month", 150, 1),
  },
  {
    ...extraColumnsField("6 M", "6Month", 150, 1),
  },
  {
    ...extraColumnsField("9 M", "9Month", 150, 1),
  },
  {
    ...extraColumnsField("12 M", "12Month", 150, 1),
  },
  {
    ...extraColumnsField("YTD", "ytd", 150, 1),
  },
  {
    ...extraColumnsField("3 YTD", "3ytd", 150, 1),
  },
  {
    ...extraColumnsField("Since Inception", "sinceInception", 150, 1),
  },
];

export const MapUnmapPortfoliosColumns = (
  selectedId: any,
  setSelectedId: any,
  mapLoading: any,
  mapMutation: any,
  unmapMutation: any,
  unmapLoading: any,
) => {
  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Tooltip title={params?.row?.name}>
            <Grid2 size={11}>
              <Typography
                fontWeight={700}
                maxWidth={"100%"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
              >
                {params?.row?.clientName}
              </Typography>
              <Typography fontSize={12}>
                {params?.row?.name} - {params?.row?.portfolioId}
              </Typography>
            </Grid2>
          </Tooltip>
        );
      },
    },
    {
      ...extraColumnsField("Status", "status", 150, 1),
      renderCell: (row) => <StatusField statusString={row?.row?.status} />,
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (row) =>
        selectedId === row?.row?.id && (mapLoading || unmapLoading) ? (
          <CircularProgress size={20} />
        ) : (
          <Switch
            checked={row?.row?.status?.toLowerCase() === "mapped"}
            onChange={() => {
              setSelectedId(row?.row?.id);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              row?.row?.status?.toLowerCase() === "unmapped"
                ? mapMutation([row?.row?.id])
                : unmapMutation([row?.row?.id]);
            }}
          />
        ),
    },
  ];

  return columns;
};

export const approveAutoContractColumns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 150, 1),
    valueFormatter: (value) => matchDate(value.value),
    editable: false,
  },
  {
    ...extraColumnsField("Broker Name", "brokerName", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("Contract Note Number", "contractNoteNum", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("Portfolio Code", "portfolioCode", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("Company Name", "companyName", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("BSE Code", "bseCode", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("NSE Code", "nseCode", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
    valueFormatter: (params) => handleCapitalize(params?.value),
    editable: false,
  },
  {
    ...extraColumnsField("Quantity", "quantity", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("Price", "price", 150, 1),
    valueGetter: (params) => params.row.price || "0",
    editable: false,
    valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
  },
  {
    ...extraColumnsField("brokerage", "nseBsbrokerageeCode", 150, 1),
    editable: false,
  },
  {
    ...extraColumnsField("STT", "stt", 150, 1),
    valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
    editable: false,
  },
  {
    ...extraColumnsField("GST", "gst", 150, 1),
    valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
    editable: false,
  },
  {
    ...extraColumnsField("Stamp Charges", "stampCharges", 150, 1),
    editable: false,
    valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
  },
  {
    ...extraColumnsField("Transaction Charges", "transactionCharges", 150, 1),
    editable: false,
    valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
  },
  {
    ...extraColumnsField("Sebi Turnover Fees", "sebiTurnoverFees", 150, 1),
    editable: false,
    valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
  },
  {
    ...extraColumnsField("Amount With Brokerag", "amountWithBrokerage", 150, 1),
    editable: false,
    valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
  },
  {
    ...extraColumnsField("Error", "error", 150, 1),
    editable: false,
  },
];

export const ArchiveManagerPageColumns = (
  handleViewFileClick: any,
  setClickedButtonId: any,
  loadingStates: any,
  clickedButtonId: any,
  unArchiveFile: any,
) => {
  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Uploaded Date", "uploadedOn", 150, 1),
      valueGetter: (item) =>
        dayjs(item?.row?.uploadedOn).format("DD/MM/YYYY hh:mm:ss A"),
    },
    {
      ...extraColumnsField("File Name", "fileName", 150, 1),
      renderCell: (item) => (
        <GKButton
          style={{ textDecoration: "underline" }}
          onClick={() => {
            handleViewFileClick(
              item?.row?.id,
              item?.row?.source,
              item?.row?.format,
            );
            setClickedButtonId(item?.row?.id);
          }}
        >
          {loadingStates?.isViewFileLoading &&
            clickedButtonId === item?.row?.id && <CircularProgress size={20} />}
          {item?.row?.source === "Manual Entry"
            ? "View manual entry details"
            : item?.row?.fileName}
        </GKButton>
      ),
    },
    {
      ...extraColumnsField("File Type", "format", 150, 1),
    },
    {
      ...extraColumnsField("Upload Type", "source", 150, 1),
    },
    {
      ...extraColumnsField("Records", "remarks", 150, 1),
    },
    {
      ...extraColumnsField("Status", "status", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 150, 1),
      headerAlign: "right",
      align: "right",
      type: "action",
      renderCell: (item) => (
        <Tooltip title="UnArchive row" placement="top" arrow>
          <IconButton onClick={() => unArchiveFile(item?.row?.id)}>
            <MdUnarchive size={20} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return columns;
};

export const clientAccessColumn = (
  enableDisableCLientLodaing: boolean,
  isRefetching: boolean,
  isFetching: boolean,
  switchLoading: any,
  selectedClientList: any,
  setSwitchLoading: any,
  enableDisableCLient: any,
) => {
  const clientListColumns: GridColDef[] = [
    // {
    //   field: "branch",
    //   headerName: "Branch",
    //   sortable: true,
    //   flex: 1,
    //   minWidth: 150,
    //   disableColumnMenu: true,
    //   editable: false,
    // },
    {
      ...extraColumnsField("Name", "name", 150, 1),
      editable: false,
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.name}</Typography>
            <Typography>{params?.row?.clientCode}</Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Family Name", "familyName", 150, 1),
      editable: false,
      renderCell: (param) => (
        <Typography>{param?.row?.familyName || "-"}</Typography>
      ),
    },
    {
      ...extraColumnsField("Email", "email", 150, 1),
      editable: false,
      renderCell: (value) => {
        return (
          <Typography textTransform={"lowercase"}>
            {value?.row?.email || "-"}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Phone", "phone", 150, 1),
      editable: false,
    },
    {
      ...extraColumnsField("PAN", "pan", 150, 1),
      editable: false,
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 150, 1),
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
      editable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      headerAlign: "right",
      editable: false,
      align: "right",
      renderCell: (params) => {
        return (enableDisableCLientLodaing || isRefetching || isFetching) &&
          params?.row?.id === switchLoading ? (
          <CircularProgress size={18} />
        ) : (
          <Switch
            checked={selectedClientList?.includes(params?.row?.id)}
            disabled={enableDisableCLientLodaing || isRefetching || isFetching}
            onChange={(event) => {
              setSwitchLoading(params?.row?.id);
              let arr = [];
              if (event.target.checked) {
                arr = [...selectedClientList, params?.row?.id];
              } else {
                arr = selectedClientList?.filter(
                  (id: any) => id !== params?.row?.id,
                );
              }
              enableDisableCLient(arr);
            }}
          />
        );
      },
    },
  ];

  return clientListColumns;
};

export const clientPageClientAccess = (
  enableDisableCLientLodaing: boolean,
  isRefetching: boolean,
  isFetching: any,
  switchLoading: any,
  selectedEmployeeList: any,
  setSwitchLoading: any,
  enableDisableCLient: any,
) => {
  const clientListColumns: GridColDef[] = [
    // {
    //   field: "branch",
    //   headerName: "Branch",
    //   sortable: true,
    //   flex: 1,
    //   minWidth: 150,
    //   disableColumnMenu: true,
    //   editable: false,
    // },
    {
      ...extraColumnsField("Name", "name", 150, 1),
      editable: false,
      renderCell: (param) => {
        return <Typography>{param?.row?.personalDetails?.name}</Typography>;
      },
    },
    {
      ...extraColumnsField("Email", "email", 200, 1),
      editable: false,
      renderCell: (param) => {
        return (
          <Typography textTransform={"lowercase"}>
            {param?.row?.personalDetails?.email}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Phone", "phone", 150, 1),
      editable: false,
    },
    {
      ...extraColumnsField("Role", "role", 150, 1),
      editable: false,
    },
    // {
    //   field: "salary",
    //   headerName: "Salary",
    //   sortable: true,
    //   disableColumnMenu: true,
    //   flex: 1,
    //   minWidth: 100,
    //   editable: false,
    // },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      headerAlign: "right",
      editable: false,
      align: "right",
      renderCell: (params) => {
        return (enableDisableCLientLodaing || isRefetching || isFetching) &&
          params?.row?.id === switchLoading ? (
          <CircularProgress size={18} />
        ) : (
          <Switch
            checked={selectedEmployeeList?.includes(params?.row?.id)}
            onChange={(event) => {
              setSwitchLoading(params?.row?.id);
              let arr = [];
              if (event.target.checked) {
                arr = [...selectedEmployeeList, params?.row?.id];
              } else {
                arr = selectedEmployeeList?.filter(
                  (id: any) => id !== params?.row?.id,
                );
              }
              enableDisableCLient(arr);
            }}
          />
        );
      },
    },
  ];

  return clientListColumns;
};

export const employeeColumn: GridColDef[] = [
  {
    ...extraColumnsField("Name", "name", 150, 1),
    valueGetter: (value) => value?.row?.name || "-",
  },
  {
    ...extraColumnsField("Code", "code", 150, 1),
    valueGetter: (value) => value?.row?.code || "-",
  },
  {
    ...extraColumnsField("Email", "email", 120, 1),
    renderCell: (value) => {
      return (
        <Typography textTransform={"lowercase"}>
          {value?.row?.email || "-"}
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Mobile", "phone", 150, 1),
    valueGetter: (value) => value?.row?.phone || "-",
  },
  // {
  //   headerName: "Reporting Branch",
  //   field: "reportingBranch",
  //   flex: 1,
  //   minWidth: 150,
  //   disableColumnMenu: true,
  //   valueGetter: (value) => value?.row?.reportingBranch || "-",
  // },
  // {
  //   headerName: "Branch",
  //   field: "headBranch",
  //   flex: 1,
  //   minWidth: 150,
  //   disableColumnMenu: true,
  //   valueGetter: (value) => value?.row?.headBranch || "-",
  // },
];

export const PortfolioAccessClientListColumn = () =>
  // selectedEmployeeList: any,
  // id: number | string,
  // refetch: any
  {
    const clientListColumns: GridColDef[] = [
      // {
      //   field: "branch",
      //   headerName: "Branch",
      //   sortable: true,
      //   flex: 1,
      //   minWidth: 150,
      //   disableColumnMenu: true,
      //   editable: false,
      //   valueGetter: (params) => params.row.branch || "-",
      // },
      {
        ...extraColumnsField("Name", "name", 150, 1),
        editable: false,
        valueGetter: (params) => params?.row?.personalDetails?.name || "-",
      },
      {
        ...extraColumnsField("Email", "email", 150, 1),
        editable: false,
        renderCell: (params) => (
          <Typography textTransform={"lowercase"}>
            {params?.row?.personalDetails?.email || "-"}
          </Typography>
        ),
      },
      {
        ...extraColumnsField("Phone", "phone", 150, 1),
        editable: false,
        valueGetter: (params) => params?.row?.phone || "-",
      },
      {
        ...extraColumnsField("Role", "role", 150, 1),
        editable: false,
        valueGetter: (params) => params?.row?.role || "-",
      },
      // {
      //   field: "salary",
      //   headerName: "Salary",
      //   sortable: true,
      //   disableColumnMenu: true,
      //   flex: 1,
      //   minWidth: 150,
      //   editable: false,
      //   valueGetter: (params) => params.row.salary,
      //   valueFormatter: (params) => moneyCommaSeparator(params.value) || "-",
      // },
      // {
      //   field: "action",
      //   headerName: "Actions",
      //   flex: 1,
      //   minWidth: 100,
      //   disableColumnMenu: true,
      //   headerAlign: "right",
      //   editable: false,
      //   align: "right",
      //   renderCell: (params) => {
      //     return (
      //       <Switch
      //         checked={selectedEmployeeList?.includes(params?.row?.id)}
      //         onChange={(event) => {
      //           let arr = [];
      //           if (event.target.checked) {
      //             arr = [...selectedEmployeeList, params?.row?.id];
      //           } else {
      //             arr = selectedEmployeeList?.filter(
      //               (id: number | string) => id !== params?.row?.id
      //             );
      //           }
      //           axiosInstance
      //             .patch(`/portfolios/access/${id}/`, { employees: arr })
      //             .then(() => {
      //               refetch();
      //             })
      //             .catch((error) => {
      //               toast.error(
      //                 error?.response?.data?.message ||
      //                   "Something went wrong, please try again."
      //               );
      //             });
      //         }}
      //       />
      //     );
      //   },
      // },
    ];
    return clientListColumns;
  };

export const ExecutionSheetColumns = (
  refetch: any,
  setConfirmDelete: any,
  setDeleteId: any,
) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Name", "name", 0, 1),
      editable: false,
    },
    {
      ...extraColumnsField("Created On", "createdOn", 0, 1),
      editable: false,
      valueFormatter: (data) => dayjs(data?.value).format("DD MMM YYYY"),
    },
    {
      ...extraColumnsField("Created By", "orderBy", 0, 1),
      editable: false,
    },
    {
      ...extraColumnsField("MapPortfolio", "mapPortfolio", 0, 1),
      editable: false,
      renderCell: (params) => {
        return (
          <GKButton
            onClick={() => {
              navigate(
                `/app/user-profile/${params?.row?.id}/portfolio-mapping`,
              );
            }}
            variant="lightBg"
          >
            Map Portfolio
          </GKButton>
        );
      },
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      editable: false,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <IconButton
            color="error"
            className="outline"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteId(params.row.id);
              setConfirmDelete(true);
            }}
          >
            <FiTrash2 size={20} />
          </IconButton>
        );
      },
    },
  ];

  return columns;
};

export const leadStatusColumns: GridColDef[] = [
  {
    ...extraColumnsField("Status", "type", 150, 1),
  },
  {
    ...extraColumnsField("CreatedAt", "createdAt", 150, 1),
    valueFormatter: (value) => dayjs(value.value).format("DD MMM YYYY"),
  },
];

export const RightIssueColumn = () => {
  const navigate = useNavigate();

  const column: GridColDef[] = [
    {
      ...extraColumnsField(
        "Last Date For Premium Payment",
        "closingDate",
        150,
        1,
      ),
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Company Name", "name", 150, 1),
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      ...extraColumnsField(
        "Partly Paid Up Company Name",
        "convertName",
        150,
        1,
      ),

      valueGetter: (row) => row?.row?.convertName || "-",
    },
    {
      ...extraColumnsField("Premium", "premium", 150, 1),
      valueGetter: (row) => row?.row?.premium || "-",
    },
    {
      ...extraColumnsField("Actions", "action", 150, 1),
      editable: false,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <>
            <GKButton
              variant="outlined"
              onClick={() => {
                navigate(
                  `/app/right-issue/${params.row.gridCode}/apply-right-issue`,
                  {
                    state: params.row,
                  },
                );
              }}
            >
              Apply For Part Payment
            </GKButton>
            &nbsp;
            <Tooltip title="View and edit already done part payments.">
              <IconButton
                style={{
                  borderRadius: 10,
                }}
                onClick={() => {
                  navigate(
                    `/app/right-issue/${params.row.gridCode}/update-right-issue`,
                    {
                      state: params.row,
                    },
                  );
                }}
              >
                <EditIcon size={20} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return column;
};
