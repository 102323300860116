/* eslint-disable react/no-this-in-sfc */
import GKCharts from "components/GKCharts";
import { formatYAxisLabel } from "utils/helpers";
import { numberToCurrency } from "utils/MathFunction";

const NetContributionChart = ({ data }: any) => {
  return (
    <GKCharts
      chartOptions={{
        chart: {
          type: "column",
          height: 170,
        },
        title: {
          text: " ",
        },
        xAxis: {
          categories: ["Pay In", "Pay Out"],
          lineWidth: 1,
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          lineWidth: 1,
          title: {
            text: " ",
          },
          labels: {
            formatter(this: any) {
              return formatYAxisLabel(this?.value);
            },
          },
        },
        tooltip: {
          pointPrecision: 2,
          formatter(this: any) {
            const content = `<span style="color:${
              this.point.color
            }">\u25CF</span> ${this.point.series.name}: <b>${numberToCurrency(
              this.point.y,
            )}</b><br/>`;

            return content;
          },
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false,
              },
            },
            animation: false,
          },
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "Net Contribution",
            color: "#FCAA67",
            data: [
              data?.payIn > data?.payOut ? 0 : data?.netContribution,
              data?.payIn < data?.payOut ? 0 : data?.netContribution,
            ],
          },
          {
            name: "Pay In",
            data: [data?.payIn, 0],
            color: "#709D88",
          },
          {
            name: "Pay Out",
            data: [0, data?.payOut],
            color: "#39627A",
          },
        ],
        legend: {
          enabled: false,
        },
      }}
    />
  );
};

export default NetContributionChart;
