import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { DashboardType } from "utils/common";
import { currentVersion } from "utils/constants";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const useOTPRegistration = () => {
  useDocumentTitle("OTP");
  const { dispatch } = useAppContext();
  const { theme } = ThemeContainer();
  const {
    state: { phone, isForgetPassword, loginWithOTP, countryCode },
  } = useLocation();

  const [isDoneTyping, setIsDoneTyping] = useState<boolean>(false);

  const [count, setCount] = useState<number>(900);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [otpCode, setOtpCode] = useState("");

  const [resendCount, setResendCount] = useState<number>(0);

  const navigate = useNavigate();

  const minutes = Math.floor(count / 60);

  const seconds = count % 60;

  const verifyOTP = async () => {
    const getLink =
      isForgetPassword || loginWithOTP ? "login" : "register_advisor";

    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/otp/${countryCode}-${phone}/${getLink}/`,
        {
          otp: otpCode,
        },
      )
      .then((response) => {
        setIsLoading(false);
        dispatch({
          type: "CURRENT_USER",
          data: {
            ...response.data,
            userType: response.data?.isEmployee
              ? DashboardType.ADVISOR
              : DashboardType.INVESTOR,
          },
        });

        axiosInstance.interceptors.request.use((config) => {
          config.headers.Authorization = `Token ${response?.data?.token}`;
          config.headers[`x-gridkey-user-role`] = response?.data?.isEmployee
            ? `advisor`
            : `client`;
          return config;
        });

        if (response?.data?.isNew) {
          navigate("/login/register-details", {
            state: { phone: phone, response: response.data },
          });
        } else if (isForgetPassword) {
          navigate("/login/password", {
            state: { phone: phone, response: response.data },
          });
        } else if (response?.data?.isFirst) {
          localStorage.setItem(
            "breadcumbs",
            JSON.stringify([{ name: "Dashboard", path: "/app/dashboard" }]),
          );
          localStorage?.setItem("token", JSON.stringify(response?.data?.token));

          localStorage?.setItem(
            "role",
            response?.data?.isEmployee ? "advisor" : "client",
          );
          localStorage?.setItem("product-version", currentVersion);
          navigate("/app/user-profile/company-information", { replace: true });
          window.location.reload();
        } else if (!response?.data?.profileDetails?.profileComplete) {
          localStorage?.setItem("token", JSON.stringify(response?.data?.token));
          localStorage?.setItem("role", response?.data?.userRole);
          localStorage?.setItem("product-version", currentVersion);
          navigate("/app/company-onboarding", { replace: true });
        } else if (!response?.data?.isEmployee) {
          localStorage.setItem(
            "breadcumbs",
            JSON.stringify([{ name: "Dashboard", path: "/app/dashboard" }]),
          );
          localStorage?.setItem("token", JSON.stringify(response?.data?.token));

          localStorage?.setItem(
            "role",
            response?.data?.isEmployee ? "advisor" : "client",
          );
          localStorage?.setItem("product-version", currentVersion);
          navigate(`/app/holdings`);
        } else {
          localStorage.setItem(
            "breadcumbs",
            JSON.stringify([{ name: "Dashboard", path: "/app/dashboard" }]),
          );
          localStorage?.setItem("token", JSON.stringify(response?.data?.token));
          localStorage?.setItem(
            "role",
            response?.data?.isEmployee ? "advisor" : "client",
          );
          localStorage?.setItem("product-version", currentVersion);
          navigate("/app/holdings");
          window.location.reload();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  const { mutate: resendOTP } = useMutation({
    mutationKey: ["SEND_OTP"],
    mutationFn: () => axios.get(`/otp/${countryCode}-${phone}/send/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  useEffect(() => {
    if (isDoneTyping) verifyOTP();
  }, [isDoneTyping]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count > 0) {
        setCount((prev) => prev - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [count]);

  return {
    theme,
    phone,
    countryCode,
    setIsDoneTyping,
    isLoading,
    resendOTP,
    seconds,
    minutes,
    otpCode,
    setOtpCode,
    resendCount,
    setResendCount,
    navigate,
    setCount,
  };
};

export default useOTPRegistration;
