import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  FiTrash2,
  FormControl,
  GKButton,
  GKSearchSelect,
  GKSelect,
  GKTextField,
  Grid2,
  IconButton,
  MenuItem,
  Show,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "components";
import GKCheckbox from "components/GKCheckbox";
import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { blockInvalidChar } from "utils/common";
import { TRANSACTION_TYPES } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import ExtraChargesModal from "./ExtraChangesModal";

const padding = { padding: 16 };
const bodyCellStyle = (minWidth: string) => {
  return { border: "none", minWidth: minWidth };
};

interface Props {
  getTransactionValue: (index: number) => void;
}

const EquityEtfBody = (props: Props) => {
  const { getTransactionValue } = props;

  const { id } = useParams();

  const { state } = useLocation();

  const form = useFormContext();

  const { theme } = ThemeContainer();

  const getAssetType = state?.assetType;

  const [chargesIndex, setChargesIndex] = useState(null);
  const [chargesModal, setChargesModal] = useState(false);

  const {
    state: { companies },
  } = useAppContext();

  const { fields, remove, append } = useFieldArray({
    name: "transactions",
    control: form.control,
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={12}
        height={`calc(100vh - 370px)`}
        overflow={`hidden auto`}
        pr={"2px"}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={padding}>Company</TableCell>

                <TableCell style={padding}>Trade Type </TableCell>
                <TableCell style={padding}>Quantity </TableCell>
                <TableCell style={padding}>Price </TableCell>
                <TableCell style={padding}>Brokerage</TableCell>
                <TableCell style={padding}>Total Charges </TableCell>
                <TableCell style={padding}>Trade Amount </TableCell>
                <TableCell style={padding} align="right">
                  Actions{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields?.map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell style={bodyCellStyle("280px")}>
                      <Controller
                        control={form.control}
                        name={`transactions.${index}.gridCode`}
                        rules={{
                          required: {
                            value: true,
                            message: "Please select company",
                          },
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <GKSearchSelect
                              {...field}
                              requiredField
                              disableClearable
                              disabled={state?.disableField}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4,
                                  }}
                                >
                                  <Typography
                                    color={theme.palette.light.main}
                                    fontSize={8}
                                    style={{
                                      background:
                                        option?.instType === "EQ"
                                          ? theme.palette.secondary.main
                                          : theme.palette.warning.main,
                                      padding: "2px 4px",
                                      borderRadius: 4,
                                    }}
                                  >
                                    {option?.instType}
                                  </Typography>
                                  <Typography whiteSpace={"nowrap"}>
                                    {option?.name}
                                  </Typography>
                                </li>
                              )}
                              options={
                                companies
                                  .filter((dataItem) =>
                                    state?.transactionType === "ETF"
                                      ? dataItem.instType === "ETF"
                                      : state.transactionType === "OTHER-ASSETS"
                                        ? dataItem.instType === getAssetType
                                        : dataItem,
                                  )
                                  ?.filter((data) => data?.instType !== "MF") ||
                                []
                              }
                              getOptionLabel={(option) =>
                                option?.name || option
                              }
                              onChange={(ev, val) => field.onChange(val?.value)}
                              value={
                                companies.find(
                                  (data: any) => data?.value === field.value,
                                )?.name
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={handleReturnAutocompletePlaceholder(
                                    {
                                      placeholder: "Select Company",
                                      value: field.value,
                                    },
                                  )}
                                  error={!!error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </TableCell>

                    <TableCell style={bodyCellStyle("120px")}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          control={form.control}
                          name={`transactions.${index}.transactionType`}
                          rules={{
                            required: {
                              value: true,
                              message: "Please select transaction type",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <GKSelect
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                              error={!!error}
                              helperText={error?.message}
                            >
                              {TRANSACTION_TYPES?.map((dataItem) => {
                                return (
                                  <MenuItem
                                    key={dataItem?.value}
                                    value={dataItem?.value}
                                  >
                                    {dataItem?.name}
                                  </MenuItem>
                                );
                              })}
                            </GKSelect>
                          )}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell style={bodyCellStyle("100px")}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          control={form.control}
                          name={`transactions.${index}.quantity`}
                          rules={{
                            required: {
                              value: true,
                              message: "Please enter quantity",
                            },
                            min: {
                              value: 1,
                              message: "Quantity must be greater than zero",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <GKTextField
                              {...field}
                              requiredField
                              placeholder="Quantity"
                              onKeyDown={blockInvalidChar}
                              type="number"
                              onChange={(
                                event: React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >,
                              ) => {
                                if (event.target instanceof HTMLInputElement) {
                                  const { valueAsNumber } = event.target;
                                  field.onChange(valueAsNumber);
                                }
                              }}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </FormControl>{" "}
                    </TableCell>
                    <TableCell style={bodyCellStyle("100px")}>
                      <Controller
                        control={form.control}
                        name={`transactions.${index}.price`}
                        rules={{
                          required: {
                            value: true,
                            message: "Please enter price",
                          },
                          // min: {
                          //   value: 1,
                          //   message: "Price must be greater than zero",
                          // },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl variant="standard" fullWidth>
                            <GKTextField
                              {...field}
                              requiredField
                              placeholder="Price"
                              onKeyDown={blockInvalidChar}
                              onChange={(
                                event: React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >,
                              ) => {
                                if (event.target instanceof HTMLInputElement) {
                                  const { valueAsNumber } = event.target;
                                  field.onChange(valueAsNumber);
                                }
                              }}
                              type="number"
                              error={!!error}
                              helperText={error?.message}
                            />
                          </FormControl>
                        )}
                      />
                    </TableCell>
                    <TableCell style={bodyCellStyle("120px")}>
                      <Controller
                        control={form.control}
                        name={`transactions.${index}.brokerage`}
                        render={({ field }) => (
                          <FormControl variant="standard" fullWidth>
                            <GKTextField
                              {...field}
                              placeholder={"Brokerage"}
                              type="number"
                              onKeyDown={blockInvalidChar}
                              onChange={(
                                event: React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >,
                              ) => {
                                if (event.target instanceof HTMLInputElement) {
                                  const { valueAsNumber } = event.target;
                                  field.onChange(valueAsNumber);
                                }
                              }}
                            />
                          </FormControl>
                        )}
                      />
                    </TableCell>
                    <TableCell style={bodyCellStyle("120px")}>
                      <Controller
                        control={form.control}
                        name={`transactions.${index}.totalTransactionCharges`}
                        render={({ field }) => (
                          <FormControl variant="standard" fullWidth>
                            <GKTextField
                              {...field}
                              type="number"
                              placeholder="Total Transaction Charges"
                              onKeyDown={blockInvalidChar}
                              disabled
                            />
                          </FormControl>
                        )}
                      />
                      <IconButton
                        style={{
                          marginTop: 5,
                        }}
                        color="primary"
                        onClick={() => {
                          setChargesIndex(index);
                          setChargesModal(true);
                        }}
                      >
                        <CgMathPlus />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "100px",
                        border: "none",
                        paddingLeft: 30,
                      }}
                    >
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          control={form.control}
                          name={`transactions.${index}.billAmount`}
                          render={({ field }) => (
                            <GKTextField
                              {...field}
                              type="number"
                              placeholder="Transaction Amount"
                              disabled
                              value={getTransactionValue(index)}
                            />
                          )}
                        />
                      </FormControl>
                    </TableCell>

                    <Tooltip
                      title={
                        form.getValues(
                          `transactions.${index}.transactionType`,
                        ) === "BUY_BACK"
                          ? "Check to tag cash transaction for this entry."
                          : ""
                      }
                      arrow
                      placement="left"
                    >
                      <TableCell
                        align="right"
                        style={{
                          border: "none",
                        }}
                      >
                        {form.getValues(
                          `transactions.${index}.transactionType`,
                        ) === "BUY_BACK" && (
                          <Controller
                            name={`transactions.${index}.cashEntry`}
                            control={form.control}
                            render={({ field }) => {
                              return <GKCheckbox {...field} />;
                            }}
                          />
                        )}
                        <IconButton
                          onClick={() => remove(index)}
                          color="error"
                          disabled={fields?.length === 1}
                        >
                          <FiTrash2 />
                        </IconButton>
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                );
              })}
              {!id && (
                <TableRow>
                  <TableCell colSpan={8} sx={{ border: "none" }}>
                    <GKButton
                      variant="outlined"
                      size="small"
                      color="dark"
                      disabled={state?.disableField}
                      onClick={() =>
                        append({
                          brokerage: 0,
                          gridCode: "",
                          transactionType: "",
                          amountWithBrokerage: 0,
                          billAmount: 0,
                          price: 0,
                          quantity: 0,
                          cashEntry: false,
                          totalTransactionCharges: 0,
                          totalCharges: {
                            gst: 0,
                            otherCharges: 0,
                            stampCharges: 0,
                            stt: 0,
                            transactionCharges: 0,
                            sebiTurnoverFees: 0,
                          },
                        })
                      }
                    >
                      Add New Trade
                    </GKButton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>

      <Show.When isTrue={chargesModal}>
        <ExtraChargesModal
          open={chargesModal}
          setOpen={setChargesModal}
          index={chargesIndex}
        />
      </Show.When>
    </Grid2>
  );
};

export default EquityEtfBody;
