import { useAppContext } from "AppContext";
import { Divider, Grid2, Tab, Tabs, Typography } from "components";
import { HoldingDataInterface } from "interfaces/Portfolio";
import { PortfolioSummary } from "Pages";
import React from "react";
import PortfolioHoldingDetails from "./PortfolioHoldingsDetails/PortfolioHoldingDetails";

interface Props {
  holdingSummaryDetails: HoldingDataInterface;
}

const PortfolioHoldingViewTab: React.FC<Props> = ({
  holdingSummaryDetails,
}) => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const tabLabels = [
    "Overview",
    "All Holdings",
    "Equity",
    "Mutual Funds",
    "ETF",
    "Cash & Cash Equivalents",
    "Private Equity",
    "Fixed Deposit",
  ];

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    dispatch({
      type: "TAB",
      data: { ...tab, portfolioExtendedViewTabs: newValue },
    });
  };

  const renderTabContent = () => {
    if (tab?.portfolioTabs !== 0) return null;

    if (tab?.portfolioExtendedViewTabs === 0) {
      return <PortfolioSummary />;
    }

    if (tab?.portfolioExtendedViewTabs > 0) {
      return (
        <PortfolioHoldingDetails
          portfolioExtendedViewTabs={tab?.portfolioExtendedViewTabs}
        />
      );
    }

    return null;
  };

  return (
    <Grid2 container size={12}>
      <Grid2 size={10}>
        <Tabs
          value={tab?.portfolioExtendedViewTabs}
          onChange={handleTabChange}
          variant="scrollable"
        >
          {tabLabels.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>
      </Grid2>

      {tab?.portfolioExtendedViewTabs === 0 && (
        <Grid2
          size={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography fontWeight={500} fontSize={12}>
            XIRR:&nbsp;
          </Typography>
          <Typography fontWeight={600} fontSize={18}>
            {holdingSummaryDetails?.xirr
              ? `${holdingSummaryDetails?.xirr.toFixed(2)}%`
              : "-"}
          </Typography>
        </Grid2>
      )}

      <Grid2 size={12} mb={2}>
        <Divider />
      </Grid2>

      <Grid2 size={12}>{renderTabContent()}</Grid2>
    </Grid2>
  );
};

export default PortfolioHoldingViewTab;
