import {
  Alert,
  Button,
  Card,
  GKButton,
  Grid2,
  SimpleLoader,
  Typography,
} from "components";
import GKCharts from "components/GKCharts";
import { useFetchQuery } from "hooks/useQueries";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PORTFOLIO_COMPARISION } from "utils/apiRoutes";
import { formatYAxisLabel } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import BenchmarkTable from "./BenchmarkTable";
import SelectBenchmarkModal from "./SelectBenchmarkModal";

interface ChartData {
  data: unknown;
  name: string;
  marker: { enabled: boolean };
  color?: string;
  borderColor?: string;
}

const Benchmark = ({ benchmarkId }: { benchmarkId?: string }) => {
  useDocumentTitle("Portfolio Benchmark");

  const { id } = useParams();
  const { theme } = ThemeContainer();

  const [showBenchmarkModal, setShowBenchmarkModal] = useState(false);
  const [chartData, setChartData] = useState<ChartData[]>();
  const [dateRange, setDateRange] = useState("monthToDate");

  const { isFetching, data: basketChartTableData } = useFetchQuery({
    key: ["GET_ALL_BENCHMARK_CHART", benchmarkId],
    route: PORTFOLIO_COMPARISION(id, benchmarkId),
    success: (data: any) =>
      setChartData(
        Object.entries(data?.data)?.map(([key, val]) => {
          return {
            data: val,
            name: key,
            marker: { enabled: false },
          };
        }),
      ),
  });

  if (isFetching) return <SimpleLoader />;

  const transformedData = chartData
    ?.filter((dataItem) => dataItem.name === dateRange)
    .map((data: any) => {
      return Object.keys(data?.data).map((key, index) => {
        return {
          data: [data.data[key].map((item: any) => [item[0], item[1]])].flat(),
          name: key,
          marker: data.marker,
          color: index === 0 ? "#01657F" : "#63a387",
          borderColor: index === 0 ? "#01657F" : "#63a387",
        };
      });
    })
    .flat();

  const options = {
    chart: {
      backgroundColor: theme.palette.light.main,
      borderRadius: 16,
      height: 500,
      style: {
        fontFamily: "Inter",
      },
    },
    title: {
      text: " ",
    },
    yAxis: {
      opposite: false,
      gridLineColor: `${theme.palette.border.main}44`,
      color: theme.palette.error.main,
      labels: {
        formatter(this: any) {
          return formatYAxisLabel(this?.value);
        },
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        day: "%e of %b, %Y",
      },
    },
    series: transformedData,
    tooltip: {
      pointFormatter(this: any) {
        return `<span style="color:${this.color}">●</span> ${
          this.series.name
        }: <b>${this.y.toFixed(2)}</b><br/>`;
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
    },
  };

  const btn = [
    { name: "MTD", value: "monthToDate" },
    { name: "QTD", value: "quarterToDate" },
    { name: "6M", value: "6M" },
    { name: "YTD", value: "YTD" },
    { name: "3Y", value: "3Y" },
    { name: "5Y", value: "5Y" },
    { name: "ALL", value: "sinceInception" },
  ];

  return (
    <>
      <Card>
        <Grid2 container spacing={1}>
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              color={theme.palette.primary.main}
              fontSize={20}
              fontWeight={700}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              Benchmark Comparison
            </Typography>
            <GKButton
              onClick={() => {
                setShowBenchmarkModal(true);
              }}
              variant="contained"
              color="primary"
            >
              Select Benchmark
            </GKButton>
          </Grid2>
          <Grid2 size={12} gap={1}>
            {btn.map((dataItem) => {
              return (
                <Button
                  key={dataItem.value}
                  disabled={isEmpty(
                    chartData?.find((data) => data?.name === dataItem.value)
                      ?.data,
                  )}
                  size="extraSmall"
                  variant={
                    dateRange === dataItem.value ? "contained" : "outlined"
                  }
                  onClick={() => setDateRange(dataItem.value)}
                  sx={{ mr: 1 }}
                >
                  {dataItem.name}
                </Button>
              );
            })}
          </Grid2>
          <Grid2 size={12}>
            {transformedData && transformedData.length > 0 ? (
              <GKCharts chartOptions={options} />
            ) : (
              <Alert severity="warning" sx={{ mt: 2 }}>
                No benchmark found for selected portfolio. Please select a
                benchmark.
              </Alert>
            )}
          </Grid2>
          <Grid2 size={12}>
            {basketChartTableData?.data?.performance && (
              <BenchmarkTable
                data={basketChartTableData?.data?.performance || {}}
              />
            )}
          </Grid2>
        </Grid2>
      </Card>
      {showBenchmarkModal && (
        <SelectBenchmarkModal
          open={showBenchmarkModal}
          setOpen={setShowBenchmarkModal}
        />
      )}
    </>
  );
};

export default Benchmark;
