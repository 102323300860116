import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  GKButton,
  GKModal,
  Grid2,
  Show,
  Tooltip,
  Typography,
} from "components";
import UploadManagerStepperModal from "Pages/UploadManager/UploadManagerStepperModal";
import { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import BrokerCompanyMapping from "./BrokerCompanyMapping";
import BrokerPortfolioMapping from "./BrokerPortfolioMapping";
import ImportBrokerStepOne from "./ImportBrokerStepOne";
import ReviewMapping from "./ReviewMapping";

interface Props {
  refetch: () => void;
  format?: string;
  type?: string;
  subType?: string;
}

interface FormValues {
  fileObject: Record<string, any>;
  step1: {
    fromDate: string;
    toDate: string;
    portfolioId: string;
    type: string;
    subType: string;
  };
  step2: {
    portfolio: any[];
  };
  step3: {
    company: any[];
  };
  step4: {
    reviewResponse: Record<string, any>;
    showDuplicates: boolean;
    duplicateIds: any[];
  };
}

const ImportBrokerModal = ({
  open,
  setOpen,
  refetch,
  type,
  subType,
}: Props & GKModalProps) => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);

  let abortController: AbortController | null = null;

  const form = useForm<FormValues>({
    defaultValues: {
      fileObject: {},
      step1: {
        fromDate: "",
        toDate: "",
        portfolioId: id || "",
        type: type || "ADITYA_BIRLA_MONEY",
        subType: subType || "EQUITY",
      },
      step2: {
        portfolio: [],
      },
      step3: {
        company: [],
      },
      step4: {
        reviewResponse: {},
        showDuplicates: false,
        duplicateIds: [],
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "step2.portfolio",
  });

  const {
    fields: companyField,
    append: companyAppend,
    remove: companyRemove,
  } = useFieldArray({
    control: form.control,
    name: "step3.company",
  });

  form.watch();

  const getActiveStep = activeStep;

  const handleModalTitle = (step: number) => {
    switch (step) {
      case 0:
        return { form: "", title: id ? "Import From Broker" : "Import" };
      case 1:
        return { form: "portfolio-mapping-form", title: "Portfolio Mapping" };
      case 2:
        return { form: "company-mapping-form", title: "Company Mapping" };
      case 3:
        return { form: "", title: "Review imported data " };
      case 4:
        return { form: "", title: "Transaction Completed" };

      default:
        return { form: "", title: id ? "Import From Broker" : "Import" };
    }
  };

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    setOpen(false);
  };

  const { mutate: handleReviewMapping, isLoading: handleReviewMappingLoading } =
    useMutation({
      mutationKey: ["BROKER_COMPANY_MAPPING_COMMIT_TRUE"],
      mutationFn: (data: any) =>
        axiosInstance.post(
          `/transaction_upload/review/?commit=true&id=${
            form.getValues("fileObject")?.id
          }`,
          { duplicates_ids: data },
        ),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        refetch();
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: reviewMapping, isLoading: reviewMappingLoading } =
    useMutation({
      mutationKey: ["BROKER_COMPANY_MAPPING"],
      mutationFn: (id: number) =>
        axiosInstance.post(`/transaction_upload/review/?id=${id}`),
      onSuccess: (response) => {
        form.setValue("step4.reviewResponse", response.data);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: handleCompanyMapping } = useMutation({
    mutationKey: ["BROKER_COMPANY_MAPPING_POST"],
    mutationFn: (data) =>
      axiosInstance.post("/advisor-company-mappings/", data),
    onSuccess: (response: any) => {
      toast.success(response.data?.message || "Action completed successfully.");
      reviewMapping(form.getValues("fileObject")?.id);
      handleNextStep();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: companyMapping, isLoading: companyMappingLoading } =
    useMutation({
      mutationKey: ["BROKER_COMPANY_MAPPING"],
      mutationFn: (id: number) =>
        axiosInstance.get(`/transaction_upload/company_mappings/?id=${id}`),
      onSuccess: (response) => {
        const companies = response?.data?.data?.filter(
          (data: any) => data?.value === null,
        );

        if (companies?.length !== 0) {
          companyRemove();
          companies.forEach((company: any) => companyAppend(company));
        } else {
          handleNextStep();
          reviewMapping(form.getValues("fileObject")?.id);
        }
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: handlePortfolioMapping } = useMutation({
    mutationKey: ["BROKER_PORTFOLIO_MAPPING_POST"],
    mutationFn: (data) =>
      axiosInstance.post(
        `/transaction_upload/mappings/?id=${form.getValues("fileObject")?.id}`,
        data,
      ),
    onSuccess: (response: any) => {
      toast.success(response.data?.message || "Action completed successfully.");
      if (form.getValues("step1.subType") === "LEDGER") {
        setActiveStep(3);
        reviewMapping(form.getValues("fileObject")?.id);
      } else {
        companyMapping(form.getValues("fileObject")?.id);
        handleNextStep();
      }
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: portfolioMapping, isLoading: portfolioMappingLoading } =
    useMutation({
      mutationKey: ["BROKER_PORTFOLIO_MAPPING"],
      mutationFn: (id: number) =>
        axiosInstance.get(`/transaction_upload/mappings/?id=${id}`),
      onSuccess: (response) => {
        const portfolios = response?.data?.data?.filter(
          (data: any) => data?.portfolio === null,
        );

        if (portfolios?.length !== 0) {
          remove();
          portfolios.forEach((item: any) => append(item));
        } else {
          if (form.getValues("step1.subType") === "LEDGER") {
            setActiveStep(3);
            reviewMapping(form.getValues("fileObject")?.id);
          } else {
            handleNextStep();
            companyMapping(form.getValues("fileObject")?.id);
          }
        }
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: brokerData, isLoading: brokerDataLoading } = useMutation({
    mutationKey: ["BROKER_API_DATA"],
    mutationFn: (data) => {
      if (abortController) {
        abortController.abort();
      }

      abortController = new AbortController();

      return axiosInstance.post("/broker-api-data/", data, {
        signal: abortController.signal,
      });
    },
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      handleNextStep();
      form.setValue("fileObject", response?.data?.data);
      portfolioMapping(response?.data?.data?.id);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const loading =
    brokerDataLoading ||
    portfolioMappingLoading ||
    companyMappingLoading ||
    reviewMappingLoading;

  return (
    <GKModal
      modalTitle={handleModalTitle(getActiveStep)?.title}
      open={open}
      setOpen={() => {
        if (abortController) {
          abortController.abort();
        }
        setOpen(false);
      }}
      width={"70%"}
      footer={
        <Grid2
          container
          justifyContent={getActiveStep !== 0 ? "space-between" : "flex-end"}
          gap={1}
        >
          {activeStep !== 0 && (
            <GKButton
              variant="outlined"
              color="dark"
              size={"medium"}
              onClick={handlePreviousStep}
            >
              Cancel
            </GKButton>
          )}

          <Show.When isTrue={!loading}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 8,
              }}
            >
              {[0, 1, 2].includes(activeStep) && (
                <LoadingButton
                  variant="contained"
                  size={"medium"}
                  type="submit"
                  form="broker-form"
                  loading={brokerDataLoading}
                >
                  Next
                </LoadingButton>
              )}

              {getActiveStep === 3 &&
                form.getValues("step4.showDuplicates") && (
                  <Tooltip
                    title={
                      form.getValues("step4.duplicateIds")?.length === 0 ? (
                        <Typography fontWeight={600}>
                          Please select atleast one id to proceed forward or go
                          back to view all data.
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    placement="left"
                    arrow
                  >
                    <span>
                      <GKButton
                        onClick={() => {
                          form.setValue(
                            "step4.showDuplicates",
                            !form.getValues("step4.showDuplicates"),
                          );
                        }}
                        variant="outlined"
                        color="error"
                        size={"medium"}
                        disabled={
                          form.getValues("step4.duplicateIds")?.length === 0
                        }
                      >
                        Keep Duplicates & Finish
                      </GKButton>
                    </span>
                  </Tooltip>
                )}

              {getActiveStep === 3 &&
                !form.getValues("step4.showDuplicates") &&
                form.getValues("step4.duplicateIds").length === 0 &&
                form
                  .getValues("step4.reviewResponse")
                  ?.transactions?.filter(
                    (dataItem: any) => dataItem.isDuplicate,
                  )?.length !== 0 && (
                  <LoadingButton
                    variant="contained"
                    size={"medium"}
                    loading={handleReviewMappingLoading}
                    onClick={form.handleSubmit((values) => {
                      const reviewData =
                        values?.step4?.reviewResponse?.transactions
                          .filter((data: any) => !data.isDuplicate)
                          .map((dataItem: any) => dataItem.id);

                      handleReviewMapping(reviewData);
                    })}
                  >
                    Remove Duplicates & Finish
                  </LoadingButton>
                )}
              {getActiveStep === 3 &&
                !form.getValues("step4.showDuplicates") &&
                form
                  .getValues("step4.reviewResponse")
                  ?.transactions?.filter(
                    (dataItem: any) => dataItem.isDuplicate,
                  )?.length !== 0 && (
                  <LoadingButton
                    variant="contained"
                    size={"medium"}
                    loading={handleReviewMappingLoading}
                    onClick={form.handleSubmit(() => {
                      const reviewData = [
                        ...form
                          .getValues("step4")
                          ?.reviewResponse?.transactions?.filter(
                            (dataItem: any) => !dataItem.isDuplicate,
                          ),
                        ...form
                          .getValues("step4")
                          ?.reviewResponse?.transactions?.filter(
                            (dataItem: any) =>
                              form
                                .getValues("step4.duplicateIds")
                                ?.includes(dataItem?.id),
                          ),
                      ]
                        .flat()
                        .map((dataItem) => dataItem.id);

                      handleReviewMapping(reviewData);
                    })}
                  >
                    {form.getValues("step4.duplicateIds").length !== 0
                      ? "Next"
                      : " Ignore Duplicates & Finish"}
                  </LoadingButton>
                )}

              {getActiveStep === 3 &&
                form
                  .getValues("step4.reviewResponse")
                  ?.transactions?.filter(
                    (dataItem: any) => dataItem.isDuplicate,
                  )?.length === 0 && (
                  <LoadingButton
                    variant="contained"
                    size={"medium"}
                    loading={handleReviewMappingLoading}
                    onClick={form.handleSubmit((values) => {
                      const reviewData =
                        values?.step4?.reviewResponse?.transactions.map(
                          (dataItem: any) => dataItem.id,
                        );

                      handleReviewMapping(reviewData);
                    })}
                  >
                    Finish
                  </LoadingButton>
                )}
            </div>
          </Show.When>
        </Grid2>
      }
    >
      <FormProvider {...form}>
        <form
          id="broker-form"
          onSubmit={form.handleSubmit((values: any) => {
            if (activeStep === 0) {
              brokerData(values.step1);
            } else if (activeStep === 1) {
              const portfolios = values.step2?.portfolio.map(
                (dataItem: any) => {
                  return {
                    value: dataItem.value,
                    portfolio: dataItem.portfolio,
                  };
                },
              );

              handlePortfolioMapping(portfolios);
            } else if (activeStep === 2) {
              handleCompanyMapping(values?.step3?.company);
            }
          })}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              <Show.When isTrue={loading}>
                <UploadManagerStepperModal
                  activeStep={activeStep + 1}
                  loading={loading}
                  format={form.getValues("step1.subType")}
                />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && activeStep === 0}>
                <ImportBrokerStepOne />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && activeStep === 1}>
                <BrokerPortfolioMapping fields={fields} />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && activeStep === 2}>
                <BrokerCompanyMapping fields={companyField} />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && activeStep === 3}>
                <ReviewMapping />
              </Show.When>
            </Grid2>
          </Grid2>
        </form>
      </FormProvider>
    </GKModal>
  );
};

export default ImportBrokerModal;
