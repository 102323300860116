import { GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useNavigate, useParams } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { FAMILY_ID_BY_HOLDING } from "utils/apiRoutes";
import { assetsHoldingsColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";

const HoldingTab = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const requestBody = useGenerateQueryParams("familyHolding");

  const { data: familyState, isLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_HOLDINGS, requestBody],
    route: FAMILY_ID_BY_HOLDING(id),
    requestBody,
  });

  return (
    <GKDataGrid
      loading={isLoading}
      columns={assetsHoldingsColumns}
      rows={familyState?.data?.data || []}
      getRowId={(row) => row?.isin}
      zeroHolding
      onRowClick={(row: any) => {
        navigate(
          `/app/holdings/${id}/transactions?type=${row?.row?.instType}`,
          {
            state: {
              holdingId: id,
              name: row?.row?.name,
              gcode: row?.row?.gcode,
              currentAmount: row?.row?.currentAmount,
              investedAmount: row?.row?.investedAmount,
              quantity: row?.row?.quantity,
              type: "family",
            },
          },
        );
      }}
      totalCount={familyState?.data?.totalCount || 0}
      name="familyHolding"
      tableName="family_holding_list"
      headerFilter={<TableFiltering name="familyHolding" />}
      exportFile={{
        path: FAMILY_ID_BY_HOLDING(id),
        exportName: "FamilyHoldingsList",
      }}
    />
  );
};

export default HoldingTab;
