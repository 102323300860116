import { useAppContext } from "AppContext";
import {
  Divider,
  Grid2,
  GrUserSettings,
  LuCalendarDays,
  Tab,
  Tabs,
  Typography,
} from "components";
import {
  HoldingDataInterface,
  PortfolioDetailsInterface,
} from "interfaces/Portfolio";
import { UserRoleTypes } from "utils/common";
import { matchDate } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

interface Props {
  portfolioDetailData: PortfolioDetailsInterface;
  holdingSummaryDetails: HoldingDataInterface;
}

const PortfolioDetailsHeaderSection = (props: Props) => {
  const { portfolioDetailData, holdingSummaryDetails } = props;

  const {
    state: { tab, userData },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  return (
    <>
      <Grid2 size={4} container>
        <Grid2 size={12}>
          <Typography fontWeight={600} fontSize={20} color={"#202939"}>
            {portfolioDetailData?.clientName}
          </Typography>
        </Grid2>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
          <Typography color={"#000000"} fontWeight={600} fontSize={12}>
            <span
              style={{
                color: theme.palette.secondaryText.main,
                marginRight: 4,
                fontWeight: 400,
              }}
            >
              Portfolio Name:
            </span>
            {portfolioDetailData?.name}
          </Typography>
          <Typography color={"#000000"} fontWeight={600} fontSize={12}>
            <span
              style={{
                color: theme.palette.secondaryText.main,
                marginRight: 4,
                fontWeight: 400,
              }}
            >
              Portfolio Code:
            </span>
            {portfolioDetailData?.portfolioId}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2
        size={8}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={2}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color={theme.palette.secondaryText.main}
            fontSize={12}
          >
            <GrUserSettings size={14} style={{ marginRight: 4 }} />
            Family Name:
            <span
              style={{
                color: theme.palette.dark.main,
                fontWeight: 600,
                marginLeft: 4,
              }}
            >
              {portfolioDetailData?.familyName}
            </span>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color={theme.palette.secondaryText.main}
            fontSize={12}
          >
            <LuCalendarDays size={14} style={{ marginRight: 4 }} />
            Inception Date:{" "}
            <span
              style={{
                color: theme.palette.dark.main,
                fontWeight: 600,
                marginLeft: 4,
              }}
            >
              {matchDate(holdingSummaryDetails?.inceptionDate, "DD MMM YYYY")}
            </span>
          </Typography>
        </div>
        {userData?.userRole !== UserRoleTypes.client && (
          <>
            <Divider orientation="vertical" />
            <Tabs
              className="light"
              variant="standard"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: {
                    ...tab,
                    portfolioExtendedViewTabs: 1,
                    portfolioTabs: val,
                  },
                });
              }}
              value={tab?.portfolioTabs}
            >
              <Tab value={0} label="Holding View" />
              <Tab value={1} label="Admin View" />
            </Tabs>
          </>
        )}
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
    </>
  );
};

export default PortfolioDetailsHeaderSection;
