import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import {
  GKButton,
  GKDataGrid,
  Grid2,
  StatusField,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useNavigate } from "react-router-dom";
import { GET_CLIENT_COMPLIANCE } from "utils/apiRoutes";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const CompliancePage = () => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  useDocumentTitle("Onboarding");

  const requestBody = useGenerateQueryParams("compliance");

  const { data: complianceData, isFetching } = useFetchQuery({
    key: [QueryKeys.COMPLIANCE_LIST, requestBody],
    route: GET_CLIENT_COMPLIANCE,
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name / Code", "clientName", 250),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography>{params?.row?.clientCode}</Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Risk Profile", "riskScore", 150),
      renderCell: (props) => {
        return (
          <StatusField statusString={props?.row?.riskScore || "Pending"} />
        );
      },
    },
    {
      ...extraColumnsField("KYC", "kycStatus", 150),
      renderCell: (props) => {
        return (
          <StatusField statusString={props?.row?.kycStatus || "Pending"} />
        );
      },
    },
    {
      ...extraColumnsField("Agreement", "agreementStatus", 150),
      renderCell: (props) => {
        return (
          <StatusField
            statusString={props?.row?.agreementStatus || "Pending"}
          />
        );
      },
    },
    {
      ...extraColumnsField("Actions", "action", 200),
      align: "right",
      headerAlign: "right",
      renderCell: (props) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <GKButton
              variant="link"
              onClick={() => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, complianceTabs: 2, clientERMPageTabs: 4 },
                });
                navigate(`/app/clients/${props.row.id}`, {
                  state: { name: props.row?.clientName },
                });
              }}
            >
              Risk
            </GKButton>
            &nbsp;
            <GKButton
              variant="link"
              onClick={() => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, clientERMPageTabs: 4 },
                });
                dispatch({
                  type: "TAB",
                  data: { ...tab, complianceTabs: 0, clientERMPageTabs: 4 },
                });
                navigate(`/app/clients/${props.row.id}`, {
                  state: { name: props.row?.clientName },
                });
              }}
            >
              KYC
            </GKButton>
            &nbsp;
            <GKButton
              variant="link"
              onClick={() => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, clientERMPageTabs: 4, complianceTabs: 1 },
                });
                navigate(`/app/clients/${props.row.id}`, {
                  state: { name: props.row?.clientName },
                });
              }}
            >
              Agreement
            </GKButton>
          </Grid2>
        );
      },
    },
  ];

  return (
    <GKDataGrid
      loading={isFetching}
      rows={complianceData?.data?.data || []}
      columns={columns}
      totalCount={complianceData?.data.totalCount || 0}
      name="compliance"
      exportFile={{
        path: GET_CLIENT_COMPLIANCE,
        exportName: "ClientComplianceList",
      }}
    />
  );
};

export default CompliancePage;
