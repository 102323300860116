/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2,
  MdExpandMore,
  Tab,
  Tabs,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { GET_FAQ, GET_FAQ_MODULES } from "utils/apiRoutes";
import { ThemeContainer } from "utils/Theme";

const FAQPage = () => {
  const { theme } = ThemeContainer();

  const [value, setValue] = useState(0);

  const [selectedValue, setSelectedValue] = useState(null);

  const { data: moduleData } = useFetchQuery({
    key: ["FAQ_MODULE"],
    route: GET_FAQ_MODULES,
    success: (data: any) => {
      setSelectedValue(data?.data?.data[0]?.id);
    },
  });

  const { data: questionsData } = useFetchQuery({
    key: ["FAQ_MODULE_QUESTION", selectedValue],
    route: GET_FAQ,
    enabled: Boolean(selectedValue),
    requestBody: {
      module_id: selectedValue,
    },
  });

  return (
    <Grid2 container alignItems={"center"} spacing={7} px={10} py={10} pt={20}>
      <Grid2 container size={3} spacing={2}>
        <Grid2 size={12} textAlign={"center"}>
          <Typography
            fontSize={36}
            fontWeight={900}
            style={{
              background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            FAQs
          </Typography>
          <Typography fontSize={20} width={"100%"} textAlign={"center"}>
            Trading and Markets
          </Typography>
        </Grid2>
        <Grid2 size={12} textAlign={"center"}>
          <Tabs
            value={value}
            onChange={(e, val) => {
              setValue(val);
            }}
            variant="scrollable"
            scrollButtons="auto"
            orientation="vertical"
          >
            {moduleData?.data?.data?.map((dataItem: any) => {
              return (
                <Tab
                  key={dataItem?.moduleName}
                  label={dataItem?.moduleName}
                  onClick={() => {
                    setSelectedValue(dataItem?.id);
                  }}
                  style={{ fontSize: "16px", textTransform: "capitalize" }}
                />
              );
            })}
          </Tabs>
        </Grid2>
      </Grid2>
      <Grid2 size={8}>
        {questionsData?.data?.data?.map((dataItem: any) => {
          return (
            <Accordion key={dataItem?.id}>
              <AccordionSummary expandIcon={<MdExpandMore size={20} />}>
                <Grid2
                  container
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid2>
                    <Typography
                      fontSize={18}
                      fontWeight={700}
                      style={{
                        color: theme.palette.light.main,
                      }}
                    >
                      {dataItem?.question || ""}
                    </Typography>
                  </Grid2>
                </Grid2>
              </AccordionSummary>
              <AccordionDetails>
                <Grid2 container spacing={2}>
                  <Grid2
                    size={12}
                    sx={{
                      "& .ql-editor": {
                        color: theme.palette.light.main,
                      },
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataItem?.description,
                      }}
                      className="ql-editor"
                    />
                  </Grid2>
                  {dataItem?.mediaFiles[0]?.mediaFileType && (
                    <Grid2
                      size={12}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      {["png", "jpg", "jpeg", "psd", "raw"]?.includes(
                        dataItem?.mediaFiles[0]?.mediaFileType?.toLowerCase(),
                      ) ? (
                        <img
                          src={dataItem?.mediaFiles[0]?.mediaFile}
                          alt=" "
                          style={{
                            borderRadius: 10,
                            height: 300,
                          }}
                          height={100}
                        />
                      ) : (
                        <video width="750" height="500" controls>
                          <source
                            src={dataItem?.mediaFiles[0]?.mediaFile}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </Grid2>
                  )}
                </Grid2>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid2>
    </Grid2>
  );
};

export default FAQPage;
