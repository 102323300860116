import { GridColDef } from "@mui/x-data-grid-pro";
import { GKDataGrid, Grid2, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { handleRemoveUnderscore } from "utils";
import { GET_ALL_LEDGER_ENTRIES } from "utils/apiRoutes";
import { returnParticularColor } from "utils/constants";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { QueryKeys } from "utils/queryKey";
import { TableFiltering } from "utils/TableFilters";
import useDocumentTitle from "utils/useDocumentTitle";

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 150),
    valueFormatter: (value) => matchDate(value.value),
  },
  {
    ...extraColumnsField("Portfolio", "clientName", 200, 1),
    renderCell: (params) => {
      return (
        <Grid2
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography
            fontWeight={700}
            maxWidth={"100%"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {params?.row?.clientName}
          </Typography>
          <Typography fontSize={12}>
            {params?.row?.portfolioName} - {params?.row?.portfolioId}
          </Typography>
        </Grid2>
      );
    },
  },
  {
    ...extraColumnsField("Particulars", "transactionType", 200),
    renderCell: (params) => {
      return (
        <Typography
          fontWeight={600}
          style={{
            width: "fit-content",
            background: `${returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType,
            )}33`,
            color: returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType,
            ),
            padding: "4px 8px",
            borderRadius: 6,
          }}
          textTransform={"capitalize"}
        >
          {params?.row?.transactionType?.toLowerCase() === "sell" ||
          params?.row?.transactionType?.toLowerCase() === "buy"
            ? `BILLAMOUNT - ${params?.row?.transactionType}`
            : params?.row?.transactionType}
          &nbsp;
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
              ? `/ ${handleRemoveUnderscore(
                  params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                    ?.addedField,
                )}`
              : ""}
          </span>
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Debit", "debit", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.debit || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Credit", "credit", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.credit || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
];

const BankLedger = () => {
  useDocumentTitle("Ledger");

  const requestBody = useGenerateQueryParams("bankLedger");

  const { data, isFetching } = useFetchQuery({
    key: [QueryKeys.BANK_LEDGER_LIST, requestBody],
    route: GET_ALL_LEDGER_ENTRIES,
    requestBody,
  });

  return (
    <GKDataGrid
      loading={isFetching}
      columns={columns || []}
      rows={data?.data?.data || []}
      totalCount={data?.data?.totalCount || 0}
      name="bankLedger"
      tableName="bank_ledger_list"
      maxHeight={"calc(100vh - 309px)"}
      headerFilter={<TableFiltering name="bankLedger" />}
      exportFile={{
        path: GET_ALL_LEDGER_ENTRIES,
        exportName: "bankLedgerList",
      }}
    />
  );
};

export default BankLedger;
