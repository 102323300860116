/* eslint-disable new-cap */
import { useMutation, useQuery } from "@tanstack/react-query";
import { GKButton, Grid2, Typography } from "components";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { ThemeContainer } from "utils/Theme";

declare global {
  interface Window {
    scDK: any;
  }
}

const SmallCase = () => {
  const { theme } = ThemeContainer();

  const location = useLocation();

  const [brokerAuthData, setBrokerAuthData] = useState<any>({});
  const [gatewayInstance, setGatewayInstance] = useState<any>(null);
  const [gatewayErrorShow, setGatewayErrorShow] = useState(false);
  const [authAddedMessage, setAuthAddedMessage] = useState(false);

  const token = new URLSearchParams(location.search).get("token");
  const portfolioId = new URLSearchParams(location.search).get(
    "portfolio_smallcase",
  );
  const logo = location.search.split("logo=")[1];

  useQuery({
    queryKey: ["SMALL-CASE-AUTH-BROKER"],
    queryFn: () =>
      axiosInstance.get(
        `/smallcase-gateway/smallcase-broker-auth/?portfolio_smallcase=${portfolioId}`,
        { headers: { Authorization: `Token ${token}` } },
      ),
    enabled: Boolean(token && portfolioId),
    onSuccess: (response) => {
      setBrokerAuthData(response.data.data);
      const instance = new window.scDK({
        gateway: response.data.data?.gatewayName || "gridkey",
        smallcaseAuthToken: response.data.data?.jwtAuth || "",
        config: {
          amo: true,
        },
      });
      setGatewayInstance(instance);
    },
    onError: (error: any) => {
      if (error?.response?.data?.message?.includes("Invalid token")) {
        setGatewayErrorShow(true);
      }
    },
  });

  const { mutate: handleAddBroker } = useMutation({
    mutationKey: ["SMALL_CASE_GATEWAY_BROKER_AUTH"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/smallcase-gateway/${portfolioId}/`,
        { smallcaseAuthId: data?.smallcaseAuthToken, broker: data?.broker },
        { headers: { Authorization: `Token ${token}` } },
      ),
    onSuccess: (response) => {
      setAuthAddedMessage(true);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const handleActivateButtonClick = () => {
    if (gatewayInstance) {
      gatewayInstance
        .triggerTransaction({ transactionId: brokerAuthData?.transactionId })
        .then((response: any) => handleAddBroker(response))
        .catch(() => setGatewayErrorShow(true));
    } else {
      toast.error("Gateway instance not available yet.");
    }
  };

  return (
    <Grid2
      container
      style={{
        width: "100vw",
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.light.main,
      }}
      boxShadow={`inset 0 0 63px -26px #63A387, inset 0 0 63px -16px #00566e`}
      p={{
        xs: 2,
        md: 10,
        lg: 20,
      }}
    >
      <Grid2 container p={2} spacing={2} borderRadius={2}>
        <Grid2
          size={12}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
        >
          <img
            onError={({ currentTarget }) => {
              currentTarget.src = "/Logos/errorImg.svg";
            }}
            src={logo}
            alt="brokerImage"
            style={{
              maxWidth: "120px",
            }}
          />
        </Grid2>

        {gatewayErrorShow ? (
          <Grid2
            size={12}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Typography sx={{ color: theme.palette.error.main }}>
              Activation an error occurred while activating broker account.
              please try again
            </Typography>
          </Grid2>
        ) : authAddedMessage ? (
          <Grid2
            size={12}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Typography
              sx={{ color: theme.palette.primary.main }}
              textAlign={"center"}
            >
              Broker integration activated successfully
            </Typography>
          </Grid2>
        ) : (
          <Grid2 container size={12} spacing={2}>
            <Grid2
              size={12}
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
            >
              <Typography
                fontSize={18}
                fontWeight={600}
                sx={{ color: theme.palette.primary.main }}
                textAlign={"center"}
              >
                You are a few steps away from linking your broker account.
              </Typography>
            </Grid2>
            <Grid2
              size={12}
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
            >
              <GKButton
                variant="outlined"
                color="dark"
                onClick={handleActivateButtonClick}
              >
                Approve
              </GKButton>
            </Grid2>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );
};

export default SmallCase;
