import {
  Divider,
  FormControlLabel,
  Grid2,
  Switch,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFetchQuery } from "hooks/useQueries";
import _ from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { permissions } from "utils/permission";
import useDocumentTitle from "utils/useDocumentTitle";
import SimpleLoader from "./SimpleLoader";

interface Props {
  headerTitle: string;
  route: string;
  queryKey: string;
  permissionType: string;
}

const Permission = (props: Props) => {
  const { headerTitle, route, queryKey, permissionType } = props;

  useDocumentTitle(headerTitle);

  const [switchValues, setSwitchValues] = useState<any>({});

  const { isFetching: isLoading, refetch } = useFetchQuery({
    key: [queryKey],
    route: route,
    success: (data: any) => setSwitchValues(data.data),
  });

  const { mutate: permissionChange } = useMutation({
    mutationKey: [queryKey],
    mutationFn: (data: any) => axiosInstance.patch(route, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
      refetch();
    },
  });

  if (isLoading) return <SimpleLoader />;

  const handleChangePermission = (event: any, permission: string) => {
    const value = event.target.checked;
    setSwitchValues((prevData: any) => {
      return _.set({ ...prevData }, permission, value);
    });

    const permissionData = _.set({}, permission, value);

    permissionChange(permissionData);
  };

  const handleToggleAllPermissions = (event: any, dataItem: any) => {
    const isChecked = event.target.checked;
    const newSwitchValues = _.cloneDeep(switchValues);

    _.forEach(
      _.filter(dataItem.subPermission, (x) =>
        _.includes(x.isExist, permissionType),
      ),
      (subPermission) => {
        _.set(newSwitchValues, subPermission.permission, isChecked);
      },
    );

    setSwitchValues(newSwitchValues);

    // Send a batch update request for all permissions in the accordion
    const permissionsData = _.reduce(
      _.filter(dataItem.subPermission, (x) =>
        _.includes(x.isExist, permissionType),
      ),
      (acc, subPermission) => {
        return _.set(acc, subPermission.permission, isChecked);
      },
      {},
    );

    permissionChange(permissionsData);
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        {_.map(
          _.filter(permissions, (x) => _.includes(x.isExist, permissionType)),
          (dataItem, index) => {
            const allPermissionsEnabled = _.every(
              _.filter(dataItem.subPermission, (x) =>
                _.includes(x.isExist, permissionType),
              ),
              (subPermission) => _.get(switchValues, subPermission.permission),
            );

            return (
              <Grid2 container size={12} spacing={2}>
                <Grid2
                  size={3}
                  display={"flex"}
                  alignItems={"flex-start"}
                  key={index}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={allPermissionsEnabled}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleToggleAllPermissions(e, dataItem);
                        }}
                      />
                    }
                    label={
                      <Typography fontSize={16} fontWeight={600}>
                        {dataItem.mainHeading}
                      </Typography>
                    }
                  />
                </Grid2>
                <Grid2
                  container
                  size={9}
                  spacing={2}
                  display={"inline-flex"}
                  alignItems={"center"}
                >
                  {_.map(
                    _.filter(dataItem.subPermission, (x) =>
                      _.includes(x.isExist, permissionType),
                    ),
                    (data, subIndex) => {
                      return (
                        <Grid2
                          size={6}
                          display={"flex"}
                          alignItems={"center"}
                          key={subIndex}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                sx={{ marginTop: "1px" }}
                                checked={
                                  _.get(switchValues, data.permission) || false
                                }
                                name={data.permission}
                                onChange={(e) =>
                                  handleChangePermission(e, data.permission)
                                }
                              />
                            }
                            label={
                              <div>
                                <Typography
                                  fontSize={14}
                                  fontWeight={500}
                                  sx={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    // whiteSpace: "nowrap",
                                    width: "100%",
                                  }}
                                >
                                  {data.subHeading}
                                </Typography>
                                {/* <Typography fontSize={12} color={theme.palette.secondaryText.main}>
                                      {"data.subText"}
                                    </Typography> */}
                              </div>
                            }
                            sx={{
                              alignItems: "center",
                              flexWrap: "nowrap",
                              width: "100%",
                            }}
                          />
                        </Grid2>
                      );
                    },
                  )}
                </Grid2>
                <Grid2 size={12}>
                  <Divider sx={{ mb: 2 }} />
                </Grid2>
              </Grid2>
            );
          },
        )}
      </Grid2>
    </Grid2>
  );
};

export default Permission;
