/* eslint-disable no-useless-concat */
import GKCharts from "components/GKCharts";
import { ThemeContainer } from "utils/Theme";

interface SunburstChartProps {
  data: any;
  sunBurstClickData?: any;
  handleSunBurstText?: any;
  onClick?: (clickedElement: any) => void;
  onHover?: (hoveredElement: any) => void;
}

const ResponsiveSunburstChart = (props: SunburstChartProps) => {
  const { theme } = ThemeContainer();
  const { data, sunBurstClickData, onClick, onHover, handleSunBurstText } =
    props;

  const chartOptions = {
    chart: {
      type: "sunburst",
      height: 300,
      width: 300,
    },
    title: {
      text: sunBurstClickData
        ? sunBurstClickData.id
        : handleSunBurstText() || "Stocks",
      verticalAlign: "middle",
      floating: true,
    },
    tooltip: {
      formatter(this: any) {
        return `<div style="font-weight: 600;font-size: 12;text-transform:capitalize;">${
          this.key
        } : ${this.point.value.toFixed(2)}%</div>`;
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
      sunburst: {
        innerSize: "40%",
        dataLabels: {
          enabled: false,
        },
        borderColor: "none",
        slicedOffset: 8,
        colors: data.map((d: any) => d.color),
        point: {
          events: {
            click(event: any) {
              onClick && onClick({ id: event.point.name });
            },
            mouseOver(event: any) {
              onHover && onHover({ id: event.target.name });
            },
            mouseOut() {
              onHover && onHover(null);
            },
          },
        },
      },
    },
    series: [
      {
        type: "sunburst",
        data: data,
        name: "Root",
      },
    ],
    legend: {
      enabled: true,
      align: "right",
      verticalAlign: "bottom",
      layout: "vertical",
      itemStyle: {
        color: theme.palette.grey[700],
      },
      itemHoverStyle: {
        color: theme.palette.dark.main,
      },
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
    },
  };

  return <GKCharts chartOptions={chartOptions} />;
};

export default ResponsiveSunburstChart;
