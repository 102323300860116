import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  PORTFOLIO_HOLDING_BY_ID,
  PORTFOLIO_REFRESH_XIRR_BY_ID,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import useGenerateQueryParams from "./useGenerateQueryParams";
import { useFetchQuery } from "./useQueries";

const usePortfolioHoldingDetails = ({
  portfolioExtendedViewTabs,
}: {
  portfolioExtendedViewTabs: number;
}) => {
  const { id } = useParams<string>();

  const {
    state: { columnsPersist, userData },
    dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  const [tableColumnModalOpen, setTableColumnModalOpen] = useState(false);

  const [filterModal, setFilterModal] = useState(false);

  const queryClient = useQueryClient();

  const [isXirrRefresh, setIsXirrRefresh] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [allHoldings, setAllHoldings] = useState([]);

  const handleReturnInstType = (val: number) => {
    switch (val) {
      case 1:
        return JSON.stringify([]);
      case 2:
        return JSON.stringify(["EQ"]);
      case 3:
        return JSON.stringify(["MF"]);
      case 4:
        return JSON.stringify(["ETF"]);
      case 5:
        return JSON.stringify(["CE"]);
      case 6:
        return JSON.stringify(["PEQ"]);
      case 7:
        return JSON.stringify(["FD"]);

      default:
        return JSON.stringify(["NCE", "MF", "CE", "PEQ", "FD"]);
    }
  };

  const requestBody = useGenerateQueryParams("portfolioHoldingsTable", {
    inst_type: handleReturnInstType(portfolioExtendedViewTabs),
  });

  // ============================================================
  const { isFetching: xirrLoading } = useFetchQuery({
    route: PORTFOLIO_REFRESH_XIRR_BY_ID(id),
    key: ["GET_PORTFOLIO_XIRR"],
    enabled: isXirrRefresh,
    success: () => {
      queryClient?.refetchQueries(["GET_PORTFOLIO_HOLDINGS_CARD"]);
      setIsXirrRefresh(false);
    },
  });

  // ============================================================
  const { isFetching: isLoading, data: holdingsData } = useFetchQuery({
    key: [QueryKeys.HOLDINGS_LIST, id, requestBody],
    route: PORTFOLIO_HOLDING_BY_ID(+id),
    requestBody,
    success: (response: any) => {
      if (response?.data?.data.length > 0) {
        setAllHoldings([
          ...response?.data?.data,
          {
            unrealisedGain: response?.data?.unrealisedGain,
            realisedGain: response?.data?.realisedGain,
            otherGains: response?.data?.otherGains,
            quantity: response?.data?.quantity,
            rawQuantity: response?.data?.quantity,
            isValid: true,
            investedAmount: response?.data?.investedAmount,
            totalNetInvestment: response?.data?.totalNetInvestment,
            currentAmount: response?.data?.currentAmount,
            totalAmount: response?.data?.totalAmount,
            todaysGain: response?.data?.todaysGain,
            todaysGainPercentage: response?.data?.todaysGainPercentage,
            lastTradedPrice: response?.data?.lastTradedPrice,
            avgBuyPrice: response?.data?.avgBuyPrice,
            cmp: response?.data?.cmp,
            yesterdayValue: response?.data?.yesterdayValue,
            unrealisedGainPercentage: response?.data?.unrealisedGainPercentage,
            absoluteReturn: response?.data?.absoluteReturn,
            ltHoldingsCount: response?.data?.ltHoldingsCount,
            instType: " ",
            instSubType: " ",
            folioNo: response?.data?.folioNo,
            weightage: response?.data?.weightage,
            id: 100000,
            isin: " ",
            gcode: " ",
            name: "",
            fullName: " ",
            cap: " ",
            sector: " ",
            bse: " ",
            nse: "",
            lastPrice: response?.data?.lastPrice,
            pastPrice: response?.data?.pastPrice,
          },
        ]);
      } else {
        setAllHoldings(response.data.data);
      }
    },
  });

  // ============================================================
  const { mutate: deletePortfolio } = useMutation({
    mutationKey: ["DELETE_PORTFOLIO"],
    mutationFn: (id: number) => axiosInstance.delete(`/portfolios/${id}/`),
    onSuccess: (response) => {
      navigate(-1);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  // ============================================================
  const loading = xirrLoading || isLoading;
  // ============================================================
  return {
    id,
    deletePortfolio,
    loading,
    openConfirmationModal,
    setOpenConfirmationModal,
    navigate,
    allHoldings,
    setSearchText,
    searchText,
    columnsPersist,
    dispatch,
    tableColumnModalOpen,
    setTableColumnModalOpen,
    filterModal,
    setFilterModal,
    holdingsData,
    userData,
  };
};

export default usePortfolioHoldingDetails;
