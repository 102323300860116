import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import {
  ConfirmDeleteModal,
  FaFileInvoice,
  FiTrash2,
  GKDataGrid,
  Grid2,
  IconButton,
  MdOutlineFileDownload,
  Show,
  StatusField,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { PROFORMA_HISTORY } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import ConvertToInvoiceModal from "./ConvertToInvoiceModal";

interface Props {
  name?: string;
  tableName?: string;
}

const PerformaInvoiceTab = (props: Props) => {
  useDocumentTitle("Proforma");

  const { id } = useParams();

  const { name, tableName } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const requestBody = useGenerateQueryParams(name || "proformaInvoice", {
    portfolio_id: id,
  });

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["PROFORMA_LIST", requestBody],
    route: PROFORMA_HISTORY,
    requestBody,
  });

  // const { mutate: updateForfeit } = useMutation({
  //   mutationKey: ["UPDATE_FORFEIT"],
  //   mutationFn: ({ id, value }: { id: any; value: boolean }) =>
  //     axiosInstance.patch(`/proforma-history/${id}/`, {
  //       is_forfeit: value,
  //     }),
  //   onSuccess: (response) => {
  //     refetch();
  //     toast.success(response.data?.message || "Action completed successfully.");
  //   },
  //   onError: (error: any) => {
  //     toast.error(
  //       error?.response?.data?.message ||
  //         "Something went wrong, please try again."
  //     );
  //   },
  // });

  const { mutate: handleDeleteProforma } = useMutation({
    mutationKey: ["DELETE_PROFORMA"],
    mutationFn: (id) => axiosInstance.delete(`/proforma-history/${id}/`),
    onSuccess: (response) => {
      refetch();
      setInvoiceId(null);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Proforma No", "proformaNumber", 150, 1),
    },
    {
      ...extraColumnsField("Date", "date", 100, 1),
      valueFormatter: (value) => matchDate(value.value),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params?.row?.createdAt).format("D MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Amount", "feeWithGst", 150, 1),
      valueGetter: (row) => row?.row?.feeWithGst,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
      type: "number",
    },
    {
      ...extraColumnsField("Fee Plan Name", "feePlanName", 150, 1),
    },
    {
      ...extraColumnsField("Invoice", "isInvoice", 120, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.isInvoice.toString()} />
      ),
    },
    // {
    //   headerName: "Forfeit",
    //   flex: 1,
    //   minWidth: 100,
    //   field: "isForfeit",
    //   disableColumnMenu: true,
    //   renderCell: (params) => {
    //     return (
    //       <Switch
    //         checked={params.row.isForfeit}
    //         disabled={params?.row?.isInvoice}
    //         onChange={(event) => {
    //           const updatedRows = data?.data?.data.map((row: any) => {
    //             if (row.id === params.row.id) {
    //               return { ...row, isForfeit: event.target.checked };
    //             }
    //             return row;
    //           });

    //           updateForfeit({ id: params.row.id, value: updatedRows });
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      ...extraColumnsField("Actions", "action", 150, 1),
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: (params) => (
        <Grid2 container gap={1} display={"flex"} justifyContent={"flex-end"}>
          {/* <Tooltip title="Forfeit">
            <IconButton
              onClick={() => {
                updateForfeit({ id: params.row.id, value: true });
              }}
              disabled={params?.row?.isInvoice || params?.row?.isForfeit}
            >
              <FiXCircle size={20} />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Download PDF">
            <IconButton
              onClick={() => {
                fetch(params?.row?.proformaPdf)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = params?.row?.proformaName;
                    link.click();
                  });
              }}
            >
              <MdOutlineFileDownload size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Convert To Invoice">
            <IconButton
              disabled={params?.row?.isForfeit || params?.row?.isInvoice}
              onClick={() => {
                setInvoiceId(params.row.id);
                setIsOpenModal(true);
              }}
            >
              <FaFileInvoice size={20} />
            </IconButton>
          </Tooltip>
          <IconButton
            color="error"
            className="outline"
            onClick={() => {
              setInvoiceId(params.row.id);
              setConfirmationModal(true);
            }}
          >
            <FiTrash2 size={20} />
          </IconButton>
        </Grid2>
      ),
    },
  ];

  return (
    <Grid2 container>
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        maxHeight={"calc(100vh - 317px)"}
        totalCount={data?.data?.totalCount || 0}
        name={name || "proformaInvoice"}
        tableName={tableName || "proforma_invoice"}
        headerFilter={<TableFiltering name={name || "proformaInvoice"} />}
      />

      {isOpenModal && (
        <ConvertToInvoiceModal
          open={isOpenModal}
          setOpen={setIsOpenModal}
          invoiceId={invoiceId}
          refetch={refetch}
          name={name}
        />
      )}

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteProforma(invoiceId)}
          title={"Proforma"}
          isDelete
        />
      </Show.When>
    </Grid2>
  );
};

export default PerformaInvoiceTab;
