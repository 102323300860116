import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CgMathPlus,
  Divider,
  GKButton,
  GKDataGrid,
  Grid2,
  LuCalendarCheck,
  LuUsers,
  Show,
  Typography,
} from "components";
import dayjs from "dayjs";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { ThemeContainer } from "utils/Theme";
import {
  ETF_TRANSACTION_BY_PORTFOLIO_ID,
  GET_PORTFOLIO_BY_ID,
} from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import DividendEditModal from "./DividendEditModal";
import DividendModal from "./DividendModal";

const PortfolioETFTransaction = () => {
  useDocumentTitle("ETF Interest");

  const { theme } = ThemeContainer();

  const { id } = useParams<{ id: string }>();

  const [portfolioDetailData, setPortfolioDetailData] = useState<any>(null);
  const [openEtfModal, setOpenEtfModal] = useState(false);
  const [openEditETFModal, setOpenEditETFModal] = useState(false);
  const [etfData, setEtfData] = useState<any>({});

  const { isFetching: portfolioFetch } = useFetchQuery({
    key: ["GET_PORTFOLIO_DETAIL_BY_ID", id],
    route: GET_PORTFOLIO_BY_ID(id),
    success: (data: any) => {
      setPortfolioDetailData(data?.data);
    },
  });

  const requestBody = useGenerateQueryParams("portfolioInterest");

  const { data, isFetching } = useFetchQuery({
    route: ETF_TRANSACTION_BY_PORTFOLIO_ID(id),
    key: [QueryKeys.PORTFOLIO_EFT_TRANSACTION_LIST, requestBody],
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
    },
    {
      ...extraColumnsField("NSE/BSE Code", "nseCode", 150, 1),
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.nseCode || params?.row?.bseCode}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Quantity", "quantity", 150, 1),
      align: "right",
      headerAlign: "right",
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.quantity || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
  ];

  return (
    <DashboardLayout title="PortfolioDetailsPage" loading={portfolioFetch}>
      <Grid2 container spacing={1}>
        <Grid2 size={3}>
          <Typography fontWeight={600} fontSize={20} color={"#202939"}>
            {portfolioDetailData?.name}
          </Typography>
          <Typography
            color={theme.palette.secondaryText.main}
            fontWeight={500}
            fontSize={12}
          >
            {portfolioDetailData?.portfolioId}
          </Typography>
        </Grid2>
        <Grid2
          size={9}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={2}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color={theme.palette.secondaryText.main}
            fontSize={12}
          >
            <LuUsers style={{ marginRight: 4 }} />
            Family name:
            <span
              style={{
                color: "#202939",
                marginLeft: 4,
              }}
            >
              {portfolioDetailData?.familyName}
            </span>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color={theme.palette.secondaryText.main}
            fontSize={12}
          >
            <LuCalendarCheck style={{ marginRight: 4 }} />
            Inception Date:{" "}
            <span
              style={{
                color: "#202939",
                marginLeft: 4,
              }}
            >
              {dayjs(portfolioDetailData?.createdOn).format("DD MMM YYYY")}
            </span>
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          <GKDataGrid
            loading={isFetching}
            columns={columns}
            rows={data?.data?.data || []}
            totalCount={data?.data?.totalCount || 0}
            name="portfolioInterest"
            onRowClick={(params) => {
              setEtfData(params.row);
              setOpenEditETFModal(true);
            }}
            tableName="portfolio_interest"
            headerFilter={<TableFiltering name="portfolioInterest" />}
            headerComponent={
              <GKButton
                variant="outlined"
                color="dark"
                onClick={() => setOpenEtfModal(true)}
                startIcon={<CgMathPlus size={14} />}
              >
                Add ETF Interest
              </GKButton>
            }
            exportFile={{
              path: ETF_TRANSACTION_BY_PORTFOLIO_ID(id),
              exportName: "portfolioInterest",
            }}
          />
        </Grid2>

        <Show.When isTrue={openEtfModal}>
          <DividendModal
            open={openEtfModal}
            setOpen={setOpenEtfModal}
            portfolioDetailData={portfolioDetailData}
          />
        </Show.When>

        <Show.When isTrue={openEditETFModal}>
          <DividendEditModal
            open={openEditETFModal}
            setOpen={setOpenEditETFModal}
            portfolioDetailData={portfolioDetailData}
            etfData={etfData}
          />
        </Show.When>
      </Grid2>
    </DashboardLayout>
  );
};

export default PortfolioETFTransaction;
