import { GridColDef } from "@mui/x-data-grid-pro";
import {
  BiEdit,
  GKButton,
  GKDataGrid,
  Grid2,
  Show,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import { FAMILY_ID_BY_CLIENT } from "utils/apiRoutes";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import EditFamilyClientPortfolioModal from "./EditFamilyClientPortfolioModal";

interface Props {
  data?: any;
}

const ClientTab = (props: Props) => {
  const { data } = props;

  const navigate = useNavigate();

  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const requestBody = useGenerateQueryParams("familyClient", {
    defaultShowZeroHolding: true,
  });

  const {
    data: getClientList,
    isLoading,
    refetch,
  } = useFetchQuery({
    key: [QueryKeys.FAMILY_CLIENT_LIST, requestBody],
    route: FAMILY_ID_BY_CLIENT(id),
    requestBody,
  });

  const editClientListColumns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name / Code", "name", 180, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.name || "-"}</Typography>
            <Typography>{params?.row?.clientCode || "-"}</Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Net Contribution", "netContribution", 150, 1),
      type: "number",
      valueGetter: (params) => params?.row?.netContribution || "0",
      valueFormatter: (params) => moneyCommaSeparator(params?.value),
    },
    {
      ...extraColumnsField("Invested Value", "investedValue", 150, 1),
      type: "number",
      valueGetter: (params) => params?.row?.investedValue || "0",
      valueFormatter: (params) => moneyCommaSeparator(params?.value),
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      type: "number",
      valueGetter: (params) => params?.row?.currentValue || "0",
      valueFormatter: (params) => moneyCommaSeparator(params?.value),
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage,
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 150, 1),
      type: "number",
      valueGetter: (params) => params?.row?.portfolioCount || "-",
      valueFormatter: (params) => moneyCommaSeparatorPoint(params?.value),
    },
  ];

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <GKDataGrid
          rows={getClientList?.data?.data || []}
          columns={editClientListColumns}
          loading={isLoading}
          onRowClick={(value) => {
            navigate(`/app/clients/${value.id}`);
          }}
          headerComponent={
            <GKButton
              variant="outlined"
              color="dark"
              onClick={() => setIsModalOpen(true)}
              startIcon={<BiEdit size={20} />}
            >
              Edit Family
            </GKButton>
          }
          zeroHolding
          name="familyClient"
          exportFile={{
            path: FAMILY_ID_BY_CLIENT(id),
            exportName: "FamilyClientList",
          }}
          totalCount={getClientList?.data?.totalCount || 0}
        />
      </Grid2>

      <Show.When isTrue={isModalOpen}>
        <EditFamilyClientPortfolioModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          data={data}
          refetch={refetch}
        />
      </Show.When>
    </Grid2>
  );
};

export default ClientTab;
