import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  ConfirmDeleteModal,
  Divider,
  Grid2,
  Show,
  Tab,
  Tabs,
} from "components";
import {
  HoldingDataInterface,
  PortfolioDetailsInterface,
} from "interfaces/Portfolio";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import { refetchQuery } from "utils/helpers";
import ModelPortfolioTab from "./PortfolioBaskets/ModelPortfolioTab";
import PortfolioNotes from "./PortfolioNotes";
import PortfolioProfileTab from "./PortfolioProfileTab";
import PortfolioRevenue from "./PortfolioRevenue";
import ReportsPages from "./ReportsPages";
import PortfolioSettingsEdit from "./SettingsPages";
import SettingTab from "./SettingTab";
import Benchmark from "./SummaryPages/Benchmark";
import PortfolioUtilityEdit from "./UtilityPages";

interface Props {
  portfolioDetailData: PortfolioDetailsInterface;
  holdingSummaryDetails: HoldingDataInterface;
}

const PortfolioAdminViewTab = (props: Props) => {
  const { portfolioDetailData, holdingSummaryDetails } = props;

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    state: { userData, tab },
    dispatch,
  } = useAppContext();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { mutate: deletePortfolio, isLoading: deleteLoading } = useMutation({
    mutationKey: ["DELETE_PORTFOLIO"],
    mutationFn: () => axiosInstance.delete(`/portfolios/${id}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      navigate(`/app/holdings`);
    },
    onError: (error: any) => {
      refetchQuery("GET_PORTFOLIO_DETAIL");
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    dispatch({
      type: "TAB",
      data: { ...tab, portfolioAdminViewTabs: value },
    });
  };

  const tabsConfig = [
    {
      label: "Profile",
      value: 0,
      component: (
        <PortfolioProfileTab portfolioDetailData={portfolioDetailData} />
      ),
      hideForClient: true,
    },
    {
      label: "Imports",
      value: 1,
      component: <PortfolioUtilityEdit />,
      hideForClient: true,
    },
    {
      label: "Reports",
      value: 2,
      component: <ReportsPages portfolioDetailData={portfolioDetailData} />,
      hideForClient: true,
    },
    {
      label: "Notes",
      value: 3,
      component: <PortfolioNotes portfolioDetailData={portfolioDetailData} />,
      hideForClient: true,
    },
    {
      label: "Benchmark",
      value: 4,
      component: <Benchmark benchmarkId={portfolioDetailData?.benchmark} />,
      hideForClient: true,
    },
    {
      label: "Baskets",
      value: 5,
      component: (
        <ModelPortfolioTab
          holdingSummaryDetails={holdingSummaryDetails}
          portfolioDetailData={portfolioDetailData}
        />
      ),
      hideForClient: true,
    },
    {
      label: "Revenue",
      value: 6,
      component: <PortfolioRevenue />,
      hideForClient: true,
    },
    {
      label: "Access",
      value: 7,
      component: <PortfolioSettingsEdit />,
      hideForClient: true,
    },
    {
      label: "Settings",
      value: 8,
      component: <SettingTab data={portfolioDetailData} />,
      hideForClient: true,
    },
  ];

  const filteredTabs = tabsConfig.filter(
    (tab) =>
      !(tab.hideForClient && userData?.userRole === UserRoleTypes.client),
  );

  return (
    <>
      <Grid2 size={12} container>
        <Grid2 size={8}>
          <Tabs
            value={tab?.portfolioAdminViewTabs}
            onChange={handleTabChange}
            indicatorColor="primary"
          >
            {filteredTabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Grid2>
        <Grid2 size={4} textAlign={"right"}>
          <Show.When isTrue={tab?.portfolioAdminViewTabs === 8}>
            <LoadingButton
              loading={deleteLoading}
              variant="outlined"
              color="error"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Delete Portfolio
            </LoadingButton>
          </Show.When>
        </Grid2>

        <Grid2 size={12}>
          <Divider />
        </Grid2>
      </Grid2>

      <Grid2 size={12}>
        {filteredTabs.map(
          (tabConfig) =>
            tab?.portfolioAdminViewTabs === tabConfig.value && (
              <Show.When key={tabConfig.value} isTrue>
                {tabConfig.component}
              </Show.When>
            ),
        )}
      </Grid2>

      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          title="Portfolio"
          onDelete={() => deletePortfolio()}
          isDelete
          deleteMessage="Are you sure you want to delete this portfolio? This client will permanently be removed. This action cannot be undone."
        />
      )}
    </>
  );
};

export default PortfolioAdminViewTab;
