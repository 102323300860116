import { Grid2, Typography } from "components";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";

const RefundPolicy = () => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div ref={myRef} />
      <Grid2
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        sx={{
          background: `linear-gradient(180deg,#102E2A 12%, rgba(0,0,0) 100%)`,
          px: {
            xs: 3,
            lg: 10,
          },
        }}
        py={"140px"}
        pt={"223px"}
      >
        <Grid2 size={12}>
          <Typography
            textAlign={"center"}
            fontSize={44}
            fontWeight={700}
            style={{
              background:
                "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 50%, rgba(72,159,132,1) 100%)",
              WebkitBackgroundClip: " text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Refund Policy
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <Typography textAlign={"center"} fontSize={16}>
            At Gridkey Technologies, we stand by the quality of our
            products/services and want you to be fully satisfied with your
            purchase. We offer a 30-day money-back guarantee from the date of
            purchase. If you are not completely satisfied with our services, you
            may request a full refund within this period. To initiate a refund,
            please contact us at info@gridkey.in. Refunds are not available
            after 30 days from the purchase date.
          </Typography>
        </Grid2>
      </Grid2>{" "}
    </motion.div>
  );
};

export default RefundPolicy;
