/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import {
  BsThreeDotsVertical,
  CgMathPlus,
  Chip,
  ConfirmDeleteModal,
  GKButton,
  GKDataGrid,
  Grid2,
  IconButton,
  LuCalendarClock,
  LuStickyNote,
  LuTable2,
  LuUserPlus,
  Popover,
  RiDeleteBinLine,
  Show,
  TbLayoutKanban,
  Typography,
} from "components";
import { useLead } from "hooks/useLead";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { getHexaColor } from "utils/constants";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import AddNewMeeting from "./AddMeeting";
import AddNewNotes from "./AddNotes";
import AddStatusAndSouceModal from "./AddStatusAndSouceModal";
import CreateLeadModal from "./CreateLeadModal";
import LeadStatusPage from "./LeadStatusPage";
import UpdateLead from "./UpdatetLead";

const Lead = () => {
  useDocumentTitle("Leads");

  const {
    getAllLead,
    isLeadLoading,
    deleteLead,
    navigate,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    leadModalOpen,
    setLeadModalOpen,
    itemId,
    setItemId,
    updateModal,
    setUpdateModal,
    isLeadStatus,
    setIsLeadStatus,
    addStatusModal,
    setAddStuatusModal,
    refetch,
  } = useLead();

  const [cardActionAnchor, setCardActionAnchor] =
    useState<HTMLButtonElement | null>(null);

  const { theme } = ThemeContainer();

  const [isScheduleMeeting, setIsScheduleMeeting] = useState(false);

  const [meetingModal, setMeetingModal] = useState(false);

  const [openNoteModal, setOpenNoteModal] = useState(false);

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handledDeleteLead } = useMutation({
    mutationFn: (da: any) => axiosInstance.delete(`/lead/${da}/`),
    mutationKey: ["DELETE_LEAD"],
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_LEAD);
      setCardActionAnchor(null);
      refetchQuery("GET_ALL_LEADS");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handledConvertLead } = useMutation({
    mutationFn: (da: any) => axiosInstance.post(`/client/${da}/convert/`),
    mutationKey: ["DELETE_LEAD"],
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_LEAD);
      setCardActionAnchor(null);
      refetchQuery("GET_ALL_LEADS");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Name", "name", 150, 1),
    },
    {
      ...extraColumnsField("Company", "companyName", 150, 1),
    },
    {
      ...extraColumnsField("Email", "email", 150, 1),
      renderCell: (params) => (
        <Typography textTransform={"lowercase"}>
          {params?.row?.email || "-"}
        </Typography>
      ),
    },
    {
      ...extraColumnsField("Status", "leadStatusStr", 150, 1),
      renderCell: (params) => (
        <Chip
          sx={{
            padding: 0,
            height: 26,
            background: `${getHexaColor(params?.row?.leadStatusStr)}22`,
            color: `${getHexaColor(params?.row?.leadStatusStr)}`,
            borderRadius: 2,
            fontWeight: 600,
            "& .MuiChip-deleteIcon": {
              color: `${getHexaColor(params?.row?.leadStatusStr)}`,
            },
          }}
          label={params?.row?.leadStatusStr}
        />
      ),
    },
    {
      ...extraColumnsField("Phone", "phoneNumber", 150, 1),
      valueGetter: (params) => params?.row?.phoneNumber || "-",
    },
    {
      ...extraColumnsField("Tags", "tags", 180, 1),
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            {params?.row?.tagsList?.slice(0, 2)?.map((dataItem: any) => {
              return (
                <Chip
                  sx={{
                    padding: 0,
                    height: 26,
                    mr: "4px",
                    background: `${getHexaColor(dataItem?.tag)}22`,
                    color: `${getHexaColor(dataItem?.tag)}`,
                    borderRadius: 2,
                    fontWeight: 600,
                    "& .MuiChip-deleteIcon": {
                      color: `${getHexaColor(dataItem?.tag)}`,
                    },
                  }}
                  label={dataItem?.tag}
                />
              );
            })}
            {params?.row?.tagsList?.length > 2 && (
              <div
                style={{
                  borderRadius: 8,
                  border: `1px solid ${paletteLightTheme.palette.border.main}`,
                  padding: 2,
                }}
              >{`+${params?.row?.tagsList?.length - 2}`}</div>
            )}
          </div>
        );
      },
    },
    {
      ...extraColumnsField("Assigned", "leadAssignedToStr", 150, 1),
      valueGetter: (params) => params.row.leadAssignedToStr || "-",
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (props) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setItemId(props?.row?.id);
                setCardActionAnchor(event.currentTarget);
              }}
            >
              <BsThreeDotsVertical size={16} />
            </IconButton>
          </Grid2>
        );
      },
    },
  ];

  return (
    <Grid2 container>
      {isLeadStatus ? (
        <Grid2 size={12}>
          <LeadStatusPage
            isLeadStatus={isLeadStatus}
            setIsLeadStatus={setIsLeadStatus}
          />
        </Grid2>
      ) : (
        <Grid2 size={12}>
          <GKDataGrid
            rows={getAllLead?.data || []}
            columns={columns}
            loading={isLeadLoading}
            onRowClick={(props) => {
              navigate(`/app/lead/detail/${props?.row?.id}/summary`);
            }}
            totalCount={getAllLead?.totalCount || 0}
            name="lead"
            headerComponent={
              <Grid2 container justifyContent={"flex-end"} gap={1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 8,
                    gap: 4,
                    padding: 4,
                    border: `1px solid ${paletteLightTheme.palette.border.main}`,
                  }}
                >
                  <IconButton
                    sx={{
                      background: !isLeadStatus
                        ? theme.palette.light.main
                        : paletteLightTheme.palette.primary.main,
                      color: !isLeadStatus
                        ? theme.palette.secondaryText.main
                        : paletteLightTheme.palette.light.main,
                      borderRadius: 2,
                      ":hover": {
                        background: !isLeadStatus
                          ? theme.palette.light.main
                          : paletteLightTheme.palette.primary.main,
                        color: !isLeadStatus
                          ? theme.palette.secondaryText.main
                          : paletteLightTheme.palette.light.main,
                      },
                    }}
                    onClick={() => {
                      setIsLeadStatus(!isLeadStatus);
                      refetchQuery("GET_ALL_LEADS");
                    }}
                  >
                    <TbLayoutKanban />
                  </IconButton>
                  <IconButton
                    sx={{
                      background: isLeadStatus
                        ? theme.palette.light.main
                        : paletteLightTheme.palette.primary.main,
                      color: isLeadStatus
                        ? theme.palette.secondaryText.main
                        : paletteLightTheme.palette.light.main,
                      borderRadius: 2,
                      ":hover": {
                        background: isLeadStatus
                          ? theme.palette.light.main
                          : paletteLightTheme.palette.primary.main,
                        color: isLeadStatus
                          ? theme.palette.secondaryText.main
                          : paletteLightTheme.palette.light.main,
                      },
                    }}
                    onClick={() => {
                      setIsLeadStatus(!isLeadStatus);
                    }}
                  >
                    <LuTable2 />
                  </IconButton>
                </div>
                <GKButton
                  id="add-new-lead-button"
                  variant="contained"
                  size="medium"
                  startIcon={<CgMathPlus size={14} />}
                  onClick={() => setLeadModalOpen(true)}
                >
                  Add New Lead
                </GKButton>
                <GKButton
                  color="dark"
                  size="medium"
                  variant="outlined"
                  startIcon={<CgMathPlus size={14} />}
                  onClick={() => setAddStuatusModal(true)}
                >
                  Add New Status
                </GKButton>
              </Grid2>
            }
            exportFile={{
              path: "/lead/all/",
              exportName: "LeadList",
            }}
          />
        </Grid2>
      )}

      {leadModalOpen && (
        <CreateLeadModal open={leadModalOpen} setOpen={setLeadModalOpen} />
      )}

      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          title="Lead"
          onDelete={() => deleteLead(itemId)}
          isDelete
        />
      )}

      <Popover
        open={Boolean(cardActionAnchor)}
        anchorEl={cardActionAnchor}
        onClose={() => {
          setCardActionAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "13%", borderRadius: 8 },
        }}
      >
        <Grid2 container sx={{ py: "6px" }}>
          <Grid2
            size={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              onClick={() => {
                handledConvertLead(itemId);
              }}
              startIcon={
                <LuUserPlus
                  size={16}
                  color={theme.palette.secondaryText.main}
                />
              }
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
            >
              Convert to Client
            </GKButton>
          </Grid2>
          <Grid2
            size={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              startIcon={
                <LuCalendarClock
                  size={16}
                  color={theme.palette.secondaryText.main}
                />
              }
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setMeetingModal(true);
                setIsScheduleMeeting(false);
                setCardActionAnchor(null);
              }}
            >
              Add Meeting
            </GKButton>
          </Grid2>
          <Grid2
            size={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              startIcon={
                <LuStickyNote
                  size={16}
                  color={theme.palette.secondaryText.main}
                />
              }
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setOpenNoteModal(true);
                setCardActionAnchor(null);
              }}
            >
              Add Notes
            </GKButton>
          </Grid2>
          <Grid2 size={12}>
            <GKButton
              onClick={() => {
                handledDeleteLead(itemId);
              }}
              startIcon={
                <RiDeleteBinLine
                  size={16}
                  color={theme.palette.secondaryText.main}
                />
              }
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
            >
              Delete
            </GKButton>
          </Grid2>
        </Grid2>
      </Popover>

      {updateModal && (
        <UpdateLead setOpen={setUpdateModal} open={updateModal} id={itemId} />
      )}

      <Show.When isTrue={meetingModal}>
        <AddNewMeeting
          open={meetingModal}
          setOpen={setMeetingModal}
          leadId={itemId}
          meetingData={null}
          refetch={refetch}
          schedule={isScheduleMeeting}
        />
      </Show.When>

      <Show.When isTrue={openNoteModal}>
        <AddNewNotes
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          leadId={itemId}
          refetch={refetch}
          noteData={null}
        />
      </Show.When>

      {addStatusModal && (
        <AddStatusAndSouceModal
          open={addStatusModal}
          setOpen={setAddStuatusModal}
          type={"STATUS"}
        />
      )}
    </Grid2>
  );
};

export default Lead;
