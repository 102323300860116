import { useAppContext } from "AppContext";
import {
  Avatar,
  Divider,
  FiTrash2,
  FormControl,
  GKButton,
  GKCheckbox,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  PhoneInput,
  styled,
  TextField,
  Typography,
} from "components";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BANK_ACCOUNT_TYPES } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const EssentialPageForm = ({ handleUpdate }: any) => {
  const form = useFormContext();

  const [hasError, setHasError] = useState(false);

  const watchBilling = form.watch("sameBillingChecked");
  const { theme } = ThemeContainer();

  const {
    state: { userData, countryList, stateList, cityList },
    dispatch,
  } = useAppContext();

  const [profilePicPreview, setProfilePicPreview] = useState<string | null>(
    null,
  );

  const handleProfilePicChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      form.setValue("logo", [file]);

      const formData = new FormData();
      formData.append("logo", file);
      handleUpdate(formData);

      event.target.value = "";
    }
  };

  const handleProfilePicDelete = () => {
    const formData = new FormData();
    formData.append("logo", "");
    handleUpdate(formData);
    setProfilePicPreview(null);
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Company Details
        </Typography>
      </Grid2>
      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid2
          container
          size={12}
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          <Grid2>
            <Avatar
              alt=""
              src={profilePicPreview || userData?.companyLogo}
              sx={{ width: 72, height: 72, border: "1px solid #eee" }}
            />
          </Grid2>
          <Grid2 ml={1}>
            <GKButton
              variant="contained"
              component="label"
              role={undefined}
              tabIndex={-1}
            >
              Upload Company Logo{" "}
              <VisuallyHiddenInput
                type="file"
                accept={".jpg, .png, .svg"}
                onChange={handleProfilePicChange}
              />
            </GKButton>
          </Grid2>
          <Grid2>
            <IconButton
              sx={{ border: "1px solid #CDD5DF", borderRadius: "8px", p: 1 }}
              onClick={handleProfilePicDelete}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Grid2>
        </Grid2>

        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="name"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Company Name"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="phoneNumber"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  requiredField
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("countryCode", val?.callingCodes);
                  }}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  onTextValue={field.value}
                  onSelectValue={form.getValues("countryCode")}
                  error={!!error || hasError}
                  helperText={error?.message}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={4}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="panCard"
              control={form.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <GKTextField
                    {...field}
                    inputLabel="PAN"
                    requiredField
                    error={!!error}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                    helperText={!!error && error?.message}
                  />
                );
              }}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={4}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="gst"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="GSTN"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={4}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="sebiNumber"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="SEBI Registration Number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
      </Grid2>

      <Grid2 size={12} my={1}>
        <Divider />
      </Grid2>

      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Contact Details
        </Typography>
      </Grid2>

      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="primaryContactName"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Contact Name"
                  requiredField
                />
              )}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="primaryContactNumber"
              control={form.control}
              render={({ field }) => (
                <PhoneInput
                  inputLabel="Phone Number"
                  requiredField
                  onSelectValue={form.getValues("primaryCountryCode")}
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("primaryCountryCode", val?.callingCodes);
                  }}
                  onTextValue={field.value}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  error={hasError}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="primaryContactEmail"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="Contact Email"
                  placeholder="Contact Email"
                  requiredField
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="whatsappNumber"
              control={form.control}
              render={({ field }) => (
                <PhoneInput
                  requiredField
                  inputLabel="WhatsApp Number"
                  onSelectValue={form.getValues("whatsappCountryCode")}
                  onSelectChange={(event: any, val: any) => {
                    form.setValue("whatsappCountryCode", val?.callingCodes);
                  }}
                  onTextValue={field.value}
                  onTextChange={(event: any) => {
                    field.onChange(event.target.value);
                  }}
                  hasError={hasError}
                  setHasError={setHasError}
                />
              )}
            />
          </FormControl>
        </Grid2>
      </Grid2>

      <Grid2 size={12} my={1}>
        <Divider />
      </Grid2>

      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Billing Details
        </Typography>
      </Grid2>

      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid2 size={12}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="address"
              control={form.control}
              rules={{
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Address"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
        {/* <Grid2 size={3}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="city"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="City"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={3}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="state"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="State"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={3}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="country"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Country"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2> */}
        <Grid2 size={3}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="pincode"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Pincode"
                  requiredField
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={3}>
          <Controller
            name="country"
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                {...field}
                inputLabel="Country"
                renderInput={(er) => (
                  <TextField
                    {...er}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: `Select Country`,
                      value: field.value,
                    })}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                options={countryList || []}
                getOptionLabel={(e) => e.name || e}
                onChange={(e, val) => {
                  field.onChange(val.name);
                  form.setValue("city", "");
                  form.setValue("state", "");
                  dispatch({
                    type: "SELECTED_COUNTRY",
                    data: val.id,
                  });
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 size={3}>
          <Controller
            name="state"
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                {...field}
                inputLabel="State"
                renderInput={(er) => (
                  <TextField
                    {...er}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select State",
                      value: field.value,
                    })}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                options={stateList || []}
                getOptionLabel={(e) => e.name || e}
                onChange={(e, val) => {
                  field.onChange(val.name);
                  form.setValue("city", "");
                  dispatch({
                    type: "SELECTED_STATE",
                    data: val.id,
                  });
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 size={3}>
          <Controller
            name="city"
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                {...field}
                inputLabel="City"
                renderInput={(er) => (
                  <TextField
                    {...er}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select City",
                      value: field.value,
                    })}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                options={cityList || []}
                getOptionLabel={(e) => e.name || e}
                onChange={(e, val) => {
                  field.onChange(val.name);
                  dispatch({
                    type: "SELECTED_CITY",
                    data: val.id,
                  });
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 size={12} display={"flex"} alignItems={"center"}>
          <Typography
            fontSize={14}
            color={theme.palette.secondaryText.main}
            fontWeight={500}
          >
            Correspondence Address
          </Typography>
          <Controller
            name="sameBillingChecked"
            control={form.control}
            render={({ field }) => (
              <GKCheckbox
                {...field}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  if (e.target.checked) {
                    form.setValue("corrAddress", form.getValues("address"));
                    form.setValue("corrPincode", form.getValues("pincode"));
                    form.setValue("corrState", form.getValues("state"));
                    form.setValue("corrCountry", form.getValues("country"));
                    form.setValue("corrCity", form.getValues("city"));
                  } else {
                    form.setValue("corrAddress", "");
                    form.setValue("corrPincode", "");
                    form.setValue("corrState", "");
                    form.setValue("corrCountry", "");
                    form.setValue("corrCity", "");
                  }
                }}
              />
            )}
          />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              color: theme.palette.secondaryText.main,
            }}
          >
            Same as Billing Address
          </Typography>
        </Grid2>

        {!watchBilling && (
          <>
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="corrAddress"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Address" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="corrCity"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="City" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="corrState"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="State" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="corrCountry"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Country" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="corrPincode"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Pincode" />
                  )}
                />
              </FormControl>
            </Grid2>
          </>
        )}
      </Grid2>

      <Grid2 size={12} my={1}>
        <Divider />
      </Grid2>

      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Bank Details
        </Typography>
      </Grid2>

      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid2 size={4}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="accountName"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Account Name" />
              )}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={4}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="accountNumber"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  type="number"
                  inputLabel="Account Number"
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={4}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="isfcCode"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="IFSC Code" />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <Controller
            name="accountType"
            control={form.control}
            render={({ field }) => (
              <GKSearchSelect
                {...field}
                disableClearable={false}
                inputLabel="Account Type"
                options={BANK_ACCOUNT_TYPES || []}
                onChange={(e, val) => {
                  field.onChange(val?.value);
                }}
                value={
                  BANK_ACCOUNT_TYPES?.find(
                    (option: any) => field.value === option.value,
                  )?.name || []
                }
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Account Type",
                      value: field.value,
                    })}
                  />
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="bank"
              control={form.control}
              render={({ field }) => (
                <GKTextField {...field} inputLabel="Bank Name" />
              )}
            />
          </FormControl>
        </Grid2>
      </Grid2>

      <Grid2 size={12} my={1}>
        <Divider />
      </Grid2>

      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Social Information
        </Typography>
      </Grid2>

      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="facebook"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Facebook"
                  error={Boolean(errors?.facebook)}
                  helperText={errors?.facebook?.message as string}
                />
              )}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="twitter"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Twitter"
                  error={Boolean(errors?.twitter)}
                  helperText={errors?.twitter?.message as string}
                />
              )}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="linkedin"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="LinkedIn"
                  error={Boolean(errors?.linkedin)}
                  helperText={errors?.linkedin?.message as string}
                />
              )}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="instagram"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Instagram"
                  error={Boolean(errors?.instagram)}
                  helperText={errors?.instagram?.message as string}
                />
              )}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="youtube"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Youtube"
                  error={Boolean(errors?.youtube)}
                  helperText={errors?.youtube?.message as string}
                />
              )}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="website"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKTextField
                  {...field}
                  inputLabel="Website"
                  error={Boolean(errors?.website)}
                  helperText={errors?.website?.message as string}
                />
              )}
            />
          </FormControl>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default EssentialPageForm;
