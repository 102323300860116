import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  FormControl,
  GKTextField,
  Grid2,
  IconButton,
  IoEyeOffOutline,
  IoMdEye,
  SimpleLoader,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";

interface FormValues {
  username: string;
  poscode: string;
  password: string;
  aesKey: string;
  apiKey: string;
}

const KYCRegistrationAgency = () => {
  const {
    state: { userData },
  } = useAppContext();

  const [showPassword, setShowPassword] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      username: "",
      poscode: "",
      password: "",
      aesKey: "",
      apiKey: "",
    },
  });

  const { isFetching } = useFetchQuery({
    key: ["GET_COMPANY_KRA_SETTINGS", userData?.companyId],
    route: `company/${userData?.companyId}/kra-configurations/`,
    success: (res: { data: { data: FormValues } }) => {
      form.reset(res.data.data);
    },
  });

  const { mutate } = useMutation({
    mutationFn: (data: FormValues) =>
      axiosInstance.patch(
        `company/${userData?.companyId}/kra-configurations/`,
        data,
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_COMPANY_KRA_SETTINGS");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const handleFormSubmit = (values: FormValues) => {
    mutate(values);
  };

  return isFetching ? (
    <SimpleLoader />
  ) : (
    <form onSubmit={form.handleSubmit(handleFormSubmit)}>
      <Grid2 container spacing={2}>
        <Grid2 size={4}>
          <Controller
            name="username"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter Name",
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth variant="standard">
                  <GKTextField
                    {...field}
                    inputLabel={"Name"}
                    error={!!fieldState.error}
                    helperText={!!fieldState.error && fieldState.error.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="poscode"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter Poscode",
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth variant="standard">
                  <GKTextField
                    {...field}
                    inputLabel={"Poscode"}
                    error={!!fieldState.error}
                    helperText={!!fieldState.error && fieldState.error.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="password"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter Password",
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth variant="standard">
                  <GKTextField
                    {...field}
                    inputLabel={"Password"}
                    type={showPassword ? "text" : "password"}
                    slotProps={{
                      input: {
                        style: {
                          paddingRight: 12,
                        },
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            style={{
                              padding: 0,
                            }}
                          >
                            {!showPassword ? <IoMdEye /> : <IoEyeOffOutline />}
                          </IconButton>
                        ),
                      },
                    }}
                    error={!!fieldState.error}
                    helperText={!!fieldState.error && fieldState.error.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="aesKey"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter AES",
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth variant="standard">
                  <GKTextField
                    {...field}
                    inputLabel={"AES Key"}
                    error={!!fieldState.error}
                    helperText={!!fieldState.error && fieldState.error.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="apiKey"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter API",
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth variant="standard">
                  <GKTextField
                    {...field}
                    inputLabel={"API Key"}
                    error={!!fieldState.error}
                    helperText={!!fieldState.error && fieldState.error.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid2>
        <Grid2 size={12} textAlign={"right"}>
          <LoadingButton variant="contained" type="submit">
            Submit
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default KYCRegistrationAgency;
