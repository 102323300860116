import { ThemeProvider } from "@emotion/react";
import { LicenseInfo } from "@mui/x-license";
import { QueryClientProvider } from "@tanstack/react-query";
import { AppContextProvider } from "AppContext";
import queryClient from "client";
import {
  BiSolidErrorAlt,
  GKButton,
  GKModal,
  GKTypography,
  Grid2,
  HiBadgeCheck,
  IconButton,
  MdClose,
  Show,
} from "components";
import { useEffect, useState } from "react";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { productRoutes } from "routes";
import { ThemeContainer } from "utils/Theme";
import { currentVersion } from "utils/constants";
import "./index.css";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const Main = () => {
  const location = useLocation();

  const subDomainName = window.location.host.split(".");

  const navigate = useNavigate();

  const { theme } = ThemeContainer();

  const [openVersionModal, setOpenVersionModal] = useState(false);

  useEffect(() => {
    const getVersion = localStorage.getItem("product-version");
    const token = localStorage.getItem("token");

    if ((!getVersion && token) || (getVersion !== currentVersion && token)) {
      setOpenVersionModal(true);
    }

    if (location?.pathname === "/login" && token) {
      navigate("/app/holdings");
    }
  }, [location]);

  useEffect(() => {
    if (location?.pathname === "/") {
      navigate("/home");
    }
  }, [location?.pathname, navigate]);

  useEffect(() => {
    if (
      subDomainName?.length === (window.location.host.includes("stage") ? 4 : 3)
    ) {
      localStorage.setItem("subdomain", subDomainName[0]);
    } else {
      localStorage.removeItem("subdomain");
    }
  }, [subDomainName]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <ThemeProvider theme={theme}>
            <Routes>
              {productRoutes?.map((dataItem) => (
                <Route
                  key={dataItem?.path}
                  path={dataItem?.path}
                  element={dataItem?.element}
                >
                  {dataItem?.child?.map((childItem) => (
                    <Route
                      key={childItem?.path}
                      path={childItem?.path}
                      element={childItem?.element}
                    />
                  ))}
                </Route>
              ))}
            </Routes>
          </ThemeProvider>
        </AppContextProvider>
      </QueryClientProvider>

      <Show.When isTrue={openVersionModal}>
        <GKModal
          modalTitle={"Version Control"}
          open={openVersionModal}
          setOpen={setOpenVersionModal}
          closeBtn={false}
          footer={
            <Grid2
              container
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GKButton
                variant="outlined"
                color="error"
                onClick={() => {
                  localStorage.removeItem(`token`);
                  localStorage.removeItem(`isInvestor`);
                  localStorage.removeItem(`isAdvisor`);
                  localStorage.removeItem(`userInfo`);
                  localStorage.removeItem(`version`);
                  localStorage.removeItem("breadcumbs");
                  localStorage.removeItem("mode");
                  localStorage.removeItem("role");
                  localStorage.removeItem("columns");
                  window.location.reload();
                  setOpenVersionModal(false);
                }}
              >
                Logout
              </GKButton>
            </Grid2>
          }
        >
          <GKTypography
            fontSize={18}
            fontWeight={500}
            color={theme.palette.primary.main}
            textAlign={"center"}
          >
            We have updated our systems for a better experience, please re-login
            into your account.
          </GKTypography>
        </GKModal>
      </Show.When>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          duration: 6000,
          style: {
            boxShadow: `0 0 10px 2px ${theme.palette.border.main}`,
            fontWeight: 700,
            padding: "10px 10px",
            fontFamily: "Inter",
            maxWidth: "unset",
            fontSize: 12,
          },
          success: {
            icon: (
              <HiBadgeCheck size={"26px"} color={theme.palette.success.main} />
            ),
          },
          error: {
            icon: (
              <BiSolidErrorAlt size={"26px"} color={theme.palette.error.main} />
            ),
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t} position="bottom-right">
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                <Show.When isTrue={t.type !== "loading"}>
                  <IconButton
                    style={{
                      padding: 4,
                    }}
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <MdClose size={16} />
                  </IconButton>
                </Show.When>
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </>
  );
};
export default Main;
