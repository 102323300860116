import { GridColDef } from "@mui/x-data-grid-pro";
import { GKDataGrid, Grid2, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { PORTFOLIO_FEE_PLAN_MAPPING } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";

const PortfoliosTab = () => {
  const { id } = useParams();

  const requestBody = useGenerateQueryParams("feePlanPortfolioMapping", {
    fee_plan: id,
  });

  const {
    data: portfolioFeePlanMappingList,
    isFetching: portfolioMappingFetching,
  } = useFetchQuery({
    key: ["PORTFOLIO_FEE_PLAN_MAPPING_LIST", requestBody],
    route: PORTFOLIO_FEE_PLAN_MAPPING,
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio Name", "clientName", 200, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Fee Plan", "feePlan", 200, 1),
      renderCell: (param) => {
        return <Typography>{param?.row?.feePlan?.name || "-"}</Typography>;
      },
    },
    {
      ...extraColumnsField("Start Date", "startDate", 100, 1),
      type: "date",
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Total Fee Collected", "totalFeeCollected", 150, 1),
      disableColumnMenu: true,
      valueGetter: (row) => row?.row?.totalFeeCollected,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Total Billed Generated", "feeGenerated", 150, 1),
      valueGetter: (row) => row?.row?.feeGenerated,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Partner", "partner", 150, 1),
    },
  ];
  return (
    <GKDataGrid
      loading={portfolioMappingFetching}
      columns={columns}
      rows={portfolioFeePlanMappingList?.data?.data || []}
      maxHeight={"calc(100vh - 306px)"}
      totalCount={portfolioFeePlanMappingList?.data?.totalCount || 0}
      name="feePlanPortfolioMapping"
    />
  );
};

export default PortfoliosTab;
