export const COMPANY_CAP = [
  { name: "Large", value: "Large" },
  { name: "Mid", value: "Mid" },
  { name: "Small", value: "Small" },
  { name: "NA", value: "NA" },
];

export const COMPANY_STATUS = [
  { name: "ACTIVE", value: "ACTIVE" },
  { name: "INACTIVE", value: "INACTIVE" },
  { name: "SUSPENDED", value: "SUSPENDED" },
];

export const INSTRUMENT_SUB_TYPE = [
  { value: "CE", name: "Cash Equivalent" },
  { value: "NCE", name: "Non Cash Equivalent" },
];

export const FREQUENCY_DROPDOWN = [
  { value: "Monthly", name: "Monthly" },
  { value: "Quarterly", name: "Quarterly" },
  { value: "Half Yearly", name: "Half Yearly" },
  { value: "Yearly", name: "Yearly" },
];

export interface CompanyGetByIdInterface {
  status: boolean;
  message: string;
  data: CompanyData;
}

export interface CompanyData {
  id: number;
  gcode: string;
  company: string;
  cmp: number;
  cmpDate: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  isDeleted: boolean;
  instType: string;
  status: string;
  name: string;
  instSubType: string;
  isGlobal: boolean;
  shortName: string;
  cap: string;
  industry: string;
  sector: string;
  house: string;
  startingDate: string;
  endingDate: any;
  otherAssetMeta: {
    tenure: string;
    frequency: string;
    interestRate: string;
  };
}

export const CORPORATE_ACTION_TYPE = [
  { name: "Dividend", value: "DIV" },
  { name: "Split", value: "SPL" },
  { name: "Bonus", value: "BNS" },
  { name: "Merger", value: "MRG" },
  { name: "Demerger", value: "DMG" },
  { name: "Reverse Split", value: "RVS" },
  { name: "Right Issue", value: "RIGHTS" },
  { name: "REC", value: "REC" },
];
