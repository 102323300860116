/* eslint-disable new-cap */
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  FormControl,
  GKOverlayLoader,
  Grid2,
  PhoneInput,
  Typography,
} from "components";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { ThemeContainer } from "utils/Theme";
import SmallCaseOrderTable from "./SmallCaseOrderTable";

declare global {
  interface Window {
    scDK: any;
  }
}

interface Props {
  orderId: any;
  token: string;
}

const SmallCaseOrder = ({ orderId, token }: Props) => {
  const location = useLocation();

  const [hasError, setHasError] = useState(false);

  const { theme } = ThemeContainer();

  const [gatewayInstance, setGatewayInstance] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(undefined);

  const phoneNumber = new URLSearchParams(location.search).get("userPhone");
  const portfolioId = new URLSearchParams(location.search).get("portfolio_id");
  const logo = location.search.split("logo=")[1];

  const form = useForm({
    defaultValues: {
      phoneNumber: phoneNumber || "",
      countryCode: "+91",
      portfolioId: portfolioId,
      orderId: orderId,
    },
  });

  form.watch();

  const {
    data,
    mutate: verifyPhoneNumber,
    isLoading,
  } = useMutation({
    mutationKey: ["VERIFY_PHONE_NUMBER"],
    mutationFn: (data: any) =>
      axiosInstance.post("/smallcase-trade/verify/", data, {
        headers: { Authorization: `Token ${token}` },
      }),
    onSuccess: (response) => {
      const instance = new window.scDK({
        gateway: response.data.data?.gatewayName || "gridkey",
        smallcaseAuthToken: response.data.data?.jwtAuth || "",
        config: {
          amo: true,
        },
      });
      setGatewayInstance(instance);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: transactionDetails } = useMutation({
    mutationKey: ["TRANSACTION_DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.post(`/smallcase-trade/transaction-details-add/`, data, {
        headers: { Authorization: `Token ${token}` },
      }),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setShow(true);
      setShowErrorMessage(true);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: transactionErrorDetails } = useMutation({
    mutationKey: ["TRANSACTION_ERROR,DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.post(`/smallcase-trade/transaction-details-error/`, data, {
        headers: { Authorization: `Token ${token}` },
      }),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setShow(true);
      setShowErrorMessage(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: orderTradeAuth, isLoading: orderTradeAuthLoading } =
    useMutation({
      mutationKey: ["ORDER_TRADE_AUTH"],
      mutationFn: (data: any) =>
        axiosInstance.post("/smallcase-trade/order-trade-auth/", data, {
          headers: { Authorization: `Token ${token}` },
        }),
      onSuccess: (response) => {
        if (gatewayInstance) {
          gatewayInstance
            .triggerTransaction({
              transactionId: response?.data?.data?.transactionId,
            })
            .then((response: any) => transactionDetails(response))
            .catch((error: any) => {
              if (error.message === "user_mismatch") {
                gatewayInstance.brokerLogout();
              } else {
                transactionErrorDetails({
                  error_code: error.message,
                  order_id: orderId,
                  transactionId: response?.data?.data?.transactionId,
                });
              }
            });
        } else {
          toast.error("Gateway instance not available yet.");
        }
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  useEffect(() => {
    if (phoneNumber) {
      verifyPhoneNumber(form.getValues());
    }
  }, [phoneNumber]);

  return (
    <>
      {isLoading && <GKOverlayLoader />}
      {!data?.data?.data?.order ? (
        <Grid2
          container
          style={{
            width: "100vw",
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.light.main,
          }}
          boxShadow={`inset 0 0 63px -26px #63A387, inset 0 0 63px -16px #00566e`}
          p={{
            xs: 2,
            md: 10,
            lg: 20,
          }}
          px={{
            xs: 10,
            md: 40,
            lg: 50,
          }}
        >
          {!data?.data?.data?.order && (
            <Grid2 container spacing={2}>
              <Grid2
                size={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={1}
              >
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = "/Logos/errorImg.svg";
                  }}
                  src={logo}
                  alt="brokerImage"
                  style={{
                    maxWidth: "120px",
                  }}
                />
                {/* {!data?.data?.data?.order && (
                  <Typography fontWeight={600}>Verify your number</Typography>
                )} */}
              </Grid2>

              {!data?.data?.data?.order && (
                <Grid2 size={12}>
                  <form
                    id="small-case-order-form"
                    onSubmit={form.handleSubmit((values) =>
                      verifyPhoneNumber(values),
                    )}
                  >
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="phoneNumber"
                        control={form.control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please Enter Phone Number",
                          },
                        }}
                        render={({ field, formState: { errors } }) => (
                          <PhoneInput
                            {...field}
                            requiredField
                            inputLabel={
                              <Typography fontSize={18} fontWeight={500}>
                                Verify your number
                              </Typography>
                            }
                            placeholder="Verify your number"
                            onSelectChange={(event: any, val: any) => {
                              form.setValue("countryCode", val?.callingCodes);
                            }}
                            onTextChange={(event: any) =>
                              field.onChange(event.target.value)
                            }
                            onTextValue={field.value}
                            onSelectValue={form.getValues("countryCode")}
                            error={Boolean(errors.phoneNumber) || hasError}
                            helperText={errors.phoneNumber?.message}
                            hasError={hasError}
                            setHasError={setHasError}
                          />
                        )}
                      />
                    </FormControl>
                  </form>
                </Grid2>
              )}

              <Grid2 size={12} textAlign={"right"}>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  size="medium"
                  form="small-case-order-form"
                >
                  Verify
                </LoadingButton>
              </Grid2>
            </Grid2>
          )}
        </Grid2>
      ) : (
        <SmallCaseOrderTable
          orders={data?.data?.data?.order}
          orderTradeAuth={orderTradeAuth}
          orderTradeAuthLoading={orderTradeAuthLoading}
          form={form}
          show={show}
          showErrorMessage={showErrorMessage}
        />
      )}
    </>
  );
};

export default SmallCaseOrder;
