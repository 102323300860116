import { GridColDef } from "@mui/x-data-grid-pro";
import AutoImportModalParent from "Pages/AutoImport/AutoImportModalParent";
import ManualEntryModal from "Pages/UploadManager/ManualEntryModal";
import UploadManagerModal from "Pages/UploadManager/UploadManagerModal";
import UploadManagerViewUpload from "Pages/UploadManager/UploadManagerViewUpload";
import UploadPopOverActions from "Pages/UploadManager/UploadPopOverActions";
import {
  GKButton,
  GKDataGrid,
  Show,
  Skeleton,
  StatusField,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import { TableFiltering } from "utils/TableFilters";
import { CONTRACT_NOTE_PORTFOLIO } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import PortfolioMappingsTab from "./PortfolioMappingsTab";

const ContractNotes = () => {
  const { id } = useParams();

  const [openUploadManagerModal, setOpenUploadManagerModal] = useState(false);

  const [loadingStates, updateLoadingState] = useReducer(
    (oldPath: any, newStates: any) => ({
      ...oldPath,
      ...newStates,
    }),
    {
      isAutoImportLoading: false,
      isArchiveLoading: false,
      isViewFileLoading: false,
    },
  );

  const [isAutoImportModal, setIsAutoImportModal] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const [clickedButtonId, setClickedButtonId] = useState<number>();

  const [viewFileFormat, setViewFileFormat] = useState<string>();

  const [viewModalData, setViewModalData] = useState<any>();

  const [isManualEntryViewModal, setIsManualEntryViewModal] = useState(false);

  const [openMappingModal, setOpenMappingModal] = useState(false);

  const [manualEntryTransactionID, setManualEntryTransactionID] =
    useState<number>();

  const requestBody = useGenerateQueryParams("portfolioImportManager", {
    portfolio_id: id,
  });

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["PORTFOLIO_CONTRACT_NOTE", requestBody],
    route: CONTRACT_NOTE_PORTFOLIO,
    requestBody,
  });

  const handleViewFileClick = async (
    id: number,
    fileType: string,
    fileFormat?: string,
  ) => {
    updateLoadingState({ ...loadingStates, isViewFileLoading: true });
    await axiosInstance
      ?.post(`/transaction_upload/review/?id=${id}`)
      .then((reviewResponse) => {
        setViewModalData(reviewResponse?.data?.transactions);
        setClickedButtonId(id);
        setIsViewModalOpen(true);
        setViewFileFormat(fileFormat);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
    updateLoadingState({ ...loadingStates, isViewFileLoading: false });
  };

  const uploadManagerColumns: GridColDef[] = [
    {
      ...extraColumnsField("Uploaded Date", "uploadedOn", 130, 1),
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      ...extraColumnsField("File Name", "fileName", 250, 1),
      renderCell: (item) => {
        return loadingStates?.isViewFileLoading &&
          clickedButtonId === item?.row?.id ? (
          <Skeleton width={"100%"} animation="wave" />
        ) : (
          <div
            className="hover"
            role="presentation"
            onClick={() => {
              if (item?.row?.source === "Manual Entry") {
                setManualEntryTransactionID(item?.row?.id);
                setIsManualEntryViewModal(true);
              } else
                handleViewFileClick(
                  item?.row?.id,
                  item?.row?.source,
                  item?.row?.format,
                );
              setClickedButtonId(item?.row?.id);
            }}
          >
            <Typography
              fontWeight={600}
              style={{
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
            >
              {item?.row?.fileName}
            </Typography>
          </div>
        );
      },
    },

    {
      ...extraColumnsField("File Format", "brokerFormat", 150, 1),
      valueFormatter: (params) =>
        handleCapitalize(handleRemoveUnderscore(params?.value)),
    },
    {
      ...extraColumnsField("Upload Type", "source", 150, 1),
    },
    {
      ...extraColumnsField("Records", "remarks", 110, 1),
    },
    {
      ...extraColumnsField("Status", "status", 140, 1),
      renderCell: (item) => <StatusField statusString={item?.row?.status} />,
    },
    {
      ...extraColumnsField("Uploaded By", "userUploaded", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 90, 1),
      type: "action",
      align: "right",
      headerAlign: "right",
      renderCell: (item) => {
        return <UploadPopOverActions item={item.row} refetch={refetch} />;
      },
    },
  ];

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={uploadManagerColumns}
        rows={data?.data?.data || []}
        totalCount={data?.data?.totalCount || 0}
        name="portfolioImportManager"
        tableName="portfolio_import_manager_list"
        headerFilter={<TableFiltering name="portfolioImportManager" />}
        headerComponent={
          <GKButton
            onClick={() => {
              setOpenMappingModal(true);
            }}
            size="medium"
            variant="contained"
          >
            Mappings
          </GKButton>
        }
      />

      {isAutoImportModal && (
        <AutoImportModalParent
          isModalOpen={isAutoImportModal}
          setIsModalOpen={setIsAutoImportModal}
        />
      )}
      {isManualEntryViewModal && (
        <ManualEntryModal
          open={isManualEntryViewModal}
          setOpen={setIsManualEntryViewModal}
          transactionID={manualEntryTransactionID}
        />
      )}

      {isViewModalOpen && (
        <UploadManagerViewUpload
          open={isViewModalOpen}
          setOpen={setIsViewModalOpen}
          recordData={viewModalData}
          recordID={clickedButtonId}
          recordName={
            data?.data?.data?.find((item: any) => item?.id === clickedButtonId)
              ?.fileName
          }
          recordFormat={viewFileFormat}
        />
      )}

      {openUploadManagerModal && (
        <UploadManagerModal
          setOpen={setOpenUploadManagerModal}
          open={openUploadManagerModal}
        />
      )}

      <Show.When isTrue={openMappingModal}>
        <PortfolioMappingsTab
          open={openMappingModal}
          setOpen={setOpenMappingModal}
        />
      </Show.When>
    </>
  );
};

export default ContractNotes;
