import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  Divider,
  FormControl,
  GKSearchSelect,
  GKTextField,
  Grid2,
  PhoneInput,
  Show,
  TextField,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { PaymentGatewayFormValues } from "interfaces/PaymentGateway";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import {
  emailRegex,
  gstRegex,
  panRegex,
  validateNotWhitespaceOnly,
} from "utils/validationSchema";
import {
  FINANCIAL_SERVICES_SUBCATEGORIES,
  PAYMENT_GATEWAY_BUSINESS_TYPE,
  PAYMENT_GATEWAY_CATEGORIES,
  SERVICES_SUBCATEGORIES,
} from "./helper";
import VerifyAccountModal from "./VerifyAccountModal";

const ConfigurePaymentForm = () => {
  useDocumentTitle("Settings");

  const [hasError, setHasError] = useState(false);

  const { id } = useParams();

  const [verifyModal, setVerifyModal] = useState(false);

  const { data, isFetching } = useFetchQuery({
    key: ["GET_PAYMENT_GETEWAY_DETAILS_BY_ID"],
    route: `/razorpay-linked-account/${id}/`,
    enabled: !!id,
  });

  const disabled = !!id;

  const form = useForm<PaymentGatewayFormValues>({
    defaultValues: {
      name: "",
      email: "",
      countryCode: "+91",
      phone: "",
      ownerPan: "",
      businessPan: "",
      gst: "",
      legalBusinessName: "",
      businessType: "",
      category: "",
      subcategory: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      accountNumber: "",
      ifscCode: "",
      beneficiaryName: "",
      token: "",
    },
    values: { ...data?.data?.data, countryCode: "+91" },
  });

  form.watch();

  const { mutate: sendOtp, isLoading } = useMutation({
    mutationKey: ["PAYMENT_GATEWAY_SEND_OTP"],
    mutationFn: () => axiosInstance.post("/razorpay-otp/send/"),
    onSuccess: (response) => {
      setVerifyModal(true);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  return (
    <DashboardLayout title="Payment Configure" loading={isFetching}>
      <Typography fontSize={16} fontWeight={600}>
        Configure Payment Gateway
      </Typography>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(() => sendOtp())}
          style={{
            padding: 8,
          }}
        >
          <Grid2 container spacing={1} height={"100%"}>
            <Grid2 size={12} mb={1}>
              <Typography fontSize={14} fontWeight={600} mt={2}>
                Primary Details
              </Typography>
              <Divider />
            </Grid2>
            <Grid2 size={12} container spacing={1} display={"flex"}>
              <Grid2 size={3} display={"flex"}>
                <Controller
                  control={form.control}
                  name="name"
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter Name",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        requiredField
                        inputLabel="Name"
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  control={form.control}
                  name="email"
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter Email",
                    },
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        requiredField
                        inputLabel="Email"
                        error={Boolean(error)}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="phone"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter a valid Phone Number",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <PhoneInput
                        {...field}
                        requiredField
                        disabled={disabled}
                        onSelectChange={(event: any, val: any) => {
                          form.setValue("countryCode", val?.callingCodes);
                        }}
                        onTextChange={(event: any) => {
                          field.onChange(event.target.value);
                        }}
                        onTextValue={field.value}
                        onSelectValue={form.getValues("countryCode")}
                        error={!!error || hasError}
                        helperText={!!error && error?.message}
                        hasError={hasError}
                        setHasError={setHasError}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="ownerPan"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter PAN",
                    },
                    pattern: {
                      value: panRegex,
                      message: "PAN should have this format ABCDE1234A",
                    },
                    maxLength: {
                      value: 10,
                      message: "PAN should have 10 character",
                    },
                    minLength: {
                      value: 10,
                      message: "PAN should have 10 character",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        inputLabel="PAN"
                        placeholder="PAN"
                        requiredField
                        onChange={(event: any) =>
                          field.onChange(event.target.value.toUpperCase())
                        }
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
            </Grid2>
            <Grid2 size={12} container spacing={1}>
              <Grid2 size={12} mb={1}>
                <Typography fontSize={14} fontWeight={600} mt={2}>
                  Business Information
                </Typography>
                <Divider />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="businessPan"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter business PAN",
                    },
                    pattern: {
                      value: panRegex,
                      message: "PAN should have this format ABCDE1234A",
                    },
                    maxLength: {
                      value: 10,
                      message: "PAN should have 10 character",
                    },
                    minLength: {
                      value: 10,
                      message: "PAN should have 10 character",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        inputLabel="Business PAN"
                        requiredField
                        onChange={(event: any) =>
                          field.onChange(event.target.value.toUpperCase())
                        }
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  name="gst"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter business GST",
                    },
                    pattern: {
                      value: gstRegex,
                      message: "GST should have this format 27AAPFU0939F1ZV",
                    },
                    maxLength: {
                      value: 15,
                      message: "GST should have 15 character",
                    },
                    minLength: {
                      value: 15,
                      message: "GST should have 15 character",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        inputLabel="GSTN"
                        requiredField
                        onChange={(event: any) =>
                          field.onChange(event.target.value.toUpperCase())
                        }
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  control={form.control}
                  name="legalBusinessName"
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter legal business name",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        requiredField
                        inputLabel="Legal Business Name"
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  control={form.control}
                  name="beneficiaryName"
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter legal Beneficiary name",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        requiredField
                        inputLabel="Beneficiary Name"
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={4}>
                <Controller
                  name="businessType"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select business type",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      {...field}
                      requiredField
                      disabled={disabled}
                      disableClearable={false}
                      inputLabel="Business Type"
                      options={PAYMENT_GATEWAY_BUSINESS_TYPE || []}
                      value={
                        PAYMENT_GATEWAY_BUSINESS_TYPE.find(
                          (dataItem) => dataItem?.value === field?.value,
                        )?.name || ""
                      }
                      onChange={(event, val) => field.onChange(val?.value)}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Business Type",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={4}>
                <Controller
                  name="category"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select category",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      {...field}
                      disabled={disabled}
                      requiredField
                      disableClearable={false}
                      inputLabel="Category"
                      options={PAYMENT_GATEWAY_CATEGORIES || []}
                      value={
                        PAYMENT_GATEWAY_CATEGORIES.find(
                          (dataItem) => dataItem?.value === field?.value,
                        )?.name || ""
                      }
                      onChange={(event, val) => field.onChange(val?.value)}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Category",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={4}>
                <Controller
                  name="subcategory"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select subcategory",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      {...field}
                      disabled={disabled}
                      requiredField
                      disableClearable={false}
                      inputLabel="Subcategory"
                      options={
                        form.getValues("category") === "financial_services"
                          ? FINANCIAL_SERVICES_SUBCATEGORIES
                          : SERVICES_SUBCATEGORIES || []
                      }
                      value={
                        [
                          ...FINANCIAL_SERVICES_SUBCATEGORIES,
                          ...SERVICES_SUBCATEGORIES,
                        ].find((dataItem) => dataItem?.value === field?.value)
                          ?.name || ""
                      }
                      onChange={(event, val) => field.onChange(val?.value)}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Subcategory",
                            value: field.value,
                          })}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
            </Grid2>
            <Grid2 size={12} container spacing={1}>
              <Grid2 size={12} my={1} mb={1}>
                <Typography fontSize={14} fontWeight={600} mt={2}>
                  Business Address
                </Typography>
                <Divider />
              </Grid2>
              <Grid2 size={12}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="street1"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter address",
                      },
                      validate: validateNotWhitespaceOnly,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        requiredField
                        inputLabel="Address 1"
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={12}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="street2"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter address 2",
                      },
                      validate: validateNotWhitespaceOnly,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        requiredField
                        inputLabel="Address 2"
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="country"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select country",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        inputLabel="Country"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="state"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select state",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        inputLabel="State"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="city"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select city",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        inputLabel="City"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="postalCode"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter postalCode",
                      },
                      validate: validateNotWhitespaceOnly,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        inputLabel="Pincode"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
            </Grid2>
            <Grid2 size={12} container spacing={1}>
              <Grid2 size={12} my={1} mb={1}>
                <Typography fontSize={14} fontWeight={600} mt={2}>
                  Bank Details
                </Typography>
                <Divider />
              </Grid2>
              <Grid2 size={6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="accountNumber"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter account number",
                      },
                      validate: validateNotWhitespaceOnly,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        requiredField
                        type="number"
                        inputLabel="Account Number"
                        error={!!error}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                        helperText={error?.message}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="ifscCode"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter IFSC code",
                      },
                      validate: validateNotWhitespaceOnly,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        requiredField
                        inputLabel="IFSC Code"
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{ htmlInput: { readOnly: disabled } }}
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
            </Grid2>

            {!id && (
              <Grid2
                size={12}
                sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
              >
                <LoadingButton
                  variant="contained"
                  loading={isLoading}
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </Grid2>
            )}
          </Grid2>
        </form>

        <Show.When isTrue={verifyModal}>
          <VerifyAccountModal open={verifyModal} setOpen={setVerifyModal} />
        </Show.When>
      </FormProvider>
    </DashboardLayout>
  );
};

export default ConfigurePaymentForm;
