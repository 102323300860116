import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  GKButton,
  GKDataGrid,
  Grid2,
  Show,
  Tooltip,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import CashTransactionModal from "Pages/CashManagement/CashTransactionModal";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import { CASH_TRANSACTION_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { returnParticularColor } from "utils/constants";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { QueryKeys } from "utils/queryKey";
import { TableFiltering } from "utils/TableFilters";

const CashEntry = () => {
  const { id } = useParams();

  const [openCashModal, setOpenCashModal] = useState(false);
  const [cashEntryData, setCashEntryData] = useState<any>({});
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const requestBody = useGenerateQueryParams("portfolioCashEntry");

  const { data, isFetching, refetch } = useFetchQuery({
    route: CASH_TRANSACTION_BY_PORTFOLIO_ID(id),
    key: [QueryKeys.PORTFOLIO_CASH_TRANSACTIONS_LIST, requestBody],
    requestBody,
  });

  const cashManagementMainPageColumns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 150, 0),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioCode}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Particulars", "expenseIncome", 200, 0),
      renderCell: (params) => {
        return (
          <Typography
            fontWeight={600}
            style={{
              width: "fit-content",
              background: `${returnParticularColor(
                params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                  ?.addedField || params?.row?.transactionType,
              )}33`,
              color: returnParticularColor(
                params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                  ?.addedField || params?.row?.transactionType,
              ),
              padding: "4px 8px",
              borderRadius: 6,
            }}
            textTransform={"capitalize"}
          >
            {params?.row?.transactionType?.toLowerCase() === "sell" ||
            params?.row?.transactionType?.toLowerCase() === "buy"
              ? `BILLAMOUNT - ${params?.row?.transactionType}`
              : params?.row?.transactionType}
            &nbsp;
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ? `/ ${handleRemoveUnderscore(
                    params?.row[
                      `${params?.row?.transactionType?.toLowerCase()}`
                    ]?.addedField,
                  )}`
                : ""}
            </span>
          </Typography>
        );
      },
    },
    // {
    //   flex: 1,
    //   disableColumnMenu: true,
    //   headerName: "Debit",
    //   minWidth: 150,
    //   field: "debit",
    //   align: "right",
    //   headerAlign: "right",
    //   valueGetter: (params) => params.row.debit || "0",
    //   valueFormatter: (params) => moneyCommaSeparator(params.value),
    // },
    // {
    //   flex: 1,
    //   disableColumnMenu: true,
    //   headerName: "Credit",
    //   minWidth: 150,
    //   field: "credit",
    //   align: "right",
    //   headerAlign: "right",
    //   valueGetter: (params) => params.row.credit || "0",
    //   valueFormatter: (params) => moneyCommaSeparator(params.value),
    // },
    {
      ...extraColumnsField("Amount", "billAmount", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.billAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Remark", "remarks", 150, 1),
      renderCell: (params) => {
        const { remarks } = params?.row;
        return (
          <Tooltip title={remarks} arrow placement="left-start">
            <Typography
              textOverflow={"ellipsis"}
              width={"80%"}
              overflow={"hidden"}
            >
              {remarks ? `${remarks}...` : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const { mutate: handleMultiDelete, isLoading: deleteLoading } = useMutation({
    mutationFn: (ids: any) =>
      axiosInstance.delete(`/transaction_cash/bulk_delete/`, {
        data: { ids: ids },
      }),
    onSuccess: (response) => {
      refetch();
      setDeleteConfirmation(false);
      setSelectedIds([]);
      toast.success(response?.data?.message || "Action completed successfully");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <Grid2 container>
      <GKDataGrid
        checkboxSelection
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        columns={cashManagementMainPageColumns}
        rows={data?.data?.data || []}
        onRowClick={(value) => {
          if (
            value.row[`${value?.row?.transactionType?.toLowerCase()}`]
              ?.addedField === "dividend" ||
            value?.row?.transactionType === "BUY" ||
            value?.row?.transactionType === "SELL"
          ) {
            toast.error("This type of transaction id not editable.");
          } else {
            setCashEntryData(value.row);
            setOpenCashModal(true);
          }
        }}
        totalCount={data?.data?.totalCount || 0}
        exportFile={{
          path: CASH_TRANSACTION_BY_PORTFOLIO_ID(id),
          exportName: "portfolioCashEntry",
        }}
        headerComponent={
          <Grid2 container gap={1} justifyContent={"end"}>
            {selectedIds?.length !== 0 && (
              <LoadingButton
                variant="outlined"
                loading={deleteLoading}
                size="medium"
                color="error"
                onClick={() => {
                  setDeleteConfirmation(true);
                }}
              >
                Delete
              </LoadingButton>
            )}
            {selectedIds?.length !== 0 && (
              <GKButton
                variant="outlined"
                size="medium"
                color="primary"
                onClick={() => {
                  setSelectedIds([]);
                }}
              >
                Cancel
              </GKButton>
            )}
            {selectedIds?.length === 0 && (
              <GKButton
                variant="contained"
                size="medium"
                onClick={() => {
                  setCashEntryData({});
                  setOpenCashModal(true);
                }}
                startIcon={<CgMathPlus size={18} />}
              >
                Add Cash Entry
              </GKButton>
            )}
          </Grid2>
        }
        name="portfolioCashEntry"
        tableName="portfolio_cash_entry"
        headerFilter={<TableFiltering name="portfolioCashEntry" />}
      />

      <Show.When isTrue={openCashModal}>
        <CashTransactionModal
          open={openCashModal}
          setOpen={setOpenCashModal}
          cashEntryData={cashEntryData}
          portfolioId={id}
        />
      </Show.When>

      <Show.When isTrue={deleteConfirmation}>
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete(selectedIds)}
          title={"Bulk"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected ledger statement & once deleted it cannot be reverted?`}
        />
      </Show.When>
    </Grid2>
  );
};

export default CashEntry;
